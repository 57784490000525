import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { SubcontractorGroup } from '../../../models/subcontractor-group.model';
import { ISubcontractorGroup } from   '../../../interfaces/subcontractor-group.interface';
import { SubcontractorGroupsService } from '../../../services/subcontractor-groups.service';


@Component({
  selector: 'app-subcontractor-groups',
  templateUrl: './subcontractor-groups.component.html',
  styleUrls: ['./subcontractor-groups.component.scss']
})


export class SubcontractorGroupsComponent implements OnInit {
	
  private allSubcontractorGroups:Array<ISubcontractorGroup> = [];
  private allMainSubcontractorGroups:Array<ISubcontractorGroup> = [];

  private subcontractorGroupModel = new SubcontractorGroup();


  private showSubcontractorGroupDrawer:boolean = false;
  private showAddEditSubcontractorGroupDrawer:boolean = false;

  private activateSubcontractorGroupDrawerSceleton:boolean = false;
  private activateAddEditSubcontractorGroupDrawerSceleton:boolean = false;

  private disableGroupSelect:boolean=false;

	

  constructor(private SubcontractorGroupsService: SubcontractorGroupsService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.SubcontractorGroupsService.getAllSubcontractorGroups().subscribe( data => { 
      this.allSubcontractorGroups = data['data']; 
    });
  
    this.SubcontractorGroupsService.getMainSubcontractorGroups().subscribe( data => {
      this.allMainSubcontractorGroups = data['data'];
    });
  
  
  }


  openAddEditSubcontractorGroupDrawer(itemId?: any){
  

    if(itemId){
	    this.activateAddEditSubcontractorGroupDrawerSceleton = true;
      this.showAddEditSubcontractorGroupDrawer = true;
      
      this.SubcontractorGroupsService.getSubcontractorGroup(itemId).subscribe(userData => {
        
        this.subcontractorGroupModel = new SubcontractorGroup(userData["data"].id, userData["data"].groupId, userData["data"].groupName);

        this.activateAddEditSubcontractorGroupDrawerSceleton=false;
        
        if(userData["data"].groupId=='0'){
          this.disableGroupSelect=true;
        }else{
          this.disableGroupSelect=false;
        }


      });
    
        
    }else{
      
      this.disableGroupSelect=false;
	    this.showAddEditSubcontractorGroupDrawer = true;
	   
	    this.activateAddEditSubcontractorGroupDrawerSceleton = false;
	    this.subcontractorGroupModel = new SubcontractorGroup();

    } 
  
  
  
  }


	  

  closeAddEditSubcontractorGroupDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditSubcontractorGroupDrawer = false;
   
  }


  submitAddEditSubcontractorGroupForm(formName: NgForm){


    if(+this.subcontractorGroupModel.id>0){


      this.SubcontractorGroupsService.updateSubcontractorGroup(this.subcontractorGroupModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditSubcontractorGroupDrawer = false;
        this.MessageService.success('Grupa została zmieniona.');
        
        this.SubcontractorGroupsService.getAllSubcontractorGroups().subscribe( data => {
          this.allSubcontractorGroups = data['data'];
        
        });
        
        this.SubcontractorGroupsService.getMainSubcontractorGroups().subscribe( data => {
          this.allMainSubcontractorGroups = data['data'];
        });

      
		},
		error => console.log('Error: ', error)
      );


    }else{

        this.SubcontractorGroupsService.addSubcontractorGroup(this.subcontractorGroupModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditSubcontractorGroupDrawer = false;
            
            this.MessageService.success('Grupa została dodana.');

            this.SubcontractorGroupsService.getAllSubcontractorGroups().subscribe( data => {
              this.allSubcontractorGroups = data['data'];
            
            });

            this.SubcontractorGroupsService.getMainSubcontractorGroups().subscribe( data => {
              this.allMainSubcontractorGroups = data['data'];
            });            
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmSubcontractorGroupDelete(recordId: any){

    this.SubcontractorGroupsService.delSubcontractorGroup(recordId).subscribe( data => {
	  this.MessageService.success('RECORD został usunięty');
      this.SubcontractorGroupsService.getAllSubcontractorGroups().subscribe( data => {
        this.allSubcontractorGroups = data['data'];
        
      });        
    });
  
  }





}