import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CommissionUserType'
})
export class CommissionUserTypePipe implements PipeTransform {
  private userType: Array<any> = [];

  constructor(){
    this.userType['i']='Inwestor';
    this.userType['u']='Uzytkownik';
    this.userType['c']='Wykonawca';    
  }

  transform(optionValue: any): string {
    return this.userType[optionValue];
  }

}