export class ToolGroup{

    constructor(

	public id: number = 0,
	public groupName: string = ''
	

    ){

    }
}