export class ProjectExtraData {

    constructor(

        public acceptanceSubject: string='',
        public constructionName:  string='',
       
        public founderName: string='',
        public founderStreetType: string='',
        public founderStreetName: string='',
        public founderHouseNr: string='',
        public founderPostCode: string='',
        public founderCity: string='',
       
        public userName: string='',
        public userStreetType: string='',
        public userStreetName: string='',
        public userHouseNr: string='',
        public userPostCode: string='',
        public userCity: string=''     
        
    ){}

}