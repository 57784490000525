import { Component, OnInit, Input } from '@angular/core';
import { ProjectItemsService } from '../../../services/project-items.service';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { ProjectItem } from '../../../models/projectitem.model';
import { IProjectsItems } from   '../../../interfaces/projects-items.interface';

import { SubcontractorsService } from '../../../services/subcontractors.service';
import { Subcontractor } from '../../../models/subcontractor.model';


import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';

export interface TreeNodeInterface {
  id: number;
  taskName: string;
  toDo: number;
  level: number;
  expand: boolean;
  toDoDone: string;
  groupId: any;
  items?: TreeNodeInterface[];
}


@Component({
  selector: 'app-project-items',
  templateUrl: './project-items.component.html',
  styleUrls: ['./project-items.component.scss']
})

export class ProjectItemsComponent implements OnInit {
  @Input() projectId: number = 0;
  
  private mapOfExpandedData = {};
  private mapOfExpandedDataTmp = {};

  private expandStatus = [];

  private bCanEdit:boolean = true;
  
  private allProjectsItems:Array<IProjectsItems> = [];

  private allSubcontractorsItems:Array<any> = [];

  private projectsItemsModel = new ProjectItem(this.projectId);
  private projectsEditItemsModel = new ProjectItem(this.projectId);

  private itemDirectoriesData:any[] =[];
  
  private newDirectoryModel: any = { dirName: '', id: '0' };
  
  private showProjectsItemsDrawer:boolean = false;
  
  private showAddProjectsItemsDrawer:boolean = false; //OK
  private showEditProjectsItemsDrawer:boolean = false; //OK
  private showEditProjectsGroupItemsDrawer:boolean = false;//

  private changeProjectsGroupAllItems:boolean = false;

  private showAddNewDirectoryDrawer:boolean = false;

  private activateProjectsItemsDrawerSceleton:boolean = false;
  private activateAddEditProjectsItemsDrawerSceleton:boolean = false;
  
  fileList = [
    /*
    {
      uid: -1,
      name: 'xxx.png',
      status: 'done',
      url: 'http://www.baidu.com/xxx.png'
    }*/
  ];

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {

        const filterFiles = fileList.filter(w => ~['application/xml','text/xml'].indexOf(w.type));
        
        if (filterFiles.length !== fileList.length) {
          this.MessageService.error(`To nie jest poprawny plik. Załaduj plik NORMA w XML (ATH2)`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: UploadFile[]) => {
        return new Observable((observer: Observer<UploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];
  
  visible = false;
  private projectWorks = <any>[];

  private showAddItemDrawerState: boolean = false;

  constructor(
                private ProjectItemsService: ProjectItemsService, 
                private SubcontractorsService: SubcontractorsService,
                private MessageService: NzMessageService
              ){}

  ngOnInit() {
    
    this.getAvailableDirectories();

    this.SubcontractorsService.getAllSubcontractors().subscribe(data => {
      this.allSubcontractorsItems=data['data'];
      this.allSubcontractorsItems[0]={id: '0', companyName: 'Inter-Profil'};
    });

    this.ProjectItemsService.getProjectItems(this.projectId).subscribe( data => {
      this.projectWorks = data['data'];
      
      this.countPercentageAndLate(this.projectWorks);
      
      this.projectWorks.forEach(item => {
        this.mapOfExpandedData[ item.id ] = this.convertTreeToList(item);
      });

    } );
  
  }

  addNormaItemsToProject(form: NgForm){
    //console.log('FileList', this.fileList);
    
    let fileTmp=this.fileList.slice(-1).pop();
    let fileId=fileTmp.response.data.fileId;

    this.ProjectItemsService.addProjectNormaFile(fileId, this.projectId, this.projectsItemsModel.groupId).subscribe(n => {

      
      //this.fileList=new Array();
    });


    
  
  
  
  }





  handleChange(info: any): void {

   

    const fileList = info.fileList;
    // 2. read from response and show file link
    
    if (info.file.response) {
      info.file.url = info.file.response.url;
      fileList.fileId=info.file.response.id;
    }
    // 3. filter successfully uploaded files according to response from server
    // tslint:disable-next-line:no-any



    
    this.fileList = fileList.filter((item: any) => {
      if(item.response){
        return item.response.success === true;
      }
      return true;
    });  
  
  }



  addNewDirectory(){
    this.showAddNewDirectoryDrawer=true;
  }


  addNewDirectoryName(){

    this.ProjectItemsService.addNewDirectory(this.projectId, this.newDirectoryModel.id, this.newDirectoryModel.dirName).subscribe(data => {

        this.getAvailableDirectories(); 
        this.getAvailableMainDirectories();
        this.newDirectoryModel.dirName='';
        this.newDirectoryModel.id='0';
        this.projectsItemsModel.groupId=data['data'].lastInsertId;   
        
        this.showAddNewDirectoryDrawer=false;
        this.MessageService.success('Dział został dodany.');

      },
      error => {
        this.MessageService.error('Wystąpił nieznany błąd.');
        console.log('Error: ', error);
      }


    );
  
  }

  closeAddNewDirectoryDrawer(formName: NgForm): void{

  	//formName.form.markAsUntouched();
    this.showAddNewDirectoryDrawer = false;
  }  

  getAvailableDirectories(){

    this.ProjectItemsService.getProjectDirs(this.projectId.toString()).subscribe(
      data => {
          this.itemDirectoriesData=data['data'];
      },
      error => console.log('Error: ', error)
    );

  }
  
  
  getAvailableMainDirectories(){

    this.ProjectItemsService.getProjectMainDirs(this.projectId.toString()).subscribe(
      data => {
          //this.mainDirectoriesData=data['data'];
      },
      error => console.log('Error: ', error)
    );

  }


  submitAddProjectsItemsForm(form: NgForm){

    this.ProjectItemsService.addProject(this.projectsItemsModel).subscribe(
      data=>{
        this.showAddProjectsItemsDrawer = false;
        form.form.markAsUntouched();
        this.MessageService.success('Element został dodany.');
      });


  }


  openAddProjectsItemsDrawer(itemId?: any){

    this.showAddProjectsItemsDrawer = true;
    
    this.SubcontractorsService.getAllSubcontractors().subscribe (data => {
      this.allSubcontractorsItems=data['data'];
      this.allSubcontractorsItems[0]={id: '0', companyName: 'Inter-Profil'};
      
    });
   
    this.activateAddEditProjectsItemsDrawerSceleton = false;
    this.projectsItemsModel = new ProjectItem();

    this.projectsItemsModel.id=0;
    this.projectsItemsModel.projectId=this.projectId;
    this.projectsItemsModel.taxRate='23';
    this.projectsItemsModel.doBy='0';
    this.projectsItemsModel.toDoUnit='m2';
    this.projectsItemsModel.groupId='0';

  }  

  closeAddProjectsItemsDrawer(formName: NgForm): void{

    formName.form.markAsUntouched();
    this.showAddNewDirectoryDrawer = false;
    this.showAddProjectsItemsDrawer = false;
  }



  ////////////////////////////// 

  editProgress(aProjectData){

    if(this.bCanEdit){
      aProjectData.isEdited = true;
      this.bCanEdit = false;

      if(aProjectData.isGroup=='1'){
        aProjectData.showItems=false;
      }

    }else{
      
      let correctData=true;
      if((aProjectData.toDo<aProjectData.toDoDone || aProjectData.toDoDone<=0) && aProjectData.isGroup=='0' )  {
        correctData=false;
        alert('Niepoprawna ilość wykonanej pracy. Musi być mniejsza lub rowna planowi.');
      }

      if(aProjectData.isEdited == true && correctData){
        let updateProjectData: any=[];

        aProjectData.isEdited = false;
        this.bCanEdit = true;
        
        aProjectData.planStatus=this.calculateLate(aProjectData.taskStartDate, aProjectData.taskEndDate, aProjectData.toDo, aProjectData.toDoDone);

        updateProjectData['id']=aProjectData.id;
        updateProjectData['toDoDone']=aProjectData.toDoDone;
        updateProjectData['projectId']=aProjectData.projectId;
        updateProjectData['planStatus']=aProjectData.planStatus;
        updateProjectData['taskStartDate']=new Date();
        updateProjectData['taskEndDate']=new Date();
        updateProjectData['taskName']=aProjectData.taskName;
        updateProjectData['basicEdit']='1';//this.projectInfo.basicEdit;
        updateProjectData['doBy']=aProjectData.doBy;
        updateProjectData['setToAll']=aProjectData.setToAll;
      
        

        this.ProjectItemsService.saveProjectItemDone(updateProjectData).subscribe(
          data=>{
            
            this.ProjectItemsService.getProjectGroups(this.projectId).subscribe( dataGroup => {
              
              let projectGroups=[];

              dataGroup['data'].forEach(
                gData => {                  
                  projectGroups[gData['id']]=gData;
                  projectGroups[gData['id']]['toDoDoneInPercent']=(100*gData.toDoDone/gData.toDo);
                }
              );

              this.updateProjectsArray(this.projectWorks, projectGroups);
            } );
            
          }

        );

        
      }
    }
  }













  submitEditProjectsItemsForm(formName?: NgForm){
    
    this.projectsEditItemsModel.basicEdit='0';
    this.ProjectItemsService.saveProjectItemDone(this.projectsEditItemsModel).subscribe( data => {

        this.ProjectItemsService.getProjectItems(this.projectId).subscribe( data => {
          this.projectWorks = data['data'];
          
          this.countPercentageAndLate(this.projectWorks);
          
          this.projectWorks.forEach(item => {
            this.mapOfExpandedData[ item.id ] = this.convertTreeToList(item);
          });
    
        } );

      this.showEditProjectsItemsDrawer = false;
      this.showEditProjectsGroupItemsDrawer = false;
    });
  }




  openEditProjectsItemsDrawer(projectItemId?: any){

    let itemStartDate=new Date();
    let itemEndDate=new Date();

    if(projectItemId.taskStartDate!='0000-00-00'){
      itemStartDate=new Date(projectItemId.taskStartDate);
    }

    if(projectItemId.taskEndDate!='0000-00-00'){
      itemEndDate=new Date(projectItemId.taskEndDate);
    }


    this.projectsEditItemsModel = {
      id: projectItemId.id,
      projectId: projectItemId.projectId,
      taskName: projectItemId.taskName,
      taskStartDate: itemStartDate,
      taskEndDate: itemEndDate,
      toDo: projectItemId.toDo,
      toDoUnit: projectItemId.toDoUnit,
      workAmountTotal: projectItemId.workAmountTotal,
      itemAmountTotal: projectItemId.itemAmountTotal,
      doBy: projectItemId.doBy,
      planStatus: projectItemId.planStatus,
      isGroup: projectItemId.isGroup,
      groupId: projectItemId.groupId,
      toDoDone: '0',
      workAmount: '0',
      itemAmount: '0',
      basicEdit: '0',
      taxRate: projectItemId.taxRate,
      setToAll: false
    };


    if(projectItemId.isGroup==='1'){

      this.projectWorks[0].taskName='fasdfasfasdf';

      this.changeProjectsGroupAllItems=false;
      this.showEditProjectsGroupItemsDrawer=true;
    }else{
      this.showEditProjectsItemsDrawer=true;
    }

  }

  closeEditProjectsItemsDrawer(formName?: NgForm): void{
  	formName.form.markAsUntouched();
    this.showEditProjectsItemsDrawer = false;
  }


  closeEditProjectsGroupItemsDrawer(formName?: NgForm): void{
  	formName.form.markAsUntouched();
    this.showEditProjectsGroupItemsDrawer = false;
  }  











  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    
    this.expandStatus[data.id]=data.expand?true:false;

    if ($event === false) {
      if (data.items) {
        data.items.forEach(d => {
          const target = array.find(a => a.id === d.id);
          target.expand = false;
          this.expandStatus[target.id]=false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }




  convertTreeToList(root: any): TreeNodeInterface[] {
    const stack = [];
    const array = [];
    const hashMap = {};    

    stack.push({ ...root, level: 0, expand: (this.expandStatus[root.id]===true)?true:false });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.items) {
        for (let i = node.items.length - 1; i >= 0; i--) {
          stack.push({ ...node.items[ i ], level: node.level + 1, expand: this.expandStatus[node.items[i].id]?true:false, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: object, array: TreeNodeInterface[]): void {
    if (!hashMap[ node.id ]) {
      hashMap[ node.id ] = true;
      array.push(node);
    }
  }









  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }





  /////////// Extra methods

  changeValue(aProjectData){
    aProjectData.toDoDoneInPercent=(100*aProjectData.toDoDone/aProjectData.toDo);
  }

  changePercent(event, aProjectData){
    aProjectData.toDoDone=aProjectData.toDoDoneInPercent*aProjectData.toDo/100.0;
  } 


  calculateLate(dateStart: string, dateEnd: string, toDo: any, toDoDone: any): string{
    let dNow = new Date();
    let dStart = new Date(dateStart);        
    let dEnd = new Date(dateEnd);
    let timePeriod=0;
    let timeDiff=0;

    if(dNow<dStart){
      return '0';
    }else if(dNow>dEnd && toDo>toDoDone){
      return '1';
    
    }else{

      timePeriod=dNow.getTime()-dStart.getTime();
      timeDiff=dEnd.getTime()-dStart.getTime();
      
      if( ((timePeriod/timeDiff)>(toDoDone/toDo)) && (toDoDone!=toDo)){
        return '1';
      }else{
        return '0';
      }
    }
  }


  countPercentageAndLate(projectData){

    projectData.forEach(
      data => {
        data.toDoDoneInPercent=Math.floor(100*data.toDoDone/data.toDo);
       
        if(data.isGroup=='1'){
          data.showItems=false;
          data.setToAll=false;
        }

        if(data.items==undefined){
          data.items=[];
        }

        if(data.items.length){
          this.countPercentageAndLate(data.items);  
        }

      }
    ); 
  
  }


  updateProjectsArray(projectData, projectGroups){

    projectData.forEach(
      data => {
       
        if(data.isGroup=='1'){

          if(data.toDo!=projectGroups[data.id].toDo){
            data.toDo=projectGroups[data.id].toDo;
          }

          if(data.toDoDone!=projectGroups[data.id].toDoDone){
            data.toDoDone=projectGroups[data.id].toDoDone;
          }

          if(data.toDoDoneInPercent!=projectGroups[data.id].toDoDoneInPercent){
            data.toDoDoneInPercent=projectGroups[data.id].toDoDoneInPercent;
          }

          if(data.planStatus!=projectGroups[data.id].planStatus){
            data.planStatus=projectGroups[data.id].planStatus;
          }

          if(data.taskStartDate!=projectGroups[data.id].taskStartDate){
            data.taskStartDate=projectGroups[data.id].taskStartDate;
          }

          if(data.taskEndDate!=projectGroups[data.id].taskEndDate){
            data.taskEndDate=projectGroups[data.id].taskEndDate;
          }

          if(data.items.length){
            this.updateProjectsArray(data.items, projectGroups);  
          }
  
        }

      }
    ); 
  }





}