import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ISupplier } from '../interfaces/supplier.interface';
import { Supplier } from '../models/supplier.model';



@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private http: HttpClient) { }



  updateSupplier(updateData: Supplier):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('userId', updateData.userId);
    body = body.set('companyName', updateData.companyName);
    body = body.set('companyStreetType', updateData.companyStreetType);
    body = body.set('companyStreet', updateData.companyStreet);
    body = body.set('companyNr', updateData.companyNr);
    body = body.set('companyPostCode', updateData.companyPostCode);
    body = body.set('companyCity', updateData.companyCity);
    body = body.set('companyTaxNr', updateData.companyTaxNr);
    body = body.set('companyBankAccount', updateData.companyBankAccount);
    body = body.set('contactPerson', updateData.contactPerson);
    body = body.set('contactTelAreaCode', updateData.contactTelAreaCode);
    body = body.set('contactTel', updateData.contactTel);
    body = body.set('contactPosition', updateData.contactPosition);

    body = body.set('webPage', updateData.webPage);
    body = body.set('emailAddress', updateData.emailAddress);
    body = body.set('serviceGroups', updateData.serviceGroups);
   

    body = body.set('contactPersons', updateData.contactPersons);
    

    return this.http.put('/api/suppliers', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


    addSupplier(addData: Supplier):Observable<any>{

      let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

      body = body.set('companyName', addData.companyName);
      body = body.set('companyStreetType', addData.companyStreetType);
      body = body.set('companyStreet', addData.companyStreet);
      body = body.set('companyNr', addData.companyNr);
      body = body.set('companyPostCode', addData.companyPostCode);
      body = body.set('companyCity', addData.companyCity);
      body = body.set('companyTaxNr', addData.companyTaxNr);
      body = body.set('companyBankAccount', addData.companyBankAccount);
      body = body.set('contactPerson', addData.contactPerson);
      body = body.set('contactTelAreaCode', addData.contactTelAreaCode);
      body = body.set('contactTel', addData.contactTel);
      body = body.set('webPage', addData.webPage);
      body = body.set('emailAddress', addData.emailAddress);
      body = body.set('serviceGroups', addData.serviceGroups);
      
      body = body.set('contactPosition', addData.contactPosition);

      body = body.set('contactPersons', addData.contactPersons);


      return this.http.post('/api/suppliers', body).pipe(
        map(this.extractData),
        catchError( this.handleError )
      ); 

    }


    getAllSuppliers(searchWord?:string, searchGroup?: string): Observable<ISupplier[]> {

      if(searchWord || searchGroup){
        let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

        body = body.set('searchWord', searchWord);
        body = body.set('searchGroup', searchGroup);

        return this.http.get<ISupplier[]>('/api/suppliers', {params: body} );
      
      }else{
        return this.http.get<ISupplier[]>('/api/suppliers');
      }
    }

    getSupplier(itemId: number): Observable<ISupplier> {
      return this.http.get<ISupplier>('/api/suppliers/'+itemId);
    }

    delSupplier(itemId: number):Observable<any> {
      return this.http.delete('/api/suppliers/'+itemId).pipe(
        map(this.extractData),
        catchError( this.handleError )
      ); 
  
    }













  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }

}