import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'QuotationType'
})
export class QuotationTypePipe implements PipeTransform {

  private quationType: Array<any> = [];


  constructor(){
    this.quationType['o']='Ofertowy';
    this.quationType['d']='Szczegółowy';
  }

  transform(optionValue: any): string {
    return this.quationType[optionValue];
  }
}
