import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '../../../services/projects.service'
//import { IProject } from '../../../interfaces/project.interface';

import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { Projects } from '../../../models/projects.model';
import { IProject } from   '../../../interfaces/project.interface';

import { User } from '../../../models/user.model';
import { IUser } from   '../../../interfaces/user.interface';
import { UserService } from '../../../services/user.service';

import { ProjectExtraData } from '../../../models/project-extra-data.model';
import { ProjectAddressData } from '../../../models/project-address-data.model';


@Component({
  selector: 'app-show-projects',
  templateUrl: './show-projects.component.html',
  styleUrls: ['./show-projects.component.scss']
})
export class ShowProjectsComponent implements OnInit {
  
  private allProjects:Array<IProject> = [];

  private projectModel = new Projects();

  private showProjectDrawer:boolean = false;
  private showAddEditProjectDrawer:boolean = false;
  private activateProjectDrawerSceleton:boolean = false;
  private activateAddEditProjectDrawerSceleton:boolean = false;


  //////////////////////////////////////////////////////
  
  
  private allActiveProjects: IProject[]=[];
  private allFinishedProjects: IProject[]=[];

  private allUsersManagers:Array<IUser> = [];


  public projectExtraData = new ProjectExtraData(); 
  private projectAddressData = new ProjectAddressData();

  constructor(private ProjectsService: ProjectsService, private UsersService: UserService,  private MessageService: NzMessageService) { }


  ngOnInit() {
    this.ProjectsService.getActiveProjects().subscribe( data => this.allActiveProjects = data['data'] );
    this.ProjectsService.getFinishedProjects().subscribe( data => this.allFinishedProjects = data['data'] );
  
  }

  isJsonString(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }


  openAddEditProjectDrawer(itemId?: any){
    
    this.activateAddEditProjectDrawerSceleton = true;

    this.UsersService.getAllUsersManagers().subscribe(userData => {
      this.allUsersManagers=userData['data'];

    
      if(itemId){
        this.activateAddEditProjectDrawerSceleton = true;
        this.showAddEditProjectDrawer = true;

        
        this.ProjectsService.getProject(itemId).subscribe(userData => {
          
          this.projectModel = new Projects();

          this.projectModel.id=userData['data'].id;
          this.projectModel.managerId=userData['data'].managerId;
          this.projectModel.projectAddress=this.isJsonString(userData['data'].projectAddress)?JSON.parse(userData['data'].projectAddress):new ProjectAddressData();
          
          
          if(userData['data'].projectStart!='0000-00-00'){
            this.projectModel.projectStart = new Date(userData['data'].projectStart);
          }
      
          if(userData['data'].projectEnd!='0000-00-00'){
            this.projectModel.projectEnd = new Date(userData['data'].projectEnd);
          }
          
          this.projectModel.projectName=userData['data'].projectName;
          this.projectModel.projectNote=userData['data'].projectNote;
          this.projectModel.basicEdit=userData['data'].basicEdit;
          this.projectModel.projectExtraData=this.isJsonString(userData['data'].projectExtraData)?JSON.parse(userData['data'].projectExtraData):new ProjectExtraData();

         
          this.projectExtraData = new ProjectExtraData();
          this.projectExtraData.acceptanceSubject=this.projectModel.projectExtraData['acceptanceSubject'];
          this.projectExtraData.constructionName=this.projectModel.projectExtraData['constructionName'];

          this.projectExtraData.founderName=this.projectModel.projectExtraData['founderName'];
          this.projectExtraData.founderStreetType=this.projectModel.projectExtraData['founderStreetType']?this.projectModel.projectExtraData['founderStreetType']:'';
          this.projectExtraData.founderStreetName=this.projectModel.projectExtraData['founderStreetName'];
          this.projectExtraData.founderHouseNr=this.projectModel.projectExtraData['founderHouseNr'];
          this.projectExtraData.founderPostCode=this.projectModel.projectExtraData['founderPostCode'];
          this.projectExtraData.founderCity=this.projectModel.projectExtraData['founderCity'];

          this.projectExtraData.userName=this.projectModel.projectExtraData['userName'];
          this.projectExtraData.userStreetType=this.projectModel.projectExtraData['userStreetType'];
          this.projectExtraData.userStreetName=this.projectModel.projectExtraData['userStreetName'];
          this.projectExtraData.userHouseNr=this.projectModel.projectExtraData['userHouseNr'];
          this.projectExtraData.userPostCode=this.projectModel.projectExtraData['userPostCode'];
          this.projectExtraData.userCity=this.projectModel.projectExtraData['userCity'];


          this.projectAddressData = new ProjectAddressData();
          this.projectAddressData.streetType=this.projectModel.projectAddress['streetType'];
          this.projectAddressData.streetName=this.projectModel.projectAddress['streetName'];
          this.projectAddressData.houseNr=this.projectModel.projectAddress['houseNr'];
          this.projectAddressData.postCode=this.projectModel.projectAddress['postCode'];
          this.projectAddressData.city=this.projectModel.projectAddress['city'];

          this.activateAddEditProjectDrawerSceleton=false;
        
        });
      
          
      }else{

        this.projectModel = new Projects();
        
        this.showAddEditProjectDrawer = true;
        this.activateAddEditProjectDrawerSceleton = false;

      }    
    
    
    
    
    });



  }






  closeAddEditProjectDrawer(formName: NgForm): void{

    formName.form.markAsUntouched();
   
    //this.userExistError=false;
    this.showAddEditProjectDrawer = false;
 
  }
  
  
  submitAddEditProjectForm(formName: NgForm){

    this.projectModel.projectExtraData=JSON.stringify(this.projectExtraData);
    this.projectModel.projectAddress=JSON.stringify(this.projectAddressData);

    
    if(+this.projectModel.id>0){

      this.ProjectsService.updateProject(this.projectModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditProjectDrawer = false;
        this.MessageService.success('Projekt został zaktualizowany');        
        this.ProjectsService.getActiveProjects().subscribe( data => this.allActiveProjects = data['data'] );

        },
        error => console.log('Error: ', error)
      );


    }else{
    
      this.ProjectsService.addProject(this.projectModel).subscribe( userData => { 
                
        formName.form.markAsUntouched(); 
        this.showAddEditProjectDrawer = false;  
        this.MessageService.success('Projekt został dodany');

        this.ProjectsService.getActiveProjects().subscribe( data => this.allActiveProjects = data['data'] );

        
        },
        error => console.log('Error: ', error)
      );
  
    }
  
  }
  
}