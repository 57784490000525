import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../../services/projects.service';



@Component({
  templateUrl: './get-project.component.html',
  styleUrls: ['./get-project.component.scss']
})
export class GetProjectComponent implements OnInit {

  projectId: string = '0';
  private projectInfo: any = { id: "", projectName: "", projectManager: "" };


  constructor(private route: ActivatedRoute, private ProjectService: ProjectsService) { }

  ngOnInit() {
    let projectId: string = this.route.snapshot.paramMap.get('id');
    this.projectId=projectId;

    this.ProjectService.getProject(projectId).subscribe( data => this.projectInfo = data['data'] );

  }

}