export class TenderFile{

    constructor(
        public id: any = 0,
        public tenderId: any = 0,
        public fileName: string = '',
        public fileDescription: string = '',
        public fileSize: any = 0,
        public addDate: string = '',
        public addedFiles: string = '',
        public dirId: string = 'c'
        ){
    
    }

}
