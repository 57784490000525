import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { Supplier } from '../../../models/supplier.model';
import { ISupplier } from   '../../../interfaces/supplier.interface';

import { SuppliersService } from '../../../services/suppliers.service';
import { SupplierGroupsService } from '../../../services/supplier-groups.service';
import { SupplierContactsService } from '../../../services/supplier-contacts.service';




@Component({
  templateUrl: './show-suppliers.component.html',
  styleUrls: ['./show-suppliers.component.scss']
})
export class ShowSuppliersComponent implements OnInit {

  private listOfOption = <any>[];
  private _serachWord:string='';
  private _searchGroup:string;

 
  private allSuppliers:Array<ISupplier> = [];

  private availableSupplierGroups:Array<any> = [];

  private supplierModel = new Supplier();

  private showSupplierSearchForm:boolean = false;

  private showSupplierDrawer:boolean = false;
  private showAddEditSupplierDrawer:boolean = false;

  private activateSupplierDrawerSceleton:boolean = false;
  private activateAddEditSupplierDrawerSceleton:boolean = false;

  private supplierContactsList: Array<any> = [];

  private supplierContactsListEditCache:{ [key: string]: any } = {};


  constructor(private SuppliersService: SuppliersService, 
              private MessageService: NzMessageService, 
              private SupplierGroupsService: SupplierGroupsService,
              private SupplierContactsService: SupplierContactsService,
              private modalService: NzModalService
              ) { }


  ngOnInit() {

    this.SuppliersService.getAllSuppliers().subscribe(data => this.allSuppliers=data['data']);
    this.SupplierGroupsService.getAvailableSupplierGroups().subscribe( data => this.availableSupplierGroups=data['data'] );

  }


  searchSuppliers(): void {

    this.SuppliersService.getAllSuppliers(this._serachWord, this._searchGroup).subscribe(data => this.allSuppliers=data['data']);
  }

  updateEditCache(): void {
    this.supplierContactsList.forEach(item => {
      
      this.supplierContactsListEditCache[ item.editId ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }

  startEdit(id: any): void {
    this.supplierContactsListEditCache[ id ].edit = true;   
  }

  cancelEdit(id: string): void {
    const index = this.supplierContactsList.findIndex(item => item.editId === id);
    
    this.supplierContactsListEditCache[ id ] = {
      data: { ...this.supplierContactsList[ index ] },
      edit: false
    };
  
  }

  saveEdit(id: string): void {
    const index = this.supplierContactsList.findIndex(item => item.editId === id);
    
    Object.assign(this.supplierContactsList[ index ], this.supplierContactsListEditCache[ id ].data);
    this.supplierContactsListEditCache[ id ].edit = false;
  }

  addSupplierContact(){

    this.supplierContactsList = [ ...this.supplierContactsList, {
        id     : '',
        editId: this.supplierContactsList.length+1,
        contactName   : '',
        contactTel    : '',
        contactPosition: '',
        contactTelAreaCode: '+48',
        contactEmail: ''
    }];




    this.updateEditCache();
  
  }


  togleSearchSupplierForm(){
    
    this.showSupplierSearchForm=!this.showSupplierSearchForm;
  
  }

  confirmSupplierDelete(recordId: any){

    this.SuppliersService.delSupplier(recordId).subscribe( data => {
      this.MessageService.success('Podwykonawca został usunięty');
      this.SuppliersService.getAllSuppliers().subscribe( data => {
        this.allSuppliers = data['data'];        
      });        
    });
  
  }



  openAddEditSupplierDrawer(userId?:number): void {
    this.showAddEditSupplierDrawer = true;
    
    this.supplierContactsList=[];

    if(userId){
      this.activateAddEditSupplierDrawerSceleton = true;

      this.SuppliersService.getSupplier(userId).subscribe(subData => {
        
        this.supplierModel.userId=subData['data'].userId;
        this.supplierModel.userLogin=subData['data'].userLogin;
        this.supplierModel.companyName=subData['data'].companyName;
        this.supplierModel.companyStreetType=subData['data'].companyStreetType;
        this.supplierModel.companyStreet=subData['data'].companyStreet;
        this.supplierModel.companyNr=subData['data'].companyNr;
        this.supplierModel.companyPostCode=subData['data'].companyPostCode;
        this.supplierModel.companyCity=subData['data'].companyCity;
        this.supplierModel.companyTaxNr=subData['data'].companyTaxNr;
        this.supplierModel.companyBankAccount=subData['data'].companyBankAccount;
        this.supplierModel.contactPerson=subData['data'].contactPerson;
        this.supplierModel.contactTelAreaCode=subData['data'].contactTelAreaCode;
        this.supplierModel.contactTel=subData['data'].contactTel;

        this.supplierModel.contactPosition=subData['data'].contactPosition;
        
        this.supplierModel.emailAddress=subData['data'].emailAddress;
        this.supplierModel.serviceGroups=subData['data'].serviceGroups.split(',');
        this.supplierModel.webPage=subData['data'].webPage;
        this.supplierModel.qrCodeData='';
        
        
        this.SupplierContactsService.getAllSupplierContacts(userId).subscribe(contactData => {
          
          

          let itemId=0;
          contactData["data"].forEach(item => {
            itemId++;


            this.supplierContactsList = [ ...this.supplierContactsList, {editId: itemId, ...item}];

          });          

          this.updateEditCache();

          this.activateAddEditSupplierDrawerSceleton = false;



        });        
        
        
        

      });    
    
    }else{
      this.supplierModel = new Supplier(); 
    }
  }


  openSupplierDrawer(userId?:number): void {
    
    this.supplierContactsList=[];

    if(userId){

      this.showSupplierDrawer = true;
      this.activateSupplierDrawerSceleton = true;
    
      this.SuppliersService.getSupplier(userId).subscribe(subData => {
      
        this.supplierModel.userId=subData['data'].userId;

        this.supplierModel.userLogin=subData['data'].userLogin;
        this.supplierModel.companyName=subData['data'].companyName;
        this.supplierModel.companyStreetType=subData['data'].companyStreetType;
        this.supplierModel.companyStreet=subData['data'].companyStreet;
        this.supplierModel.companyNr=subData['data'].companyNr;
        this.supplierModel.companyPostCode=subData['data'].companyPostCode;
        this.supplierModel.companyCity=subData['data'].companyCity;
        this.supplierModel.companyTaxNr=subData['data'].companyTaxNr;
        this.supplierModel.companyBankAccount=subData['data'].companyBankAccount;
        this.supplierModel.contactPerson=subData['data'].contactPerson;
        this.supplierModel.contactTelAreaCode=subData['data'].contactTelAreaCode;
        this.supplierModel.contactTel=subData['data'].contactTel;
        this.supplierModel.contactPosition=subData['data'].contactPosition;

        
        this.supplierModel.emailAddress=subData['data'].emailAddress;
        this.supplierModel.serviceGroups=subData['data'].serviceGroups;

        this.supplierModel.webPage=subData['data'].webPage;
        this.supplierModel.qrCodeData=`BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:`+this.supplierModel.contactPerson.replace(/ /i, ";")+`\nORG;CHARSET=UTF-8:`+this.supplierModel.companyName+`\nADR;WORK;PREF;CHARSET=UTF-8:;`+this.supplierModel.companyCity+` `+this.supplierModel.companyPostCode+`;`+this.supplierModel.companyStreet+` `+this.supplierModel.companyNr+`\nTITLE;CHARSET=UTF-8:`+this.supplierModel.contactPosition+`\nTEL;CELL:`+this.supplierModel.contactTelAreaCode+this.supplierModel.contactTel+`\nEMAIL:`+this.supplierModel.emailAddress+`\nEND:VCARD`;
        

        if(this.supplierModel.contactPerson!=''){
          this.supplierContactsList.push({
            qrCodeData: this.supplierModel.qrCodeData,
            edit: false,
            contactEmail: this.supplierModel.emailAddress,
            contactName: this.supplierModel.contactPerson,
            contactPosition: this.supplierModel.contactPosition,
            contactTel: this.supplierModel.contactTel,
            contactTelAreaCode: this.supplierModel.contactTelAreaCode,
            id: '0',
            subId: '0'
          });
        }

        this.SupplierContactsService.getAllSupplierContacts(userId).subscribe(contactData => {

          contactData["data"].forEach(item => {

            this.supplierContactsList.push({ 
              
              qrCodeData: `BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:`+item.contactName.replace(/ /i, ";")+`\nORG;CHARSET=UTF-8:`+this.supplierModel.companyName+`\nADR;WORK;PREF;CHARSET=UTF-8:;`+this.supplierModel.companyCity+` `+this.supplierModel.companyPostCode+`;`+this.supplierModel.companyStreet+` `+this.supplierModel.companyNr+`\nTITLE;CHARSET=UTF-8:`+item.contactPosition+`\nTEL;CELL:`+item.contactTelAreaCode+item.contactTel+`\nEMAIL:`+item.contactEmail+`\nEND:VCARD`,
              
              edit: false, ...item });

          });          

          this.activateSupplierDrawerSceleton=false;

        });



                
      });
    
    }else{
      this.MessageService.error('Nie mogę pobrać danych podwykonawcy');
    }
  } 


  closeSupplierDrawer(m?: any):void{
    this.showSupplierDrawer = false;	
  }

  closeAddEditSupplierDrawer(formName: NgForm): void{
  	formName.form.markAsUntouched(); 	  
    this.showAddEditSupplierDrawer = false;
  }


  submitAddEditSupplierForm(formName: NgForm){

    this.supplierModel.contactPersons=JSON.stringify(this.supplierContactsList);
    

    if (formName.invalid) { 
      Object.keys( formName.controls).forEach(key => {
        formName.controls[key].markAsTouched();
      });
      
    }

    //formName.controls['contactPerson'].markAsTouched();

//    alert(formName.invalid);

    if(formName.invalid){ //if having errors?
      this.modalService.error({
        nzTitle: 'Ups! Wystąpiły błędy',
        nzContent: 'Sprawdź formularz i popraw je.'
      });
    }else{

      if(+this.supplierModel.userId>0){

      
        
        this.SuppliersService.updateSupplier(this.supplierModel).subscribe( userData => { 
          formName.form.markAsUntouched(); 
          this.showAddEditSupplierDrawer = false;
          this.MessageService.success('Dane zostały Zapisane');
          
          this.SuppliersService.getAllSuppliers(this._serachWord, this._searchGroup).subscribe(data => this.allSuppliers=data['data']);
        
        },
        error => console.log('Error: ', error)
        );


      }else{





        this.SuppliersService.addSupplier(this.supplierModel).subscribe( userData => { 
          formName.form.markAsUntouched(); 
          this.showAddEditSupplierDrawer = false;

          this.MessageService.success('Użytkownik został dodany');

          this.SuppliersService.getAllSuppliers(this._serachWord, this._searchGroup).subscribe(data => this.allSuppliers=data['data']);
        
          },
          error => console.log('Error: ', error)
        );

      }  
    
    }
  }


}