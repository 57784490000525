import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //HttpErrorResponse

import { Observable} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { IProject } from '../interfaces/project.interface';
import { Projects }  from '../models/projects.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private http: HttpClient) { }

  getActiveProjects(): Observable<IProject[]> {
    return this.http.get<IProject[]>('/api/projects/active');
  }

  getFinishedProjects(): Observable<IProject[]> {
    return this.http.get<IProject[]>('/api/projects/finished');
  }


  getProject(projectId: string): Observable<IProject[]> {
    return this.http.get<IProject[]>('/api/projects/get/'+projectId);
  }

  addProject(projectData: Projects):Observable<any>{

    let body = new HttpParams();



    body = body.set('projectName',    projectData.projectName);
    body = body.set('projectAddress', projectData.projectAddress);
    body = body.set('projectNote',    projectData.projectNote); 
    body = body.set('projectStart',   projectData.projectStart.getFullYear()+'-'+(projectData.projectStart.getMonth()+1)+'-'+projectData.projectStart.getDate()); 
    body = body.set('projectEnd',     projectData.projectEnd.getFullYear()+'-'+(projectData.projectEnd.getMonth()+1)+'-'+projectData.projectEnd.getDate() ); 
    body = body.set('managerId',      projectData.managerId); 

    body = body.set('projectExtraData',      projectData.projectExtraData);
    
//    let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
  //  let option = new RequestOptions({headers: headers});
    
    return this.http.post('/api/projects', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );
  }

  delProject(projectId: string):void{
    //return this.http.delete<IProject[]>(this.serverUrl+'/projects/'+projectId);
  }

  updateProject(projectData: Projects):Observable<any>{
 
    let body = new HttpParams();

    body = body.set('projectName',    projectData.projectName);
    body = body.set('projectAddress', projectData.projectAddress);
    body = body.set('projectNote',    projectData.projectNote); 

    body = body.set('projectStart',   projectData.projectStart.getFullYear()+'-'+(projectData.projectStart.getMonth()+1)+'-'+projectData.projectStart.getDate()); 
    body = body.set('projectEnd',     projectData.projectEnd.getFullYear()+'-'+(projectData.projectEnd.getMonth()+1)+'-'+projectData.projectEnd.getDate() ); 
    
    body = body.set('managerId',      projectData.managerId); 

    body = body.set('projectExtraData',      projectData.projectExtraData);

    body = body.set('projectId',      projectData.id); 

    body = body.set('basicEdit',      projectData.basicEdit); 
    body = body.set('status',      projectData.status); 
    


    return this.http.put('/api/projects', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );   
  
  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }




}
