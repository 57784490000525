import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ITenderFile } from '../interfaces/tender-file.interface';
import { TenderFile } from '../models/tender-file.model';



@Injectable({
  providedIn: 'root'
})


export class TenderFilesService {

  constructor(private http: HttpClient) { }
  
   
  updateTenderFile(updateData: TenderFile):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('tenderId', updateData.tenderId);
    body = body.set('fileName', updateData.fileName);
    body = body.set('fileDescription', updateData.fileDescription);
    body = body.set('fileSize', updateData.fileSize);
    body = body.set('addDate', updateData.addDate);
	

    return this.http.put('/api/tenderfiles', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addTenderFile(addData: TenderFile):Observable<any>{

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('fileName', addData.fileName);
    body = body.set('tenderId', addData.tenderId);
    body = body.set('fileDescription', addData.fileDescription);
    body = body.set('addDate', addData.addDate);

    body = body.set('dirId', addData.dirId);


    body = body.set('addedFiles', addData.addedFiles);

    

    return this.http.post('/api/tenderfiles', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllTenderFiles(tenderId: any): Observable<ITenderFile[]> {

    return this.http.get<ITenderFile[]>('/api/tenderfiles/id/'+tenderId);
  }

  
  getTenderFile(itemId: number): Observable<ITenderFile> {
    return this.http.get<ITenderFile>('/api/tenderfiles/'+itemId);
  }


  
  delTenderFile(itemId: number):Observable<any> {
    return this.http.delete('/api/tenderfiles/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}