import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { TenderFile } from '../../models/tender-file.model';
import { ITenderFile } from   '../../interfaces/tender-file.interface';
import { TenderFilesService } from '../../services/tender-files.service';



@Component({
  selector: 'app-tenderfiles',
  templateUrl: './tenderfiles.component.html',
  styleUrls: ['./tenderfiles.component.scss']
})

//        <td><a (click)="openTenderFileDrawer(item.id)">Show</a></td>


export class TenderFilesComponent implements OnInit {
  @Input() tenderId: string = '0';

  private fileListFiles: any[]=[];

  //private allTenderFiles:Array<ITenderFile> = [];

  private allTenderFilesF:Array<ITenderFile> = [];
  private allTenderFilesC:Array<ITenderFile> = [];


  private tenderFileModel = new TenderFile();


  private showTenderFileDrawer:boolean = false;
  private showAddEditTenderFileDrawer:boolean = false;

  private activateTenderFileDrawerSceleton:boolean = false;
  private activateAddEditTenderFileDrawerSceleton:boolean = false;



	

  constructor(private TenderFilesService: TenderFilesService, private MessageService: NzMessageService) {}

  ngOnInit() {

    this._getAllTenderFiles();
  }

  ngOnChanges(changes) {

    this._getAllTenderFiles();
  }

  _getAllTenderFiles(){
    if(+this.tenderId>0){
      this.TenderFilesService.getAllTenderFiles(this.tenderId).subscribe( data => { 
        this._splitTenderFiles(data['data']); 

        console.log(this.allTenderFilesC, this.allTenderFilesF);
      });
    }
  }

  _splitTenderFiles(tenderFiles: any[]){
    this.allTenderFilesC=tenderFiles.filter(tFile => {
      return tFile.dirId==='c';
    });

    this.allTenderFilesF=tenderFiles.filter(tFile => {
      return tFile.dirId==='f';
    });
  }


  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      this.MessageService.success(`${file.name} został dodany.`);
    } else if (status === 'error') {

      this.MessageService.error(`${file.name} nie został dodany.`);
    }
  }


  openAddEditTenderFileDrawer(itemId?: any){

    if(itemId){
	    this.activateAddEditTenderFileDrawerSceleton = true;
      this.showAddEditTenderFileDrawer = true;

        
      this.TenderFilesService.getTenderFile(itemId).subscribe(userData => {
          
          this.tenderFileModel = new TenderFile();

          this.activateAddEditTenderFileDrawerSceleton=false;
        
        });
    
        
    }else{
	  
      this.showAddEditTenderFileDrawer = true;
      
      this.activateAddEditTenderFileDrawerSceleton = false;
      this.tenderFileModel = new TenderFile();

    } 
  
  
  
  }


  openTenderFileDrawer(itemId?: any): void{
 

    if(itemId){

	    this.showTenderFileDrawer = true;
      this.activateTenderFileDrawerSceleton = true;

      
      this.TenderFilesService.getTenderFile(itemId).subscribe(userData => {
      
        this.tenderFileModel = new TenderFile();
      
        this.activateTenderFileDrawerSceleton=false;
      
      });
    
    }else{
	  
		  this.MessageService.error('ERROR');
	  
    }
 
 
  }

  closeTenderFileDrawer(m?: any):void{
	  
    this.showTenderFileDrawer = false;	  

  }
	  

  closeAddEditTenderFileDrawer(formName: NgForm): void{

    formName.form.markAsUntouched();
    
    this.showAddEditTenderFileDrawer = false;
   
  }



  submitAddEditTenderFileForm(formName: NgForm){


    if(+this.tenderFileModel.id>0){


      this.TenderFilesService.updateTenderFile(this.tenderFileModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditTenderFileDrawer = false;
        this.MessageService.success('SAVED');
        
        this.TenderFilesService.getAllTenderFiles(this.tenderId).subscribe( data => {
          this._splitTenderFiles(data['data']); 
        
        });
      
		},
		error => console.log('Error: ', error)
      );


    }else{

      this.tenderFileModel.tenderId=this.tenderId;
    
      this.tenderFileModel.addedFiles=JSON.stringify(this.fileListFiles);

        this.TenderFilesService.addTenderFile(this.tenderFileModel).subscribe( userData => { 
            
            this.fileListFiles=[];
            formName.form.markAsUntouched();
            this.showAddEditTenderFileDrawer = false;
            
            this.MessageService.success('Pliki zostały dodane');

            this.TenderFilesService.getAllTenderFiles(this.tenderId).subscribe( data => {
              this._splitTenderFiles(data['data']); 
            
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmTenderFileDelete(recordId: any){

    this.TenderFilesService.delTenderFile(recordId).subscribe( data => {
	  this.MessageService.success('Plik został usunięty');
      this.TenderFilesService.getAllTenderFiles(this.tenderId).subscribe( data => {
        this._splitTenderFiles(data['data']); 
        
      });        
    });
  
  }

}