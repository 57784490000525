import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ITender } from '../interfaces/tender.interface';
import { Tender } from '../models/tender.model';



@Injectable({
  providedIn: 'root'
})


export class TendersService {

  constructor(private http: HttpClient) { }
  
   
  updateTender(updateData: Tender):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('createdBy', updateData.createdBy);
    body = body.set('tenderDate',    updateData.tenderDate.getFullYear()+'-'+(updateData.tenderDate.getMonth()+1)+'-'+updateData.tenderDate.getDate());
    body = body.set('offerToDate', updateData.offerToDate.getFullYear()+'-'+(updateData.offerToDate.getMonth()+1)+'-'+updateData.offerToDate.getDate());
    body = body.set('investmentDeadline', updateData.investmentDeadline );

    
    body = body.set('founderBudget', updateData.founderBudget);

    body = body.set('founderId', updateData.founderId);
    body = body.set('investmentDescription', updateData.investmentDescription);
    body = body.set('depositAmount', updateData.depositAmount);
    body = body.set('note', updateData.note);
    body = body.set('bidsNumber', updateData.bidsNumber);
    body = body.set('yourOffer', updateData.yourOffer);
    body = body.set('winOffer', updateData.winOffer);
    body = body.set('worksAmount', updateData.worksAmount);
    body = body.set('materialsAmount', updateData.materialsAmount);
    body = body.set('status', updateData.status);

    body = body.set('investmentType', updateData.investmentType);
    body = body.set('priceRate', updateData.priceRate);
    
    body = body.set('competitorsOffers', updateData.competitorsOffers);
    

    body = body.set('questionsDeadline',    updateData.questionsDeadline.getFullYear()+'-'+(updateData.questionsDeadline.getMonth()+1)+'-'+updateData.questionsDeadline.getDate());

    body = body.set('electronicOffer', updateData.electronicOffer);
    body = body.set('bankInfo', updateData.bankInfo);
    body = body.set('tenderLink', updateData.tenderLink);
    body = body.set('otherRates', updateData.otherRates);

    body = body.set('capitalGroupSentDate',    updateData.capitalGroupSentDate===undefined?'':updateData.capitalGroupSentDate.getFullYear()+'-'+(updateData.capitalGroupSentDate.getMonth()+1)+'-'+updateData.capitalGroupSentDate.getDate());

    body = body.set('capitalGroupSent', updateData.capitalGroupSent); 
    body = body.set('siwzTerms', updateData.siwzTerms); 
    body = body.set('RatesNote', updateData.RatesNote); 
    body = body.set('offerNumDays', updateData.offerNumDays);

    body = body.set('competitorsOffersNote', updateData.competitorsOffersNote);
    body = body.set('quotationType', updateData.quotationType);
    body = body.set('quotationNote', updateData.quotationNote);






    return this.http.put('/api/tenders', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addTender(addData: Tender):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('createdBy', addData.createdBy);
    body = body.set('tenderDate',    addData.tenderDate.getFullYear()+'-'+(addData.tenderDate.getMonth()+1)+'-'+addData.tenderDate.getDate());
    body = body.set('offerToDate', addData.offerToDate.getFullYear()+'-'+(addData.offerToDate.getMonth()+1)+'-'+addData.offerToDate.getDate());
    body = body.set('investmentDeadline', addData.investmentDeadline);
    body = body.set('investmentDescription', addData.investmentDescription);
    body = body.set('depositAmount', addData.depositAmount);
    body = body.set('note', addData.note);
    body = body.set('bidsNumber', addData.bidsNumber);
    body = body.set('yourOffer', addData.yourOffer);
    body = body.set('winOffer', addData.winOffer);
    body = body.set('worksAmount', addData.worksAmount);
    body = body.set('materialsAmount', addData.materialsAmount);
    body = body.set('status', addData.status);
  
    body = body.set('investmentType', addData.investmentType);
    body = body.set('priceRate', addData.priceRate);

    body = body.set('competitorsOffers', addData.competitorsOffers);

    body = body.set('founderBudget', addData.founderBudget);
    body = body.set('founderId', addData.founderId);



    body = body.set('otherRates', addData.otherRates);    
    body = body.set('electronicOffer', addData.electronicOffer);    
    body = body.set('bankInfo', addData.bankInfo);    
    body = body.set('tenderLink', addData.tenderLink);    

    body = body.set('questionsDeadline',    addData.questionsDeadline.getFullYear()+'-'+(addData.questionsDeadline.getMonth()+1)+'-'+addData.questionsDeadline.getDate());

    body = body.set('capitalGroupSentDate',    addData.capitalGroupSentDate===undefined?'':addData.capitalGroupSentDate.getFullYear()+'-'+(addData.capitalGroupSentDate.getMonth()+1)+'-'+addData.capitalGroupSentDate.getDate());

    body = body.set('capitalGroupSent', addData.capitalGroupSent); 
    body = body.set('siwzTerms', addData.siwzTerms); 
    body = body.set('RatesNote', addData.RatesNote); 
    body = body.set('offerNumDays', addData.offerNumDays);


    body = body.set('competitorsOffersNote', addData.competitorsOffersNote);
    body = body.set('quotationType', addData.quotationType);
    body = body.set('quotationNote', addData.quotationNote);    

    return this.http.post('/api/tenders', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllTenders(): Observable<ITender[]> {
    return this.http.get<ITender[]>('/api/tenders');
  }

  
  getTender(itemId: number): Observable<ITender> {
    return this.http.get<ITender>('/api/tenders/'+itemId);
  }


  
  delTender(itemId: number):Observable<any> {
    return this.http.delete('/api/tenders/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  getTendersStatsUsers01(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats01');
  }

  getTendersStatsUsers01m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats01m');
  }


  getTendersStatsUsers02(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats02');
  }
  getTendersStatsUsers02m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats02m');
  }


  getTendersStatsUsers03(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats03');
  }
  getTendersStatsUsers03m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats03m');
  }


  getTendersStatsUsers04(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats04');
  }
  getTendersStatsUsers04m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats04m');
  }


  getTendersStatsUsers05(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats05');
  }


  getTendersStatsUsers06(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats06');
  }



  getTendersStatsUsers11(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats11');
  }

  getTendersStatsUsers11m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats11m');
  }
  
  
  getTendersStatsUsers12(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats12');
  }

  getTendersStatsUsers12m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats12m');
  }



  /////////////////////////////////////////////
  getTendersStatsUsers13(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats13');
  }

  getTendersStatsUsers13m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats13m');
  }
  
  
  getTendersStatsUsers14(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats14');
  }

  getTendersStatsUsers14m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats14m');
  }




  getTendersStatsUsers21m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats21m');
  }

  getTendersStatsUsers22m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats22m');
  }
  
  getTendersStatsUsers23m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats23m');
  }
  
  getTendersStatsUsers24m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats24m');
  }
  
  getTendersStatsUsers25m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats25m');
  }
  
  getTendersStatsUsers26m(): Observable<any[]> {
    return this.http.get<any[]>('/api/tenders/stats26m');
  }




  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}