export class TimeTable{

    constructor(

	public id: any = '',
	public projectId: any = '',
	public timeTableDate: any = new Date(),
	public timeTableType: string = 'm',
	public isOfficial: string = '0',
	public timeTableLayout: string = 'n',
	public isFull: any = true,
	
	public showNettoAmount: any = true,
	public showBruttoAmount: any = true,
	public showTaskPeriod: any = true,

	
	public timePeriods: string = 'w',
	public updatesTo: any = 0



    ){

    }
}