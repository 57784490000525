import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';

import { ISubcontractorGroup } from '../interfaces/subcontractor-group.interface';
import { SubcontractorGroup } from '../models/subcontractor-group.model';


@Injectable({
  providedIn: 'root'
})

export class SubcontractorGroupsService {

  constructor(private http: HttpClient) { }
  
   
  updateSubcontractorGroup(updateData: SubcontractorGroup):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('groupId', updateData.groupId);
    body = body.set('groupName', updateData.groupName);
	

    return this.http.put('/api/subcontractorgroups', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addSubcontractorGroup(addData: SubcontractorGroup):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', addData.id);
    body = body.set('groupId', addData.groupId);
    body = body.set('groupName', addData.groupName);
	

    return this.http.post('/api/subcontractorgroups', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllSubcontractorGroups(): Observable<ISubcontractorGroup[]> {
    return this.http.get<ISubcontractorGroup[]>('/api/subcontractorgroups');
  }


  getAvailableSubcontractorGroups(): Observable<ISubcontractorGroup[]> {
    return this.http.get<ISubcontractorGroup[]>('/api/subcontractorgroups/available');
  }


  getMainSubcontractorGroups(): Observable<ISubcontractorGroup[]> {
    return this.http.get<ISubcontractorGroup[]>('/api/subcontractorgroups/main');
  }  
  
  getSubcontractorGroup(itemId: number): Observable<ISubcontractorGroup> {
    return this.http.get<ISubcontractorGroup>('/api/subcontractorgroups/'+itemId);
  }


  
  delSubcontractorGroup(itemId: number):Observable<any> {
    return this.http.delete('/api/subcontractorgroups/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}