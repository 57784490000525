import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { User } from '../../../models/user.model';
import { IUser } from   '../../../interfaces/user.interface';
import { UserService } from '../../../services/user.service';



@Component({
  selector: 'app-show-users',
  templateUrl: './show-users.component.html',
  styleUrls: ['./show-users.component.scss']
})
export class ShowUsersComponent implements OnInit {

  
  private allUsers:Array<IUser> = [];
  private userModel = new User('', '', '3', '','','');

  private filteredAllUsers:Array<IUser> = [];

  
  private usersSortBy:string = '0';  
  private addEditUserDrawerVisible:boolean = false;
  private showUserDrawerVisible:boolean = false;
  private showLoadingSceleton:boolean = false;


  private userExistError:boolean = false;


  private editUserVersion=true;


  constructor(private UserService: UserService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.UserService.getAllUsers().subscribe( data => { 
      this.allUsers = data['data'];
      this.filteredAllUsers=this.allUsers.filter(this._filterUsers, this); 
    });
  }

  
  openShowUserDrawer(userId?:number): void {
    this.showUserDrawerVisible = true;
    
    //this.showLoadingSceleton=true;

    if(userId){
      
      this.UserService.getUser(userId).subscribe(userData => {
        this.userModel = new User(userData['data'].userLogin, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id);

        this.userModel.qrCodeData=`BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:`+userData['data'].userName.replace(/ /i, ";")+`\nORG:Inter-Profil sp z o. o.\nTITLE:Kierownik budowy\nTEL;CELL:`+userData['data'].userPhone+`\nEMAIL:`+userData['data'].userEmail+`\nEND:VCARD`;

        //this.showLoadingSceleton=false;
      });
    
    
    
    }else{
      //this.showLoadingSceleton=false;
      this.userModel = new User('', '', '3', '','',''); 
    }

  }

  closeShowUserDrawer(m?: any): void {
    this.showUserDrawerVisible = false;
  }


  
  changeUserSortBy() {
    this.filteredAllUsers=this.allUsers.filter(this._filterUsers, this);
  }
  
  openAddEditUserDrawer(userId?:number): void {
    this.addEditUserDrawerVisible = true;
    this.showLoadingSceleton=true;

    
    if(userId){
      this.editUserVersion=true;      
      this.UserService.getUser(userId).subscribe(userData => {
        this.userModel = new User(userData['data'].userLogin, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id) 
        this.showLoadingSceleton=false;
      });
    }else{
      this.showLoadingSceleton=false;
      this.editUserVersion=false;      
      this.userModel = new User('', '', '3', '','',''); 
    }
  }

  closeAddEditUserDrawer(formName: NgForm): void {
    this.userExistError=false;
    formName.form.markAsUntouched();
    this.addEditUserDrawerVisible = false;
  }
  
  
  submitForm(formName: NgForm){

    
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    
    

    //alert(regexp.test(this.userModel.userEmail));
    /*
    userEmail: "e"
    userId: "0"
    userLogin: "we"
    userName: "qe"
    userPass: "qwe"
    userPhone: "qwe"
    userType: "3"
    */

    if(+this.userModel.userId>0){
      //alert('Zapisuję edytuje'+this.userModel.userId);

      this.UserService.saveUser(this.userModel).subscribe( userData => { 
        formName.form.markAsUntouched(); 
        this.addEditUserDrawerVisible = false;
        this.MessageService.success('Dane zostały Zapisane');
        this.UserService.getAllUsers().subscribe( data => {
          this.allUsers = data['data'];
          this.filteredAllUsers=this.allUsers.filter(this._filterUsers, this);
        
        });
      
      },
      error => console.log('Error: ', error)
      );


    }else{

      this.UserService.ifUserExist(this.userModel).subscribe( data => {

          if(data.success){
            if(data['data'].userExist==false){

              this.UserService.addUser(this.userModel).subscribe( userData => { 
                  formName.form.markAsUntouched(); 
                  this.addEditUserDrawerVisible = false;
                  this.MessageService.success('Użytkownik został dodany');
                  this.UserService.getAllUsers().subscribe( data => {
                    this.allUsers = data['data'];
                    this.filteredAllUsers=this.allUsers.filter(this._filterUsers, this);
                  
                  });
                
                },
                error => console.log('Error: ', error)
              );

              if(this.userExistError==true){
                this.userExistError=false;
              }

            }else{
              this.userExistError=true;
            }
          }else{
            this.MessageService.error(data.error);
          }


        },
        error => console.log('Error', error)

      );
    }

  
  }


  confirmUserDelete(userId: any){
    
    this.UserService.delUser(userId).subscribe( data => {
      this.UserService.getAllUsers().subscribe( data => {
        this.allUsers = data['data'];
        this.filteredAllUsers=this.allUsers.filter(this._filterUsers, this);
        this.MessageService.success('Użytkownik został usunięty');
      });        
    });
  
  }  
  
  
  
  formForceLoginLowerCase(){
    this.userModel.userLogin=this.userModel.userLogin.toLowerCase();
  }

  forceEmailLowerCase():void{
    this.userModel.userEmail=this.userModel.userEmail.toLowerCase();
  }


  _filterUsers(userData:any){

    if(this.usersSortBy=='0'){
      return true;
    }else{
      return userData.userType==this.usersSortBy;
    }
  }

}