import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { IProjectProtocol } from '../interfaces/project-protocol.interface';
import { ProjectProtocol } from '../models/project-protocol.model';



@Injectable({
  providedIn: 'root'
})


export class ProjectProtocolsService {

  constructor(private http: HttpClient) { }
  
   
  updateProjectProtocol(updateData: ProjectProtocol):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('projectId', updateData.projectId);
    body = body.set('projectCommission', updateData.projectCommission);
    body = body.set('projectAcceptance', updateData.projectAcceptance);

    body = body.set('acceptanceDate', updateData.acceptanceDate.getFullYear()+'-'+(updateData.acceptanceDate.getMonth()+1)+'-'+updateData.acceptanceDate.getDate());
    body = body.set('protocolDateFrom', updateData.protocolDateFrom.getFullYear()+'-'+(updateData.protocolDateFrom.getMonth()+1)+'-'+updateData.protocolDateFrom.getDate());
    body = body.set('protocolDateTo', updateData.protocolDateTo.getFullYear()+'-'+(updateData.protocolDateTo.getMonth()+1)+'-'+updateData.protocolDateTo.getDate());
    body = body.set('projectScore', updateData.projectScore);
    body = body.set('protocolNote', updateData.protocolNote);
	

    return this.http.put('/api/projects/protocols', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addProjectProtocol(addData: ProjectProtocol):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('projectId', addData.projectId);
    body = body.set('projectCommission', addData.projectCommission);
    body = body.set('projectAcceptance', addData.projectAcceptance);    
    body = body.set('protocolType', addData.protocolType);
    

    body = body.set('acceptanceDate', addData.acceptanceDate.getFullYear()+'-'+(addData.acceptanceDate.getMonth()+1)+'-'+addData.acceptanceDate.getDate());
    body = body.set('protocolDateFrom', addData.protocolDateFrom.getFullYear()+'-'+(addData.protocolDateFrom.getMonth()+1)+'-'+addData.protocolDateFrom.getDate());
    body = body.set('protocolDateTo', addData.protocolDateTo.getFullYear()+'-'+(addData.protocolDateTo.getMonth()+1)+'-'+addData.protocolDateTo.getDate());
    body = body.set('projectScore', addData.projectScore);
    body = body.set('protocolNote', addData.protocolNote);
    
    
    



    return this.http.post('/api/projects/protocols', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllProjectProtocols(projectId: any): Observable<IProjectProtocol[]> {
    return this.http.get<IProjectProtocol[]>('/api/projects/protocols/project/'+projectId);
  }

  
  getProjectProtocol(itemId: number): Observable<IProjectProtocol> {
    return this.http.get<IProjectProtocol>('/api/projects/protocols/'+itemId);
  }


  setProjectProtocolOfficial(itemId: string):Observable<any>{
    return this.http.put('/api/projects/protocols/official/'+itemId, {}).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );     
  }

  delProjectProtocol(itemId: number):Observable<any> {
    return this.http.delete('/api/projects/protocols/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}