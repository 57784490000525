import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ISubcontractorsAgreementsItem } from '../interfaces/subcontractors-agreements-item.interface';
import { SubcontractorsAgreementsItem } from '../models/subcontractors-agreements-item.model';



@Injectable({
  providedIn: 'root'
})


export class SubcontractorsAgreementsItemsService {

  constructor(private http: HttpClient) { }
  
   
  updateSubcontractorsAgreementsItem(updateData: SubcontractorsAgreementsItem):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	body = body.set('id', updateData.id);
	body = body.set('agreementId', updateData.agreementId);
	body = body.set('piId', updateData.piId);
	body = body.set('taskName', updateData.taskName);
	body = body.set('toDo', updateData.toDo);
	body = body.set('toDoUnit', updateData.toDoUnit);
	body = body.set('pricePerUnit', updateData.pricePerUnit);
	body = body.set('pricePerUnitName', updateData.pricePerUnitName);
	

    return this.http.put('/api/subcontractorsagreementsitems', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addSubcontractorsAgreementsItem(addData: SubcontractorsAgreementsItem):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	body = body.set('id', addData.id);
	body = body.set('agreementId', addData.agreementId);
	body = body.set('piId', addData.piId);
	body = body.set('taskName', addData.taskName);
	body = body.set('toDo', addData.toDo);
	body = body.set('toDoUnit', addData.toDoUnit);
	body = body.set('pricePerUnit', addData.pricePerUnit);
	body = body.set('pricePerUnitName', addData.pricePerUnitName);
	

    return this.http.post('/api/subcontractorsagreementsitems', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllSubcontractorsAgreementsItems(): Observable<ISubcontractorsAgreementsItem[]> {
    return this.http.get<ISubcontractorsAgreementsItem[]>('/api/subcontractorsagreementsitems');
  }

  
  getSubcontractorsAgreementsItem(itemId: number): Observable<ISubcontractorsAgreementsItem> {
    return this.http.get<ISubcontractorsAgreementsItem>('/api/subcontractorsagreementsitems/'+itemId);
  }


  
  delSubcontractorsAgreementsItem(itemId: number):Observable<any> {
    return this.http.delete('/api/subcontractorsagreementsitems/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}