import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ElectronicOffer'
})
export class ElectronicOfferPipe implements PipeTransform {

  private offerType: Array<any> = [];


  constructor(){
    this.offerType[0]='papierowe';
    this.offerType[1]='elektroniczne';
  }

  transform(optionValue: any): string {
    return this.offerType[Number(optionValue)];
  }

}
