import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-schedule',
  templateUrl: './project-schedule.component.html',
  styleUrls: ['./project-schedule.component.scss']
})
export class ProjectScheduleComponent implements OnInit {

  @Input() projectId: string = '0';

  constructor() { }

  ngOnInit() {
  }

}
