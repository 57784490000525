export class ProjectItem{

    constructor(
        public id: number = 0,
        public projectId: any = 0,
        public taskName: string = '',
        public taskStartDate: Date = new Date(),
        public taskEndDate: Date = new Date(),
        public toDo: string = '',
        public toDoUnit: string = '',
        public toDoDone: string = '',
        public workAmount: string = '',
        public workAmountTotal: string = '',
        public itemAmount: string = '',
        public itemAmountTotal: string = '',
        public doBy: any = 0,
        public planStatus: number = 0,
        public isGroup: any = 0,
        public groupId: any = '0',
        public basicEdit: any = '0',
        public taxRate: any = '23',
        public setToAll: boolean = false   
    ){

    }
}
