import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';




import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, pl_PL } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import pl from '@angular/common/locales/pl';
import { QRCodeModule } from 'angularx-qrcode';

import { NgxChartsModule } from '@swimlane/ngx-charts';

//Pipes
import { UserType } from './pipes/user-type.pipe'; 
import { SucontractorGroupsTypePipe } from './pipes/subcontactor-groups-type.pipe';
import { SupplierGroupsTagsPipe } from './pipes/supplier-groups-tags.pipe';



//Components
import { ShowUsersComponent } from './components/users/show-users/show-users.component';
import { ShowSubcontractorsComponent } from './components/subcontractors/show-subcontractors/show-subcontractors.component';
import { ShowProjectsComponent } from './components/projects/show-projects/show-projects.component';
import { ShowSettingsComponent } from './components/settings/show-settings/show-settings.component';
import { GetProjectComponent } from './components/projects/get-project/get-project.component';

//Services 
import { UserService} from './services/user.service';
import { SubcontractorsService } from './services/subcontractors.service';
import { SubcontractorGroupsService } from './services/subcontractor-groups.service';


import { ProjectFilesComponent } from './components/projects/project-files/project-files.component';
import { ProjectItemsComponent } from './components/projects/project-items/project-items.component';
import { ProjectScheduleComponent } from './components/projects/project-schedule/project-schedule.component';
import { ProjectSubcontractorsComponent } from './components/projects/project-subcontractors/project-subcontractors.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppRoutingModule } from './app-routing.module';
import { SubcontractorsPipe } from './pipes/subcontractors.pipe';
import { TimeTableComponent } from './components/projects/time-table/time-table.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { TimeTableTypePipe } from './pipes/time-table-type.pipe';
import { TimeTableLayoutPipe } from './pipes/time-table-layout.pipe';
import { ProjectProtocolsComponent } from './components/projects/project-protocols/project-protocols.component';
import { SubcontractorGroupsComponent } from './components/subcontractors/subcontractor-groups/subcontractor-groups.component';
import { SupplierGroupsComponent } from './components/suppliers/supplier-groups/supplier-groups.component';
import { SubcontractorGroupsTagsPipe } from './pipes/subcontractor-groups-tags.pipe';
import { CommissionUserTypePipe } from './pipes/commission-user-type.pipe';
import { JsonToAddressPipe } from './pipes/json-to-address.pipe';
import { FilesDirectoriesComponent } from './components/settings/files-directories/files-directories.component';

import { ShowSuppliersComponent } from './components/suppliers/show-suppliers/show-suppliers.component';

import { SubcontractorsofferComponent } from './components/subcontractorsoffer/subcontractorsoffer.component';
import { ToolsComponent } from './components/tools/tools.component';
import { ToolGroupsComponent } from './components/tools/tool-groups/tool-groups.component';
import { TendersComponent } from './components/tenders/tenders.component';
import { TenderFilesComponent } from './components/tenderfiles/tenderfiles.component';
import { TenderStatusPipe } from './pipes/tender-status.pipe';

import { TendersStatsComponent } from './components/tenders/tenders-stats/tenders-stats.component';
import { TenderQuestionsComponent } from './components/tenders/tender-questions/tender-questions.component';
import { TenderInvestmentTypePipe } from './pipes/tender-investment-type.pipe';
import { QuotationTypePipe } from './pipes/quotation-type.pipe';
import { ElectronicOfferPipe } from './pipes/electronic-offer.pipe';
import { AuthComponent } from './components/auth/auth.component';




registerLocaleData(pl);


@NgModule({
  declarations: [
    AppComponent,
    ShowUsersComponent,
    ShowSubcontractorsComponent,
    ShowSuppliersComponent,
    ShowProjectsComponent,
    ShowSettingsComponent,
    GetProjectComponent,
    UserType,
    ProjectFilesComponent,
    ProjectItemsComponent,
    ProjectScheduleComponent,
    ProjectSubcontractorsComponent,
    SubcontractorsPipe,
    TimeTableComponent,
    YesNoPipe,
    SucontractorGroupsTypePipe,
    SupplierGroupsTagsPipe,
    TimeTableTypePipe,
    TimeTableLayoutPipe,
    ProjectProtocolsComponent,
    SubcontractorGroupsComponent,
    SupplierGroupsComponent,
    SubcontractorGroupsTagsPipe,
    CommissionUserTypePipe,
    JsonToAddressPipe,
    FilesDirectoriesComponent,
    SubcontractorsofferComponent,
    ToolsComponent,
    ToolGroupsComponent,
    TendersComponent,
    TenderFilesComponent,
    TenderStatusPipe,
    TendersStatsComponent,
    TenderQuestionsComponent,
    TenderInvestmentTypePipe,
    QuotationTypePipe,
    ElectronicOfferPipe,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    ReactiveFormsModule,
    QRCodeModule,
    CKEditorModule,
    NgxChartsModule

  ],
  providers: [
    { provide: NZ_I18N, useValue: pl_PL },
    UserService,
    SubcontractorsService,
    SubcontractorGroupsService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
