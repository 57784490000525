import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'YesNo'
})
export class YesNoPipe implements PipeTransform {
  private yesNo: Array<any> = [];


  constructor(){
    this.yesNo[0]='Nie';
    this.yesNo[1]='Tak';
  }

  transform(optionValue: any): string {
    return this.yesNo[Number(optionValue)];
  }

}
