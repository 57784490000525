import { Component, OnInit, Input } from '@angular/core';


import { NgForm } from '@angular/forms';

//import { FormBuilder, FormControl, , Validators } from '@angular/forms';


import { NzMessageService } from 'ng-zorro-antd';

import { TimeTable } from '../../../models/timetable.model';
import { ITimeTable } from   '../../../interfaces/timetable.interface';
import { TimeTableService } from '../../../services/time-table.service';

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.scss']
})
export class TimeTableComponent implements OnInit {
  @Input() projectId: string = '0';
  
  private editInstalment: any = [];
  private totalEditInstalmentsAmount: number = 0.0;

  private allTimeTable:Array<ITimeTable> = [];
  private timeTablesWithValues: Array<any> =[];
  private extraColumns: Array<any> =[];

  private timeTableModel = new TimeTable();


  private showTimeTableDrawer:boolean = false;
  private showAddEditTimeTableDrawer:boolean = false;

  private showEditTimeTableAmountDrawer:boolean = false;


  private activateTimeTableDrawerSceleton:boolean = false;
  private activateAddEditTimeTableDrawerSceleton:boolean = false;
  private isTimeTableEditingDisebled:boolean = false;


  private timeTablePeriodsDisabled: boolean = true;


  private showEditInstalmentsDrawer: boolean = false;
  private showEditInstalmentsDrawerItemId: any = '0';
  private arrayEditInstalments: any = [];



  constructor(private TimeTableService: TimeTableService, private MessageService: NzMessageService) {

    //this.projectId
  }


  changeTableLayoutSelect(layoutId: any){
    
    if(layoutId==='v'){  
      if(+this.timeTableModel.id>0){
        this.timeTablePeriodsDisabled=true;
      }else{
        this.timeTablePeriodsDisabled=false;
      }
    }else{
      this.timeTablePeriodsDisabled=true;
    }
  }

  addColumn(){
    this.extraColumns.push({
      key: (this.extraColumns.length+2).toString(),
      name: 'John Brown',
      age: 32,
      address: 'New York'
    });
  }
  ngOnInit() {
    this.TimeTableService.getAllTimeTable(this.projectId).subscribe( data => { 
      this.allTimeTable = data['data']; 
    });
  }


  confirmTimeTableSetOfficial(itemId: any){

    this.TimeTableService.setTimeTableOfficial(itemId).subscribe( userData => { 

        this.MessageService.success('Zrobione');
      
          this.TimeTableService.getAllTimeTable(this.projectId).subscribe( data => {
            this.allTimeTable = data['data'];
          });
    
      },
      error => console.log('Error: ', error)
    );


  }




  openAddEditTimeTableDrawer(itemId?: any){
  
    this.showAddEditTimeTableDrawer = true;
    this.activateAddEditTimeTableDrawerSceleton = true;
    
    this.timeTablePeriodsDisabled=true;

    if(itemId){
      
      this.isTimeTableEditingDisebled=true;
      this.TimeTableService.getTimeTable(itemId).subscribe(userData => {
       
      //this.timeTableModel = new User(, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id);

      this.timeTableModel = new TimeTable();
      this.timeTableModel.projectId = this.projectId;       

      this.timeTableModel.id = userData['data'].id;       
      this.timeTableModel.timeTableDate = new Date(userData['data'].timeTableDate);       

      this.timeTableModel.timeTableType = userData['data'].timeTableType;       
      this.timeTableModel.timeTableLayout = userData['data'].timeTableLayout;       
      this.timeTableModel.isFull = userData['data'].isFull=='1'?true:false;       
      
      this.timeTableModel.showNettoAmount = userData['data'].showNettoAmount=='1'?true:false; 
      this.timeTableModel.showBruttoAmount = userData['data'].showBruttoAmount=='1'?true:false; 
      this.timeTableModel.showTaskPeriod = userData['data'].showTaskPeriod=='1'?true:false;

      
      this.timeTableModel.timePeriods = userData['data'].timePeriods;       

      this.timeTableModel.updatesTo = userData['data'].updatesTo;

      this.activateAddEditTimeTableDrawerSceleton=false;
     
      });

    }else{
      
      this.isTimeTableEditingDisebled=false;
      this.activateAddEditTimeTableDrawerSceleton = false;
      this.timeTableModel = new TimeTable();
      this.timeTableModel.projectId = this.projectId;
    
    }
  }

  trackById(index){
    return index;
  }

  trackByInsId(index){
    return index;
  }
  


  editInstalments(itemData: any){

    const arrayKeyToLabel = (keyTxy) => { 
      let monthWord=[];
      monthWord['01']='Styczeń';  monthWord['02']='Luty';   monthWord['03']='Marzec';
      monthWord['04']='Kwiecień'; monthWord['05']='Maj';    monthWord['06']='Czerwiec';
      monthWord['07']='Lipiec';   monthWord['08']='Sierpień'; monthWord['09']='Wrzesień';
      monthWord['10']='Październik'; monthWord['11']='Listopad'; monthWord['12']='Grudzień';
            
      return  monthWord[keyTxy.slice(4, 6)] + ' ' + keyTxy.slice(0, 4);
    }

    this.arrayEditInstalments = [];
    this.totalEditInstalmentsAmount = Number(itemData['taskAmountTotal']);

    const tmpItem=JSON.parse(itemData['taskInstalmentsJson']);
    let j=0;

    for(var i in tmpItem){
      
      this.arrayEditInstalments.push({instKey: i, instVal: tmpItem[i], isDisabled: false, instLabel: arrayKeyToLabel(i) });
      j++;
    }
    
    this.arrayEditInstalments[this.arrayEditInstalments.length-1].isDisabled=true;
    this.showEditInstalmentsDrawerItemId=itemData.tid;
    this.showEditInstalmentsDrawer=true;
  }

  onKeyDownInstalment(instVal){
    instVal.instVal=instVal.instVal.replace(',', '.');
  }

  onChangeInstalment(){
  
    //this.totalEditInstalmentsAmount
    const numItems=this.arrayEditInstalments.length;
    let tmpInstAmount=0.0;
    for(let i=0; i<numItems-1; i++){
      tmpInstAmount+=Number(this.arrayEditInstalments[i].instVal);
    }

    this.arrayEditInstalments[(numItems-1)].instVal = Math.round((this.totalEditInstalmentsAmount-tmpInstAmount)*100)/100;  
  }

  saveInstalments(timeItemId:any = '0'){

    const numItems=this.arrayEditInstalments.length;
    let allCorrect=true;

    let tmpInstAmount=0.0;
    for(let i=0; i<numItems; i++){

      if(Number(this.arrayEditInstalments[i].instVal)<=0.0){
        allCorrect=false;
      }
      tmpInstAmount+=Number(this.arrayEditInstalments[i].instVal);
    }
    
    tmpInstAmount = Math.round(tmpInstAmount*100)/100;


    if(allCorrect){
      if(tmpInstAmount===this.totalEditInstalmentsAmount){
        this.MessageService.success('Wygląda OK');
        //Service zapisać 

        const instData={tid: timeItemId, instalments: JSON.stringify(this.arrayEditInstalments)};
        
        this.TimeTableService.updateTimeTableInstalments(instData).subscribe(()=>{

          /*
          this.TimeTableService.getTimeTableValues(itemId).subscribe(itemData => {
            this.extraColumns=itemData['data'][0]['taskInstalments'];
            this.timeTablesWithValues=itemData['data'];
            //console.log('COL ',this.timeTablesWithValues);
          });
          */

        });

      }else{
        this.MessageService.error('Wartość rat nie jest równa wartości elementu '+tmpInstAmount+' == '+this.totalEditInstalmentsAmount);
      }
    }else{
      this.MessageService.error('Wartość kazdej raty musi być większa od zera');
    }

  }


  closeEditInstalments(itemData: any){
    this.showEditInstalmentsDrawer=false;
    this.arrayEditInstalments = [];
  }


  openTimeTableAmountDrawer(itemId?: any): void{

    this.TimeTableService.getTimeTableValues(itemId).subscribe(itemData => {

      this.extraColumns=itemData['data'][0]['taskInstalments'];
      this.timeTablesWithValues=itemData['data'];

      //console.log('COL ',this.timeTablesWithValues);

    });
    this.showEditTimeTableAmountDrawer = true;
  }
  
  openTimeTableDrawer(itemId?: any): void{

   this.showTimeTableDrawer = true;
   
   this.activateTimeTableDrawerSceleton = true;

   if(itemId){
     
     this.TimeTableService.getTimeTable(itemId).subscribe(userData => {
       
     //  this.timeTableModel = new User(userData['data'].userLogin, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id);


       this.activateTimeTableDrawerSceleton=false;
     
     });
   
   
   
   }else{
   
      this.activateTimeTableDrawerSceleton = false;
      this.timeTableModel = new TimeTable();

   }


 
 }

 closeTimeTableDrawer(m?: any):void{
   
   this.showTimeTableDrawer = false;	  

 }
   
 closeEditTimeTableAmountDrawer(formName: NgForm): void{

  formName.form.markAsUntouched();
  this.showEditTimeTableAmountDrawer = false;
 }

 closeAddEditTimeTableDrawer(formName: NgForm): void{

   formName.form.markAsUntouched();
    
   //this.userExistError=false;
   this.showAddEditTimeTableDrawer = false;
  
 }


 submitAddEditTimeTableForm(formName: NgForm){

    if(+this.timeTableModel.id>0){

      this.TimeTableService.updateTimeTable(this.timeTableModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditTimeTableDrawer = false;
        this.MessageService.success('Zapisałem');
        
          this.TimeTableService.getAllTimeTable(this.projectId).subscribe( data => {
          this.allTimeTable = data['data'];
        
        });
      
      },
      error => console.log('Error: ', error)
      );

    }else{

       this.TimeTableService.addTimeTable(this.timeTableModel).subscribe( userData => { 
           
           formName.form.markAsUntouched(); 
           this.showAddEditTimeTableDrawer = false;
           
           this.MessageService.success('Harmonogram został dodany.');

           this.TimeTableService.getAllTimeTable(this.projectId).subscribe( data => {
             this.allTimeTable = data['data'];
           
           });
         
         },
         error => console.log('Error: ', error)
       );
       
   }





 }


 confirmTimeTableDelete(recordId: any){

   this.TimeTableService.delTimeTable(recordId).subscribe( data => {
     this.TimeTableService.getAllTimeTable(this.projectId).subscribe( data => {
       this.allTimeTable = data['data'];
       this.MessageService.success('RECORD został usunięty');
     });        
   });
 
 }





}
