export class SubcontractorsOffer{

    constructor(

	public id: any = 0,
	public groupName: any = '',
	public subId: any = null,
	public offerAskDay: any = '',
	public offerDay: any = '',
	public uniteNetPrice: string = '',
	public netAmount: string = '',
	public offerNote: string = '',
	public supOrSub: any = '0',
	public fileId: any = ''

    ){

    }
}