import { Component, OnInit, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NzMessageService } from 'ng-zorro-antd';

import { TendersService } from '../../../services/tenders.service';


@Component({
  selector: 'app-tenders-stats',
  templateUrl: './tenders-stats.component.html',
  styleUrls: ['./tenders-stats.component.scss']
})
export class TendersStatsComponent implements OnInit {

  multi: any[]  = [];  


   //Tender Stats per Office User 
  statsData01: any[] = [];
  statsData01m: any[] = [];

  statsData02: any[] = [];
  statsData02m: any[] = [];

  statsData03: any[] = [];
  statsData03m: any[] = [];

  statsData04: any[] = [];
  statsData04m: any[] = [];

  statsData05: any[] = [];
  statsData06: any[] = [];

  //Tender Stats
  statsData11: any[] = [];
  statsData11m: any[] = [];

  statsData12: any[] = [];
  statsData12m: any[] = [];

  statsData13: any[] = [];  
  statsData13m: any[] = [];

  statsData14: any[] = [];
  statsData14m: any[] = [];


  statsData21m: any[] = [];
  statsData22m: any[] = [];

  statsData23m: any[] = [];
  statsData24m: any[] = [];

  statsData25m: any[] = [];
  statsData26m: any[] = [];



  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  colorSchemeYear01 = {
    domain: ['#06D6A0', '#02A9EA', '#EF476F', '#FFC43D']
  };

  colorSchemeYear02 = {
    domain: ['#FF8811', '#118AB2', '#EF476F', '#06D6A0',  '#073B4C']
  };




  constructor(private TendersService: TendersService, private MessageService: NzMessageService) {
    //Object.assign(this, { multi: this.multi });

    this.TendersService.getTendersStatsUsers01().subscribe( data => { 
      this.statsData01 = data['data']; 
    });
    this.TendersService.getTendersStatsUsers01m().subscribe( data => { 
      this.statsData01m = data['data']; 
    });


    this.TendersService.getTendersStatsUsers02().subscribe( data => { 
      this.statsData02 = data['data']; 
    });
    this.TendersService.getTendersStatsUsers02m().subscribe( data => { 
      this.statsData02m = data['data']; 
    });


    this.TendersService.getTendersStatsUsers03().subscribe( data => { 
      this.statsData03 = data['data']; 
    });

    this.TendersService.getTendersStatsUsers03m().subscribe( data => { 
      this.statsData03m = data['data']; 
    });

    this.TendersService.getTendersStatsUsers04().subscribe( data => { 
      this.statsData04 = data['data']; 
    });

    this.TendersService.getTendersStatsUsers04m().subscribe( data => { 
      this.statsData04m = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers05().subscribe( data => { 
      this.statsData05 = data['data']; 
    });

    this.TendersService.getTendersStatsUsers06().subscribe( data => { 
      this.statsData06 = data['data']; 
    });    


    this.TendersService.getTendersStatsUsers11().subscribe( data => { 
      this.statsData11 = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers11m().subscribe( data => { 
      this.statsData11m = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers12().subscribe( data => { 
      this.statsData12 = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers12m().subscribe( data => { 
      this.statsData12m = data['data']; 
    });    


    //////////////////////////////////////////////////////////////////////////////////////
    this.TendersService.getTendersStatsUsers13().subscribe( data => { 
      this.statsData13 = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers13m().subscribe( data => { 
      this.statsData13m = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers14().subscribe( data => { 
      this.statsData14 = data['data']; 
    });    

    this.TendersService.getTendersStatsUsers14m().subscribe( data => { 
      this.statsData14m = data['data']; 
    });  




    //////////////////////////////////////////////////////////////////////////////////////
    this.TendersService.getTendersStatsUsers21m().subscribe( data => { 
      this.statsData21m = data['data']; 
    });  

    this.TendersService.getTendersStatsUsers22m().subscribe( data => { 
      this.statsData22m = data['data']; 
    });  

    this.TendersService.getTendersStatsUsers23m().subscribe( data => { 
      this.statsData23m = data['data']; 
    });  

    this.TendersService.getTendersStatsUsers24m().subscribe( data => { 
      this.statsData24m = data['data']; 
    });  

    this.TendersService.getTendersStatsUsers25m().subscribe( data => { 
      this.statsData25m = data['data']; 
    });  

    this.TendersService.getTendersStatsUsers26m().subscribe( data => { 
      this.statsData26m = data['data']; 
    });  

  }


  ngOnInit() {
  
  }


  onChangeTab(event){
    window.dispatchEvent(new Event('resize'));
  }


  onSelect(event) {
    console.log(event);
  }


}
