export class ContactSettings{
    
    constructor(
        public companyTel: string = '',
        public companyPostcode: string = '',
        public companyRegon: string = '',
        public companyNip: string = '',
        public companyEmail: string = '',
        public companyShortName: string = '',
        public companyName: string = '',
        public companyAddress: string = '',
        public companyCity: string = '',
        public companyBankAccountNr: string = '',
        public companyWeb: string = ''
    ){

    }
}