import { Component, OnInit } from '@angular/core';
import { NgForm, SelectMultipleControlValueAccessor } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { Tender } from '../../models/tender.model';
import { ITender } from   '../../interfaces/tender.interface';
import { TendersService } from '../../services/tenders.service';

import { TenderFoundersService } from '../../services/tender-founders.service';
import { ITenderFounder } from '../../interfaces/tender-founder.interface';

import { TenderFounder } from '../../models/tender-founder.model'

@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.scss']
})

export class TendersComponent implements OnInit {

  private tenderFounderModel = new TenderFounder();

  private questionsDeadlineStyle = {};

  private tenderId:any = 0;
  private allTenders:Array<ITender> = [];

  private _allTendersFiltered:Array<ITender> = [];

  
  private allTenderFounders:Array<ITenderFounder>=[];

  private tenderModel = new Tender();


  private showTenderDrawer:boolean = false;
  private showAddEditTenderDrawer:boolean = false;

  private activateTenderDrawerSceleton:boolean = false;
  private activateAddEditTenderDrawerSceleton:boolean = false;


  private _serachWord:any='';
  private _searchStatus:any=null;
  private _searchGroup:any=null;
  
  private projectCommissionTable: any = [];
  private projectCommissionCache: any = {}; 


  private tenderRateTable: any = [];
  private tenderRateCache: any = {}; 






  private showTenderFounderDrawer:boolean = false;
  private showAddEditTenderFounderDrawer:boolean = false;

  private activateTenderFounderDrawerSceleton:boolean = false;
  private activateAddEditTenderFounderDrawerSceleton:boolean = false;


  private isFounderOpen:boolean = false;


  constructor(private TendersService: TendersService, private MessageService: NzMessageService, private TenderFoundersService: TenderFoundersService) {}

  ngOnInit(){

    this.TendersService.getAllTenders().subscribe( data => { 
      this.allTenders = data['data']; 
    
    
      this.TenderFoundersService.getAllTenderFounders().subscribe( data => { 
        this.allTenderFounders = data['data']; 
  
        this._allTendersFiltered=this.allTenders.filter(tender=>this.filterItems(tender));
  
      });    
    
    });




  }

  formatterCourency(value: number){
    let formatter = new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    if(typeof value !== "undefined"){
      return formatter.format(value);
    }else{
      return undefined;
    }
  }





  ////////////////////////////////////////

  submitAddEditTenderFounderForm(formName: NgForm){

    this.TenderFoundersService.addTenderFounder(this.tenderFounderModel).subscribe( userData => { 
        
        formName.form.markAsUntouched(); 
        this.showAddEditTenderFounderDrawer = false;
        
        this.MessageService.success('Inwestor został dodany');

        this.TenderFoundersService.getAllTenderFounders().subscribe( data => {
          this.allTenderFounders = data['data'];
        
        });
      
      },
      error => console.log('Error: ', error)
    );
    
  }


  closeAddEditTenderFounderDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditTenderFounderDrawer = false;
   
  }

  addFounder(){

    this.showAddEditTenderFounderDrawer=true;
    this.isFounderOpen=false;
  }
  //Tender competitor offers



  //////////////////////////////////////
  addCommissionPerson(){
    
    this.projectCommissionTable = [ ...this.projectCommissionTable, {
      id: this.projectCommissionTable.length+1,
      offerAmount:   0,
      offerCompetitor: ''
    }];


    this.updateEditCommissionCache();
  }

  startCommissionEdit(id: string): void{
    this.projectCommissionCache[ id ].edit = true; 
  }

  saveCommissionEdit(id: string): void{
    
    const index = this.projectCommissionTable.findIndex(item => item.id === id);
    Object.assign(this.projectCommissionTable[ index ], this.projectCommissionCache[ id ].data);
    this.projectCommissionCache[ id ].edit = false;
  
  
  }

  
  cancelCommissionEdit(id: string): void {

    this.projectCommissionTable = this.projectCommissionTable.filter( (commission) => commission.id!==id);

    this.updateEditCommissionCache();
  }

  updateEditCommissionCache(): void {
    this.projectCommissionTable.forEach(item => {
      
      this.projectCommissionCache[ item.id ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }



  /////////////////////////////


  addTenderRate(){
    

    this.tenderRateTable = [ ...this.tenderRateTable, {
      id: this.tenderRateTable.length+1,
      tenderRateName:   'Nazwa wagi',
      tenderRateValue:  10
    }];


    this.updateEditTenderRateCache();

  }

  startTenderRateEdit(id: string): void{
    this.tenderRateCache[ id ].edit = true; 
  }

  saveTenderRateEdit(id: string): void{
    
    const index = this.tenderRateTable.findIndex(item => item.id === id);
    Object.assign(this.tenderRateTable[ index ], this.tenderRateCache[ id ].data);
    this.tenderRateCache[ id ].edit = false;
  
  
  }

  
  cancelTenderRateEdit(id: string): void {

    this.tenderRateTable = this.tenderRateTable.filter( (tenderRate) => tenderRate.id!==id);

    this.updateEditTenderRateCache();
  }

  updateEditTenderRateCache(): void {
    this.tenderRateTable.forEach(item => {
      
      this.tenderRateCache[ item.id ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }





  //////////////////////////////

  filterItems(tender:ITender){
    let _toolNameCondition:boolean=false;
    let _toolGroupCondition:boolean=false;
    let _toolStatusCondition:boolean=false;

    if(this._serachWord===''){
      _toolNameCondition=true;
    }else if(tender.investmentDescription.toLowerCase().includes(this._serachWord.toLowerCase()) || tender.investmentDescription.toLowerCase().includes(this._serachWord.toLowerCase())){
      _toolNameCondition=true;
    }else{
      _toolNameCondition=false;
    }

    if(this._searchGroup===null){
      _toolGroupCondition=true;
    }else if(tender.founderId==this._searchGroup){
      _toolGroupCondition=true;
    }else{
      _toolGroupCondition=false;
    }

    if(this._searchStatus===null){
      _toolStatusCondition=true;
    }else if(tender.status==this._searchStatus){
      _toolStatusCondition=true;
    }else{
      _toolStatusCondition=false;
    }  





    return  (_toolNameCondition && _toolGroupCondition && _toolStatusCondition);
  
  }

  searchSubcontractorsClear(){
    this._serachWord='';
    this._searchStatus=null;
    this._searchGroup=null;

    this._allTendersFiltered=this.allTenders.filter(tender=>this.filterItems(tender));
  }

  searchSubcontractors(){
    this._allTendersFiltered=this.allTenders.filter(tender=>this.filterItems(tender));
  }



  _changeOfferToDate(e){


    if(this.tenderModel['tenderDate'] instanceof Date && e instanceof Date){

      if(this.tenderModel['tenderDate'].getTime()>=e.getTime()){
        this.MessageService.error('BŁĄD!!! Termin składania oferty jest wcześniejszy niż data ogłoszenia przetargu');
      }else{

        this.tenderModel['questionsDeadline']=new Date((this.tenderModel['tenderDate'].getTime() + e.getTime()) / 2);

        this.questionsDeadlineStyle={border: '1px solid #ff0000'};


        setTimeout( ()=>{
          this.questionsDeadlineStyle={border: '0px'};
        }, 900);
      }
    
    }


  }
  openAddEditTenderDrawer(itemId?: any){
    
    this.projectCommissionTable=[];
    this.tenderRateTable=[];

    

    if(itemId){
	    this.activateAddEditTenderDrawerSceleton = true;
		  this.showAddEditTenderDrawer = true;

      
      this.TendersService.getTender(itemId).subscribe(tenderData => {
          
          tenderData=tenderData['data'];
          this.tenderModel = new Tender();


          this.tenderModel['id']=tenderData['id'];

          this.tenderModel['offerToDate']=tenderData['offerToDate']==='0000-00-00'?undefined:new Date(tenderData['offerToDate']);
          this.tenderModel['tenderDate']=tenderData['tenderDate']==='0000-00-00'?undefined:new Date(tenderData['tenderDate']);

          this.tenderModel['createdBy']=tenderData['createdBy'];
          this.tenderModel['investmentDeadline']= tenderData['investmentDeadline'];

          this.tenderModel['founderId']=tenderData['founderId'];
          this.tenderModel['founderBudget']=tenderData['founderBudget'];

          this.tenderModel['investmentDescription']=tenderData['investmentDescription'];
          this.tenderModel['depositAmount']=tenderData['depositAmount'];
          this.tenderModel['note']=tenderData['note'];
          this.tenderModel['bidsNumber']=tenderData['bidsNumber'];
          this.tenderModel['yourOffer']=tenderData['yourOffer'];
          this.tenderModel['winOffer']=tenderData['winOffer'];

          this.tenderModel['worksAmount']=tenderData['worksAmount'];
          this.tenderModel['materialsAmount']=tenderData['materialsAmount'];
          this.tenderModel['status']=tenderData['status'];

          this.tenderModel['investmentType']=tenderData['investmentType'];

          this.tenderModel['priceRate']=tenderData['priceRate'];
          

          this.tenderModel['questionsDeadline']=tenderData['questionsDeadline']==='0000-00-00'?undefined:new Date(tenderData['questionsDeadline']);


          this.tenderModel['electronicOffer']=tenderData['electronicOffer'];
          this.tenderModel['bankInfo']=tenderData['bankInfo'];
          this.tenderModel['tenderLink']=tenderData['tenderLink'];
          this.tenderModel['otherRates']=tenderData['otherRates'];



          this.tenderModel['capitalGroupSentDate']=(tenderData['capitalGroupSentDate']=='0000-00-00')?undefined:new Date(tenderData['capitalGroupSentDate']);

          this.tenderModel['capitalGroupSent']=tenderData['capitalGroupSent'];
          this.tenderModel['siwzTerms']=tenderData['siwzTerms'];
          this.tenderModel['RatesNote']=tenderData['RatesNote'];
          this.tenderModel['offerNumDays']=tenderData['offerNumDays'];




          this.tenderModel['competitorsOffersNote']=tenderData['competitorsOffersNote'];
          this.tenderModel['quotationType']=tenderData['quotationType'];
          this.tenderModel['quotationNote']=tenderData['quotationNote'];          

          let proCom=JSON.parse(tenderData.competitorsOffers);
          let proTenderRates=JSON.parse(tenderData.otherRates);
          

          proCom.forEach(item => {
            this.projectCommissionTable = [ ...this.projectCommissionTable, {
              id: this.projectCommissionTable.length+1,
              offerAmount:   item.offerAmount,
              offerCompetitor: item.offerCompetitor

            }];
          })


          proTenderRates.forEach(item => {
            this.tenderRateTable = [ ...this.tenderRateTable, {
              id: this.tenderRateTable.length+1,
              tenderRateName:   item.tenderRateName,
              tenderRateValue:  item.tenderRateValue

            }];
          })          


          

          this.updateEditCommissionCache();
          this.updateEditTenderRateCache();

          this.activateAddEditTenderDrawerSceleton=false;
        
        });
    
        
    }else{
	  
      this.showAddEditTenderDrawer = true;      
      this.activateAddEditTenderDrawerSceleton = false;
      this.tenderModel = new Tender();

      this.tenderModel['status']='0';

      this.tenderModel['founderId']='1';
      

      this.tenderModel['investmentType']='c';
      this.tenderModel['priceRate']='70';
      this.tenderModel['depositAmount']='20000'
      this.tenderModel['tenderDate']= new Date();
      this.tenderModel['offerToDate']= new Date(new Date().getTime() + (14 * 24 * 60 * 60 * 1000));

      this.tenderModel['questionsDeadline']=new Date((this.tenderModel['tenderDate'].getTime() + this.tenderModel['offerToDate'].getTime()) / 2);

      this.tenderModel['electronicOffer']='0';
      this.tenderModel['bankInfo']='0';
      this.tenderModel['tenderLink']='';

    } 
  
  
  
  }


  openTenderDrawer(itemId?: any): void{
 
    this.projectCommissionTable=[];
    this.tenderRateTable=[];

    if(itemId){

	    this.showTenderDrawer = true;
		  this.activateTenderDrawerSceleton = true;

      this.tenderId = itemId;
		
      this.TendersService.getTender(itemId).subscribe(tenderData => {
        tenderData=tenderData['data'];

        this.tenderModel = new Tender();

        this.tenderModel['tenderDate']=tenderData['tenderDate']==='0000-00-00'?undefined:tenderData['tenderDate'];
        this.tenderModel['createdBy']=tenderData['createdBy'];
        this.tenderModel['offerToDate']=tenderData['offerToDate']==='0000-00-00'?undefined:tenderData['offerToDate'];
        this.tenderModel['investmentDeadline']=tenderData['investmentDeadline'];

        
        this.tenderModel['founderName']=tenderData['founderName'];

        this.tenderModel['founderId']=tenderData['founderId'];

        this.tenderModel['founderBudget']=tenderData['founderBudget'];
        this.tenderModel['investmentDescription']=tenderData['investmentDescription'];
        this.tenderModel['depositAmount']=tenderData['depositAmount'];
        this.tenderModel['note']=tenderData['note'];
        this.tenderModel['bidsNumber']=tenderData['bidsNumber'];
        this.tenderModel['yourOffer']=tenderData['yourOffer'];
        this.tenderModel['winOffer']=tenderData['winOffer'];
        this.tenderModel['worksAmount']=tenderData['worksAmount'];
        this.tenderModel['materialsAmount']=tenderData['materialsAmount'];
        this.tenderModel['status']=tenderData['status'];

        this.tenderModel['priceRate']=tenderData['priceRate'];

        this.tenderModel['investmentType']=tenderData['investmentType'];


        this.tenderModel['questionsDeadline']=tenderData['questionsDeadline']==='0000-00-00'?undefined:tenderData['questionsDeadline'];

        this.tenderModel['capitalGroupSent']=tenderData['capitalGroupSent'];
        this.tenderModel['capitalGroupSentDate']=tenderData['capitalGroupSentDate']==='0000-00-00'?undefined:tenderData['capitalGroupSentDate'];
        this.tenderModel['siwzTerms']=tenderData['siwzTerms'];
        this.tenderModel['RatesNote']=tenderData['RatesNote'];
        this.tenderModel['offerNumDays']=tenderData['offerNumDays'];

        this.tenderModel['tenderLink']=tenderData['tenderLink'];
        
        this.tenderModel['avgOfferAmount']=tenderData['avgOfferAmount'];
        this.tenderModel['offerDeviation']=tenderData['offerDeviation'];

        this.tenderModel['bankInfo']=tenderData['bankInfo'];


        this.tenderModel['competitorsOffersNote']=tenderData['competitorsOffersNote'];
        this.tenderModel['quotationType']=tenderData['quotationType'];
        this.tenderModel['quotationNote']=tenderData['quotationNote'];
        





        let arrCompetitors=JSON.parse(tenderData.competitorsOffers);
        let arrRates=JSON.parse(tenderData.otherRates);


        arrCompetitors.forEach(item => {
          this.projectCommissionTable = [ ...this.projectCommissionTable, {
            id: this.projectCommissionTable.length+1,
            offerAmount:   item.offerAmount,
            offerCompetitor: item.offerCompetitor
          }];
        })


        arrRates.forEach(item => {
          this.tenderRateTable = [ ...this.tenderRateTable, {
            id: this.tenderRateTable.length+1,
            tenderRateName:   item.tenderRateName,
            tenderRateValue:  item.tenderRateValue

          }];
        })          


        

        this.updateEditCommissionCache();
        this.updateEditTenderRateCache();        

        this.activateTenderDrawerSceleton=false;
      
      });
    
    }else{  
  		this.MessageService.error('Błąd');
    }
 
 
  }

  closeTenderDrawer(m?: any):void{
	  
    this.showTenderDrawer = false;	  

  }
	  

  closeAddEditTenderDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditTenderDrawer = false;
   
  }


  submitAddEditTenderForm(formName: NgForm){


    this.tenderModel.competitorsOffers=JSON.stringify(this.projectCommissionTable); 
    this.tenderModel.otherRates=JSON.stringify(this.tenderRateTable); 

    if(+this.tenderModel.id>0){


      this.TendersService.updateTender(this.tenderModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditTenderDrawer = false;
        this.MessageService.success('Zmiany zostały zapisane');
        
        this.TendersService.getAllTenders().subscribe( data => {
          this.allTenders = data['data'];
          this._allTendersFiltered=this.allTenders.filter(tender=>this.filterItems(tender));
        
        });
      
		},
		error => console.log('Error: ', error)
      );


    }else{

        this.TendersService.addTender(this.tenderModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditTenderDrawer = false;
            
            this.MessageService.success('Przetarg został dodany');

            this.TendersService.getAllTenders().subscribe( data => {
              this.allTenders = data['data'];
              this._allTendersFiltered=this.allTenders.filter(tender=>this.filterItems(tender));            
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmTenderDelete(recordId: any){

    this.TendersService.delTender(recordId).subscribe( data => {
	  this.MessageService.success('Przetarg został usunięty');
      this.TendersService.getAllTenders().subscribe( data => {
        this.allTenders = data['data'];
        
        this._allTendersFiltered=this.allTenders.filter(tender=>this.filterItems(tender));

      });        
    });
  
  }





}
