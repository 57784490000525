import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ISubcontractorsOffer } from '../interfaces/subcontractors-offer.interface';
import { SubcontractorsOffer } from '../models/subcontractors-offer.model';

import { format } from 'date-fns'


@Injectable({
  providedIn: 'root'
})


export class SubcontractorsOffersService {

  constructor(private http: HttpClient) { }
  
   
  updateSubcontractorsOffer(updateData: SubcontractorsOffer):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});
	
    body = body.set('id', updateData.id);
    body = body.set('groupName', updateData.groupName);
    body = body.set('subId', updateData.subId);


    if(updateData.offerAskDay==undefined || updateData.offerAskDay=='0000-00-00'){
      body = body.set('offerAskDay', '0000-00-00');
    }else{
      body = body.set('offerAskDay', format(updateData.offerAskDay, 'YYYY-MM-DD'));
    }
    
    if(updateData.offerDay==undefined || updateData.offerDay=='0000-00-00'){
      body = body.set('offerDay', '0000-00-00'  );
    }else{
      body = body.set('offerDay', format(updateData.offerDay, 'YYYY-MM-DD')   );
    }
    
    body = body.set('uniteNetPrice', updateData.uniteNetPrice);
    body = body.set('netAmount', updateData.netAmount);
    body = body.set('offerNote', updateData.offerNote);
    body = body.set('supOrSub', updateData.supOrSub);

    body = body.set('fileId', updateData.fileId);

    console.log('UPDATE OFF ', updateData);
    

    return this.http.put('/api/subcontractorsoffers', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addSubcontractorsOffer(addData: SubcontractorsOffer):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

      body = body.set('groupName', addData.groupName);
      body = body.set('subId', addData.subId);
      body = body.set('offerAskDay', format(addData.offerAskDay, 'YYYY-MM-DD'));
      body = body.set('offerDay', format(addData.offerDay, 'YYYY-MM-DD')   );
      body = body.set('uniteNetPrice', addData.uniteNetPrice);
      body = body.set('netAmount', addData.netAmount);
      body = body.set('offerNote', addData.offerNote);
      body = body.set('supOrSub', addData.supOrSub);
      body = body.set('fileId', addData.fileId);
      
      console.log('ADD OFF ', addData);
    return this.http.post('/api/subcontractorsoffers', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllSubcontractorsOffers(): Observable<ISubcontractorsOffer[]> {
    return this.http.get<ISubcontractorsOffer[]>('/api/subcontractorsoffers');
  }

  
  getSubcontractorsOffer(itemId: number): Observable<ISubcontractorsOffer> {
    return this.http.get<ISubcontractorsOffer>('/api/subcontractorsoffers/'+itemId);
  }


  
  delSubcontractorsOffer(itemId: number):Observable<any> {
    return this.http.delete('/api/subcontractorsoffers/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}