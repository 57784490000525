import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TenderStatus'
})
export class TenderStatusPipe implements PipeTransform {
  private tenderStatues: Array<any> = [];

  constructor(){
    this.tenderStatues['0']='W przygotowaniu';
    this.tenderStatues['1']='Złożony';

    this.tenderStatues['w']='Wygrany';
    this.tenderStatues['l']='Przegrany';
    this.tenderStatues['c']='Odwołana';
    this.tenderStatues['i']='Unieważniona';
  }

  transform(optionValue: any): string {
    return this.tenderStatues[optionValue];
  }

}
