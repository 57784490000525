export class Tender {
    constructor(

        public id: any = 0,
        public createdBy: any = 0,
        public tenderDate: Date = new Date,
        public offerToDate: Date = new Date,
        public investmentDeadline: string = '',
        public founderId: any = 1,
        public investmentDescription: string = '',
        public depositAmount: string = '20000',
        public note: string = '',
        public bidsNumber: any = 0,
        public yourOffer: string = '0',
        public winOffer: string = '0',
        public worksAmount: string = '0',
        public materialsAmount: string = '0',
        public status: string = '1',
        
        public priceRate: string ='70',
        public investmentType: string = 'c',
        public competitorsOffers: string = '0',
        public founderBudget: any = '0',


        public otherRates: string = '',
        public questionsDeadline: Date = new Date,
        public electronicOffer: string = '0',
        public bankInfo: string = '0',
        public tenderLink: string = '',


        public capitalGroupSent: string = '0', 
        public capitalGroupSentDate: Date = new Date, 
        public siwzTerms: string = '0', 
        public RatesNote: string = '', 
        public offerNumDays: string = '0',



        public competitorsOffersNote: string = '',
        public quotationType: string = 'o',
        public quotationNote: string = ''

    ){
    
        }
}
