import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})


export class AuthComponent implements OnInit {
  errorMessage: string;
  validateForm!: FormGroup;


  constructor( private authService: AuthService, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
              }

  loginForm(value: { userName: string; password: string; } ) {

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (value.userName && value.password) {
      const userName = value.userName;
      const password = value.password;
            
      this.authService.login(userName, password).subscribe( data => { 

        if(data['success']==true){
          this.authService.userData =data['data'];
          this.router.navigate(['/projects']);
        }else{
          alert('Błąd logowania');
        }
  
      },
      
        error => {
          console.log('Error: ', error)
          alert('Błąd logowania');
        }
      
      )

      // Navigate to the Product List page after log in.
      if (this.authService.redirectUrl) {
        this.router.navigateByUrl(this.authService.redirectUrl);
      } else {
        this.router.navigate(['/projects']);
      }
    } else {
      this.errorMessage = 'Please enter a user name and password.';
    }


  }
}
