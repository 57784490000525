import { Component, OnInit } from '@angular/core';
import { SettingsService }  from '../../../services/settings.service';

import { ContactSettings } from '../../../models/contactsettings.model';

import { NzMessageService } from 'ng-zorro-antd';

//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';



@Component({
  selector: 'app-show-settings',
  templateUrl: './show-settings.component.html',
  styleUrls: ['./show-settings.component.scss']
})


export class ShowSettingsComponent implements OnInit {

  private contactSettings = new ContactSettings();

  private contractTxt: any = '';

  //public Editor = ClassicEditor;


  public Editor = DecoupledEditor;

  public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
  }



  constructor(private SettingsService: SettingsService,  private MessageService: NzMessageService) { }

  
  getCompanyConfigData(){
    this.SettingsService.getConfigs().subscribe( contactData => {

      contactData['data'].forEach(function(contactItem){
          
          this.contactSettings[contactItem['confName']] = contactItem['confValue'];

        }, this
      );

    });
  }
  
  
  ngOnInit() {
    this.getCompanyConfigData();

    this.SettingsService.getDocumentTemplate('1').subscribe( contractData => {
      this.contractTxt=contractData["data"].docTpl;
    });

    
  
  }

  saveCompanySettings(){
    

    this.SettingsService.updateCompanyData(this.contactSettings).subscribe(data => {
      this.MessageService.success('Dane zostały zapisane');
      this.getCompanyConfigData();
      
    });
  }

  saveSubcontractorContract(){
        
    this.SettingsService.updateDocumentTemplate({id: '1', docTpl: this.contractTxt}).subscribe( data => {

      this.MessageService.success('Umowa została zapisana');

      this.SettingsService.getDocumentTemplate('1').subscribe( contractData => {
        this.contractTxt=contractData["data"].docTpl;
      });

    });
    
  
  }

}
