import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';

import { ITenderQuestion } from '../interfaces/tender-question.interface';
import { TenderQuestion } from '../models/tender-question.model';


import { ITenderQuestionAnswer } from '../interfaces/tender-question-answer.interface';
import { TenderQuestionAnswer } from '../models/tender-question-answer.model';




@Injectable({
  providedIn: 'root'
})
export class TenderQuestionsService {

  constructor(private http: HttpClient) { }
  
   
  updateTenderQuestion(updateData: TenderQuestion):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);

    body = body.set('tenderId', updateData.tenderId);
    body = body.set('tenderQuestionTitle', updateData.tenderQuestionTitle);
    body = body.set('tenderQuestion', updateData.tenderQuestion);
    body = body.set('sentDate', updateData.sentDate.getFullYear()+'-'+(updateData.sentDate.getMonth()+1)+'-'+updateData.sentDate.getDate() );


    return this.http.put('/api/tenderquestions', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addTenderQuestion(addData: TenderQuestion):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	
  
//    body = body.set('id', addData.id);
    body = body.set('tenderId', addData.tenderId);
    body = body.set('tenderQuestionTitle', addData.tenderQuestionTitle);
    body = body.set('tenderQuestion', addData.tenderQuestion);
    body = body.set('sentDate', addData.sentDate.getFullYear()+'-'+(addData.sentDate.getMonth()+1)+'-'+addData.sentDate.getDate() );

    body = body.set('addedFiles', JSON.stringify(addData.addedFiles) );
    

    return this.http.post('/api/tenderquestions', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  getAllTenderQuestionAnswers(questionId: any): Observable<ITenderQuestionAnswer[]> {
    return this.http.get<ITenderQuestionAnswer[]>('/api/tenderquestions/answers/'+questionId);
  }
  
  getAllTenderQuestions(tenderId: any): Observable<ITenderQuestion[]> {
    return this.http.get<ITenderQuestion[]>('/api/tenderquestions/all/'+tenderId);
  }

  
  getTenderQuestion(itemId: number): Observable<ITenderQuestion> {
    return this.http.get<ITenderQuestion>('/api/tenderquestions/'+itemId);
  }


  
  delTenderQuestion(itemId: number):Observable<any> {
    return this.http.delete('/api/tenderquestions/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }

  delTenderQuestionAnswer(itemId: number):Observable<any> {
    return this.http.delete('/api/tenderquestions/delete/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }

  addTenderQuestionAnswer(addData: TenderQuestionAnswer):Observable<any>{

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});


    body = body.set('id', addData.id);
    body = body.set('tenderQuestionId', addData.tenderQuestionId);
    body = body.set('tenderQuestionResponse', addData.tenderQuestionResponse);
    body = body.set('responseDate', addData.responseDate.getFullYear()+'-'+(addData.responseDate.getMonth()+1)+'-'+addData.responseDate.getDate());
    body = body.set('addedFiles', addData.addedFiles);
    
    

    return this.http.post('/api/tenderquestions/answer', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }



  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}