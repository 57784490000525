import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { IProjectSubcontractorProtocol } from '../interfaces/project-subcontractor-protocol.interface';
import { ProjectSubcontractorProtocol } from '../models/project-subcontractor-protocol.model';


@Injectable({
  providedIn: 'root'
})


export class ProjectSubcontractorProtocolsService {

  constructor(private http: HttpClient) { }
  
   
  updateProjectSubcontractorProtocol(updateData: ProjectSubcontractorProtocol):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});
/*
	body = body.set('id', updateData.id);
	body = body.set('projectId', updateData.projectId);
	body = body.set('subId', updateData.subId);
	body = body.set('projectFounder', updateData.projectFounder);
	body = body.set('acceptanceDate', updateData.acceptanceDate);
	body = body.set('protocolDateFrom', updateData.protocolDateFrom);
	body = body.set('protocolDateTo', updateData.protocolDateTo);
	body = body.set('projectName', updateData.projectName);
	body = body.set('protocolValue', updateData.protocolValue);
	body = body.set('protocolNumber', updateData.protocolNumber);
	body = body.set('protocolType', updateData.protocolType);
	body = body.set('isOfficial', updateData.isOfficial);
	*/

    return this.http.put('/api/projectsubcontractorprotocols', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addProjectSubcontractorProtocol(addData: ProjectSubcontractorProtocol):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    /*
      body = body.set('id', addData.id);
      body = body.set('projectId', addData.projectId);
      body = body.set('subId', addData.subId);
      body = body.set('projectFounder', addData.projectFounder);
      body = body.set('acceptanceDate', addData.acceptanceDate);
      body = body.set('protocolDateFrom', addData.protocolDateFrom);
      body = body.set('protocolDateTo', addData.protocolDateTo);
      body = body.set('projectName', addData.projectName);
      body = body.set('protocolValue', addData.protocolValue);
      body = body.set('protocolNumber', addData.protocolNumber);
      body = body.set('protocolType', addData.protocolType);
      body = body.set('isOfficial', addData.isOfficial);
     */ 
      Object.keys(addData).forEach( keyName => {
        body = body.set(keyName, addData[keyName]);
      });


    return this.http.post('/api/projectsubcontractorprotocols', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllProjectSubcontractorProtocols(): Observable<IProjectSubcontractorProtocol[]> {
    return this.http.get<IProjectSubcontractorProtocol[]>('/api/projectsubcontractorprotocols');
  }

  
  getProjectSubcontractorProtocol(itemId: number): Observable<IProjectSubcontractorProtocol> {
    return this.http.get<IProjectSubcontractorProtocol>('/api/projectsubcontractorprotocols/'+itemId);
  }


  
  delProjectSubcontractorProtocol(itemId: number):Observable<any> {
    return this.http.delete('/api/projectsubcontractorprotocols/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}