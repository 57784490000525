export class File{

    constructor(

        public id: any='',
        public dirId:       string='',
        public projectId:   string='',
        public fileName:    string='',
        public fileDescription:    string='',
        public fileFile:    string='',
        public fileType:    string='',
        public fileUniqId:  string='',
        public fileSize:    any='',
        public addedFiles?: any

    ){

    }
}


