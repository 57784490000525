import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ISubcontractorContact } from '../interfaces/subcontractor-contact.interface';
import { SubcontractorContact } from '../models/subcontractor-contact.model';



@Injectable({
  providedIn: 'root'
})


export class SubcontractorContactsService {

  constructor(private http: HttpClient) { }
  
   
  updateSubcontractorContact(updateData: SubcontractorContact):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	

    return this.http.put('/api/subcontractorcontacts', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addSubcontractorContact(addData: SubcontractorContact):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	

    return this.http.post('/api/subcontractorcontacts', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllSubcontractorContacts(userId: any): Observable<ISubcontractorContact[]> {
    return this.http.get<ISubcontractorContact[]>('/api/subcontractorcontacts/'+userId);
  }

  
  getSubcontractorContact(itemId: number): Observable<ISubcontractorContact> {
    return this.http.get<ISubcontractorContact>('/api/subcontractorcontacts/'+itemId);
  }


  
  delSubcontractorContact(itemId: number):Observable<any> {
    return this.http.delete('/api/subcontractorcontacts/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}
