import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { SubcontractorsOffer } from '../../models/subcontractors-offer.model';
import { ISubcontractorsOffer } from   '../../interfaces/subcontractors-offer.interface';
import { SubcontractorsOffersService } from '../../services/subcontractors-offers.service';

import { SubcontractorsService } from '../../services/subcontractors.service';
import { ISubcontractor } from   '../../interfaces/subcontractor.interface';

import { ISupplier } from   '../../interfaces/supplier.interface';
import { SuppliersService } from '../../services/suppliers.service';

import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';


@Component({
  selector: 'app-subcontractorsoffer',
  templateUrl: './subcontractorsoffer.component.html',
  styleUrls: ['./subcontractorsoffer.component.scss']
})

export class SubcontractorsofferComponent implements OnInit {

  

  private allSuppliers:Array<ISupplier> = [];
  private allSubcontractors:Array<ISubcontractor> = [];
  private allSubSup:Array<any> = [];


  private allSubcontractorsOffers:Array<ISubcontractorsOffer> = [];

  private subcontractorsOfferModel = new SubcontractorsOffer();


  private showSubcontractorsOfferDrawer:boolean = false;
  private showAddEditSubcontractorsOfferDrawer:boolean = false;

  private activateSubcontractorsOfferDrawerSceleton:boolean = false;
  private activateAddEditSubcontractorsOfferDrawerSceleton:boolean = false;


  private fileList = [];

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {

        const filterFiles = fileList.filter(w => ~['application/pdf'].indexOf(w.type));
        
        if (filterFiles.length !== fileList.length) {
          this.MessageService.error(`To nie jest poprawny plik PDF`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: UploadFile[]) => {
        return new Observable((observer: Observer<UploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];  

  constructor(  private SubcontractorsOffersService: SubcontractorsOffersService, 
                private MessageService: NzMessageService,
                private SubcontractorsService: SubcontractorsService,
                private SuppliersService: SuppliersService,
                ) {}

  ngOnInit() {
    this.SubcontractorsOffersService.getAllSubcontractorsOffers().subscribe( data => { 
      this.allSubcontractorsOffers = data['data']; 
    });

    this.SubcontractorsService.getAllSubcontractors().subscribe( data => {
      this.allSubSup=this.allSubcontractors=data['data'];
    
    });
    
    this.SuppliersService.getAllSuppliers().subscribe( data => this.allSuppliers=data['data'] );

  }


  handleChange(info: any): void {

   

    const fileList = info.fileList;
    // 2. read from response and show file link
    
    if (info.file.response) {
      info.file.url = info.file.response.url;
      fileList.fileId=info.file.response.id;
    }
    // 3. filter successfully uploaded files according to response from server
    // tslint:disable-next-line:no-any

    this.fileList = fileList.filter((item: any) => {
      if(item.response){
        return item.response.success === true;
      }
      return true;
    });  

  }




  changeTypeSelect(layoutId: any){
    if(layoutId==='0'){  
      this.allSubSup=this.allSubcontractors;
    }else{
      this.allSubSup=this.allSuppliers;
    }
  }



  openAddEditSubcontractorsOfferDrawer(itemId?: any){
  

    if(itemId){
	    this.activateAddEditSubcontractorsOfferDrawerSceleton = true;
		  this.showAddEditSubcontractorsOfferDrawer = true;
      this.fileList = [];
      
      this.SubcontractorsOffersService.getSubcontractorsOffer(itemId).subscribe(userData => {
          
          this.subcontractorsOfferModel = new SubcontractorsOffer(
            userData['data'].id, 
            userData['data'].groupName, 
            userData['data'].subId, 
            userData['data'].offerAskDay,
            userData['data'].offerDay, 
            userData['data'].uniteNetPrice, 
            userData['data'].netAmount,
            userData['data'].offerNote,
            userData['data'].supOrSub
            );
          

          if(this.subcontractorsOfferModel.supOrSub=='0'){
            this.allSubSup=this.allSubcontractors;
          }else{
            this.allSubSup=this.allSuppliers;
          }
          
          if(this.subcontractorsOfferModel.offerDay=='0000-00-00'){
            this.subcontractorsOfferModel.offerDay=undefined;
          }

          if(this.subcontractorsOfferModel.offerAskDay=='0000-00-00'){
            this.subcontractorsOfferModel.offerAskDay=undefined;
          }

          this.activateAddEditSubcontractorsOfferDrawerSceleton=false;
        });
    
        
    }else{

      this.fileList = [];
      this.allSubSup=this.allSubcontractors;	  
      this.showAddEditSubcontractorsOfferDrawer = true;
      this.activateAddEditSubcontractorsOfferDrawerSceleton = false;
      this.subcontractorsOfferModel = new SubcontractorsOffer();

    } 
  
  
  
  }


  openSubcontractorsOfferDrawer(itemId?: any): void{
 

    if(itemId){

	    this.showSubcontractorsOfferDrawer = true;
		this.activateSubcontractorsOfferDrawerSceleton = true;

		
		this.SubcontractorsOffersService.getSubcontractorsOffer(itemId).subscribe(userData => {
		
			this.subcontractorsOfferModel = new SubcontractorsOffer(userData['data'].userLogin, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id);
		
			this.activateSubcontractorsOfferDrawerSceleton=false;
		
		});
    
    }else{
	  
		this.MessageService.error('ERROR');
	  
    }
 
 
  }

  closeSubcontractorsOfferDrawer(m?: any):void{
	  
    this.showSubcontractorsOfferDrawer = false;	  

  }
	  

  closeAddEditSubcontractorsOfferDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditSubcontractorsOfferDrawer = false;
   
  }


  submitAddEditSubcontractorsOfferForm(formName: NgForm){

    if(this.fileList.length>0){
      let fileTmp=this.fileList.slice(-1).pop();
      this.subcontractorsOfferModel.fileId=fileTmp.response.data.fileId;
      this.fileList=[];
    }

    if(+this.subcontractorsOfferModel.id>0){

      this.SubcontractorsOffersService.updateSubcontractorsOffer(this.subcontractorsOfferModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditSubcontractorsOfferDrawer = false;
        this.MessageService.success('Oferta zostałą zaktualizowana');
        
        this.SubcontractorsOffersService.getAllSubcontractorsOffers().subscribe( data => {
          this.allSubcontractorsOffers = data['data'];
        
        });
      
		},
		error => console.log('Error: ', error)
      );


    }else{

        this.SubcontractorsOffersService.addSubcontractorsOffer(this.subcontractorsOfferModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditSubcontractorsOfferDrawer = false;
            
            this.MessageService.success('Oferta została dodana');

            this.SubcontractorsOffersService.getAllSubcontractorsOffers().subscribe( data => {
              this.allSubcontractorsOffers = data['data'];
            
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmSubcontractorsOfferDelete(recordId: any){

    this.SubcontractorsOffersService.delSubcontractorsOffer(recordId).subscribe( data => {
	  this.MessageService.success('RECORD został usunięty');
      this.SubcontractorsOffersService.getAllSubcontractorsOffers().subscribe( data => {
        this.allSubcontractorsOffers = data['data'];
        
      });        
    });
  
  }





}