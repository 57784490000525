import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ITenderFounder } from '../interfaces/tender-founder.interface';
import { TenderFounder } from '../models/tender-founder.model';



@Injectable({
  providedIn: 'root'
})


export class TenderFoundersService {

  constructor(private http: HttpClient) { }
  
   
  updateTenderFounder(updateData: TenderFounder):Observable<any>{

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('founderName', updateData.founderName);
    

    return this.http.put('/api/tenderfounders', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addTenderFounder(addData: TenderFounder):Observable<any>{

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', addData.id);
    body = body.set('founderName', addData.founderName);
    

    return this.http.post('/api/tenderfounders', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllTenderFounders(): Observable<ITenderFounder[]> {
    return this.http.get<ITenderFounder[]>('/api/tenderfounders');
  }

  
  getTenderFounder(itemId: number): Observable<ITenderFounder> {
    return this.http.get<ITenderFounder>('/api/tenderfounders/'+itemId);
  }


  
  delTenderFounder(itemId: number):Observable<any> {
    return this.http.delete('/api/tenderfounders/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}