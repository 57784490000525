
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { Tool } from '../../models/tool.model';
import { ITool } from   '../../interfaces/tool.interface';
import { ToolsService } from '../../services/tools.service';

import { IToolGroup } from   '../../interfaces/tool-group.interface';
import { ToolGroupsService } from '../../services/tool-groups.service';
import { forEach } from '@angular/router/src/utils/collection';


@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})

export class ToolsComponent implements OnInit {
	
  private allTools:Array<ITool> = [];
  private _allToolsFiltered:Array<ITool> = [];
  private allToolGroups:Array<IToolGroup> = [];

  private toolModel = new Tool();


  private showToolDrawer:boolean = false;
  private showAddEditToolDrawer:boolean = false;

  private activateToolDrawerSceleton:boolean = false;
  private activateAddEditToolDrawerSceleton:boolean = false;


  private _serachWord:any='';
  private _searchStatus:any=null;
  private _searchGroup:any=null;




  constructor(private ToolGroupsService: ToolGroupsService, private ToolsService: ToolsService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.ToolsService.getAllTools().subscribe( data => { 
      this.allTools = data['data'];
   
      this.ToolGroupsService.getAllToolGroups().subscribe( data => { 
        this.allToolGroups = data['data']; 
        this.id2Words();
      });

    });



  }

  formatterGuarantee = (value: number) => { 
    if(value===1){
      return `${value} rok`;
    }else if(value===0){
      return `${value}`;
    }else if(value>4){
      return `${value} lat`;
    }else{
      return `${value} lata`;
    }  
  };
  parserGuarantee = (value: string) => value.replace(' rok', '').replace(' lata', '').replace(' lat', '');
 
  filterItems(tool:ITool){
    let _toolNameCondition:boolean=false;
    let _toolGroupCondition:boolean=false;
    let _toolStatusCondition:boolean=false;

    if(this._serachWord===''){
      _toolNameCondition=true;
    }else if(tool.toolName.toLowerCase().includes(this._serachWord.toLowerCase()) || tool.toolNumber.toLowerCase().includes(this._serachWord.toLowerCase())){
      _toolNameCondition=true;
    }else{
      _toolNameCondition=false;
    }

    if(this._searchGroup===null){
      _toolGroupCondition=true;
    }else if(tool.did==this._searchGroup){
      _toolGroupCondition=true;
    }else{
      _toolGroupCondition=false;
    }

    if(this._searchStatus===null){
      _toolStatusCondition=true;
    }else if(tool.state==this._searchStatus){
      _toolStatusCondition=true;
    }else{
      _toolStatusCondition=false;
    }  





    return  (_toolNameCondition && _toolGroupCondition && _toolStatusCondition);
  
  }

  searchSubcontractorsClear(){
    this._serachWord='';
    this._searchStatus=null;
    this._searchGroup=null;

    this._allToolsFiltered=this.allTools.filter(tool=>this.filterItems(tool));
  }

  searchSubcontractors(){
    this._allToolsFiltered=this.allTools.filter(tool=>this.filterItems(tool));
  }

  _stateToWord(stateId: any){
    let toolState=Array();

    toolState[0]="Na stanie";
    toolState[1]="Skradziona";
    toolState[3]="Zepsuta";
    toolState[4]="W naprawie";

    return toolState[stateId];
  }

  id2Words(){
    let groupData=Array();
    let numTools=this.allTools.length;
    let numGroups=this.allToolGroups.length;

  
    for(let i=0; i<numGroups; i++){
      groupData[this.allToolGroups[i]['id']]=this.allToolGroups[i]['groupName'];
    }

    for(let i=0; i<numTools; i++){
      this.allTools[i]['stateName']=this._stateToWord(this.allTools[i]['state']);
      this.allTools[i]['toolGuaranteeName']=this.formatterGuarantee(this.allTools[i]['toolGuarantee']);
      this.allTools[i]['groupName']=groupData[this.allTools[i]['did']];
    }

    this._allToolsFiltered=this.allTools.filter(tool=>this.filterItems(tool));
  }


  openAddEditToolDrawer(itemId?: any){
  

    if(itemId){
	    this.activateAddEditToolDrawerSceleton = true;
		  this.showAddEditToolDrawer = true;

      
      this.ToolsService.getTool(itemId).subscribe(toolData => {
          
        toolData=toolData['data'];
          
        this.toolModel = new Tool();
        this.toolModel['id']=toolData['id'];
        this.toolModel['did']=toolData['did'];
        this.toolModel['toolName']=toolData['toolName'];
        this.toolModel['toolNumber']=toolData['toolNumber'];
        
        
        
        if(toolData['purchaseDate']!='0000-00-00'){
          this.toolModel['purchaseDate']=new Date(toolData['purchaseDate']);
        }else{
          this.toolModel['purchaseDate']=null;
        }
     
        
        this.toolModel['toolGuarantee']=toolData['toolGuarantee'];
        this.toolModel['toolNote']=toolData['toolNote'];
        this.toolModel['toolLocalisation']=toolData['toolLocalisation'];
        this.toolModel['toolGetBy']=toolData['toolGetBy'];
        this.toolModel['toolUseOn']=toolData['toolUseOn'];
        this.toolModel['state']=toolData['state'];
        

        this.activateAddEditToolDrawerSceleton=false;
        
      });
    
        
    }else{
	  
      this.showAddEditToolDrawer = true;
      
      this.activateAddEditToolDrawerSceleton = false;
      this.toolModel = new Tool();
      this.toolModel['did']='1';
      this.toolModel['toolGuarantee']='2';

    } 
  
  
  
  }


  openToolDrawer(itemId?: any): void{
 

    if(itemId){

      let groupData=Array();
      let numGroups=this.allToolGroups.length;

	    this.showToolDrawer = true;
		  this.activateToolDrawerSceleton = true;

      for(let i=0; i<numGroups; i++){
        groupData[this.allToolGroups[i]['id']]=this.allToolGroups[i]['groupName'];
      }      

		  this.ToolsService.getTool(itemId).subscribe(toolData => {

        toolData=toolData['data'];

        this.toolModel = new Tool();
        this.toolModel['id']=toolData['id'];
        this.toolModel['did']=toolData['did'];
        this.toolModel['toolName']=toolData['toolName'];
        this.toolModel['toolNumber']=toolData['toolNumber'];
        this.toolModel['purchaseDate']=toolData['purchaseDate'];
        this.toolModel['toolGuarantee']=toolData['toolGuarantee'];
        this.toolModel['toolNote']=toolData['toolNote'];
        this.toolModel['toolLocalisation']=toolData['toolLocalisation'];
        this.toolModel['toolGetBy']=toolData['toolGetBy'];
        this.toolModel['toolUseOn']=toolData['toolUseOn'];
        this.toolModel['state']=toolData['state'];


        this.toolModel['toolGuaranteeName']=this.formatterGuarantee(toolData['toolGuarantee']);



        this.toolModel['stateName']=this._stateToWord(toolData['state']);
        this.toolModel['groupName']=groupData[toolData['did']];



        this.activateToolDrawerSceleton=false;
		
		  });
    
    }else{
	  
		  this.MessageService.error('ERROR');
	  
    }
 
 
  }

  closeToolDrawer(m?: any):void{
	  
    this.showToolDrawer = false;	  

  }
	  

  closeAddEditToolDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditToolDrawer = false;
   
  }


  submitAddEditToolForm(formName: NgForm){


    if(+this.toolModel.id>0){


      this.ToolsService.updateTool(this.toolModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditToolDrawer = false;
        this.MessageService.success('Dane zostały zmienione');
        
        this.ToolsService.getAllTools().subscribe( data => {
          this.allTools = data['data'];
          this.id2Words();
        });
      
		},
		error => console.log('Error: ', error)
      );


    }else{

        this.ToolsService.addTool(this.toolModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditToolDrawer = false;
            
            this.MessageService.success('Narzędzie zostało dodane');

            this.ToolsService.getAllTools().subscribe( data => {
              this.allTools = data['data'];
              this.id2Words();
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmToolDelete(recordId: any){

    this.ToolsService.delTool(recordId).subscribe( data => {
    
      if(data['success']==false){
        this.MessageService.error(data['error'], {nzDuration: 10000});

      }else{
        this.MessageService.success('Narzędzie zostało usunięte');
        this.ToolsService.getAllTools().subscribe( data => {
          this.allTools = data['data'];
          this.id2Words();
        });
      }        
    }
    );
  
  }

}