import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //HttpErrorResponse

import { Observable} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { IUser } from '../interfaces/user.interface';
import { User } from '../models/user.model';

//{ providedIn: 'root'}

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  getAllUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>('/api/users');
  }


  getAllUsersManagers(): Observable<IUser[]> {
    return this.http.get<IUser[]>('/api/users/managers');
  }
  


  getUser(userId: number): Observable<IUser[]> {
    return this.http.get<IUser[]>('/api/users/get/'+userId);
  }

  addUser(userData: User):Observable<any>{

    let body = new HttpParams();

    body = body.set('userLogin', userData.userLogin);   
    body = body.set('userName', userData.userName);
    body = body.set('userType', userData.userType);
    body = body.set('userPass', userData.userPass); 
    body = body.set('userPhone', userData.userPhone); 
    body = body.set('userEmail', userData.userEmail); 

    
    return this.http.post('/api/users', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );
  
  }

  delUser(userId: string):Observable<any>{
    
    return this.http.delete('/api/users/del/'+userId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 
  }

  ifUserExist(userData: User):Observable<any>{
    let body = new HttpParams();

    body = body.set('userLogin', userData.userLogin); 

    return this.http.post('/api/users/userexist', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );  
  }

  saveUser(userData: User):Observable<any>{

    let body = new HttpParams();

    body = body.set('userName', userData.userName);
    body = body.set('userPass', userData.userPass); 
    body = body.set('userPhone', userData.userPhone); 
    body = body.set('userEmail', userData.userEmail);   
    body = body.set('userId',   userData.userId); 

    return this.http.put('/api/users', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 
  }

  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }

}