import { Component } from '@angular/core';

import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';

import { AuthService } from './services/auth.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ncsprobud';


  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  // get isMessageDisplayed(): boolean {
  //   //return this.messageService.isDisplayed;
  // }

  get userName(): string {
    if (this.authService.currentUser) {
      return this.authService.currentUser.userName;
    }
    return '';
  }

  constructor(private authService: AuthService,
              private router: Router) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    // if (routerEvent instanceof NavigationStart) {
    //   this.loading = true;
    // }

    // if (routerEvent instanceof NavigationEnd ||
    //     routerEvent instanceof NavigationCancel ||
    //     routerEvent instanceof NavigationError) {
    //   this.loading = false;
    // }
  }

  displayMessages(): void {
    this.router.navigate([{ outlets: { popup: ['messages'] } }]);
    //this.messageService.isDisplayed = true;
  }

  hideMessages(): void {
    this.router.navigate([{ outlets: { popup: null } }]);
    //this.messageService.isDisplayed = false;
  }

  logOut(): void {
    this.authService.logout().subscribe( data => {
      this.router.navigateByUrl('/login');
    });
    
  }



}