import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //HttpErrorResponse
import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IProject } from '../interfaces/project.interface';

import { IFile } from '../interfaces/file.interface';
import { File } from '../models/file.model';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


@Injectable({
  providedIn: 'root'
})


export class FilesService {

  constructor(private http: HttpClient) { }


  getProjectFiles(projectId: string): Observable<IProject[]> {
    return this.http.get<IProject[]>('/api/files/get/'+projectId);
  }


  getDirectories(): Observable<any[]> {
    return this.http.get<any[]>('/api/files/directories');
  }

  
  addFile(addData: File):Observable<any>{

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('dirId', addData.dirId);
    body = body.set('projectId', addData.projectId);
    body = body.set('fileName', addData.fileName);
    body = body.set('fileDescription', addData.fileDescription);
    body = body.set('addedFiles', addData.addedFiles);


    return this.http.post('/api/files', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }  



  addNewDirectory(dirName: string):Observable<any>{

    let body = new FormData();
  
    body.set('dirName', dirName);   
    
    return this.http.post('/api/files/directories', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );
  }

  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }
}