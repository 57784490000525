import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TenderInvestmentType'
})
export class TenderInvestmentTypePipe implements PipeTransform {
  private tenderInvType: Array<any> = [];

  constructor(){
    this.tenderInvType['c']='Budowa';
    this.tenderInvType['r']='Remont';
    this.tenderInvType['re']='Przebudowa';
  }

  transform(value: any): any {
    return this.tenderInvType[value];
  }

}


