import { Component, OnInit, Input } from '@angular/core';

import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { TenderQuestion } from '../../../models/tender-question.model';
import { ITenderQuestion } from   '../../../interfaces/tender-question.interface';
import { TenderQuestionsService } from '../../../services/tender-questions.service';

import { TenderQuestionAnswer } from '../../../models/tender-question-answer.model';
import { ITenderQuestionAnswer } from   '../../../interfaces/tender-question-answer.interface';


@Component({
  selector: 'app-tender-questions',
  templateUrl: './tender-questions.component.html',
  styleUrls: ['./tender-questions.component.sass']
})


export class TenderQuestionsComponent implements OnInit {
  @Input() tenderId: string = '0';
  
  private fileListFiles: any[]=[];

  private allTenderQuestions:Array<ITenderQuestion> = [];
  private allTenderQuestionAnswers:Array<ITenderQuestionAnswer> = [];



  private tenderQuestionModel = new TenderQuestion();
  private tenderQuestionAnswerModel = new TenderQuestionAnswer();

  private showTenderQuestionDrawer:boolean = false;
  private showAddEditTenderQuestionDrawer:boolean = false;

  private showAddEditTenderQuestionAnswerDrawer:boolean = false;  

  private activateTenderQuestionDrawerSceleton:boolean = false;
  private activateAddEditTenderQuestionDrawerSceleton:boolean = false;
  private activateAddEditTenderQuestionAnswerDrawerSceleton:boolean = false;



  constructor(private TenderQuestionsService: TenderQuestionsService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this._getAllTenderQuestions();
  }

  ngOnChanges(changes) {
    this._getAllTenderQuestions();
  }


  _getAllTenderQuestions(){
    if(+this.tenderId>0){
      this.TenderQuestionsService.getAllTenderQuestions(this.tenderId).subscribe( data => { 
        this.allTenderQuestions = data['data']; 
      });
    }
  }


  
  handleUploadFileChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      this.MessageService.success(`${file.name} został dodany.`);
    } else if (status === 'error') {

      this.MessageService.error(`${file.name} nie został dodany.`);
    }
  }


  openAddEditTenderQuestionAnswerDrawer(itemId?: any){

    this.showAddEditTenderQuestionAnswerDrawer = true;	   
	  this.activateAddEditTenderQuestionAnswerDrawerSceleton = false;

    this.tenderQuestionAnswerModel = new TenderQuestionAnswer();
    this.tenderQuestionAnswerModel.tenderQuestionId=itemId;

  }


  openAddEditTenderQuestionDrawer(itemId?: any){
  
    this.tenderQuestionModel = new TenderQuestion();

    this.fileListFiles=[];

    if(+itemId){

      this.activateAddEditTenderQuestionDrawerSceleton = true;
      
      this.showAddEditTenderQuestionDrawer = true;
      
      this.TenderQuestionsService.getTenderQuestion(itemId).subscribe(tenderData => {

        tenderData=tenderData['data'];

        this.tenderQuestionModel.id=tenderData.id;
        this.tenderQuestionModel.tenderId=tenderData.tenderId;
        this.tenderQuestionModel.tenderQuestionTitle=tenderData.tenderQuestionTitle;
        this.tenderQuestionModel.tenderQuestion=tenderData.tenderQuestion;
        this.tenderQuestionModel.sentDate=new Date(tenderData.sentDate);

        this.tenderQuestionModel.addedFiles=[];

        this.activateAddEditTenderQuestionDrawerSceleton=false;        
      });
    
        
    }else{

      this.showAddEditTenderQuestionDrawer = true;
      
      this.activateAddEditTenderQuestionDrawerSceleton = false;

      this.tenderQuestionModel.tenderQuestionTitle='';
      this.tenderQuestionModel.tenderId=this.tenderId;

      this.tenderQuestionModel.addedFiles=[];

    } 
  
  
  
  }


  openTenderQuestionDrawer(itemId?: any): void{


    if(+itemId){

	    this.showTenderQuestionDrawer = true;
		  this.activateTenderQuestionDrawerSceleton = true;
      
      this.TenderQuestionsService.getTenderQuestion(itemId).subscribe(questionData => {
      
        this.tenderQuestionModel = new TenderQuestion();

        this.tenderQuestionModel.id=questionData['data'].id;
        this.tenderQuestionModel.tenderQuestion=questionData['data'].tenderQuestion;
        this.tenderQuestionModel.tenderQuestionTitle= questionData['data'].tenderQuestionTitle;
        this.tenderQuestionModel.sentDate=questionData['data'].sentDate;

        this.tenderQuestionModel.addedFiles=JSON.parse(questionData['data'].attFiles);
        
        this.TenderQuestionsService.getAllTenderQuestionAnswers(this.tenderQuestionModel.id).subscribe(answerData =>{
          this.allTenderQuestionAnswers=answerData['data'];
        });

        this.activateTenderQuestionDrawerSceleton=false;
      });
    


    }else{
	  
  		this.MessageService.error('ERROR');
	  
    } 
 
  }




  closeTenderQuestionDrawer(m?: any):void{
    this.showTenderQuestionDrawer = false;
  }
  

  closeAddEditTenderQuestionAnswerDrawer(formName: NgForm): void{
    formName.form.markAsUntouched();
    this.fileListFiles=[];    
    this.showAddEditTenderQuestionAnswerDrawer = false;
  }
	  

  closeAddEditTenderQuestionDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
    this.fileListFiles=[];
  	//this.userExistError=false;
    this.showAddEditTenderQuestionDrawer = false;
   
  }


  submitAddEditTenderQuestionForm(formName: NgForm){

    if(+this.tenderQuestionModel.id>0){

      this.TenderQuestionsService.updateTenderQuestion(this.tenderQuestionModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditTenderQuestionDrawer = false;
        this.MessageService.success('Pytanie zostało zapisane');
        
        this._getAllTenderQuestions();
      },
        error => console.log('Error: ', error)
      );


    }else{


      this.tenderQuestionModel.addedFiles=this.fileListFiles;

      this.TenderQuestionsService.addTenderQuestion(this.tenderQuestionModel).subscribe( userData => { 
          
          formName.form.markAsUntouched(); 
          this.fileListFiles=[];

          this.showAddEditTenderQuestionDrawer = false;
          
          this.MessageService.success('Pytanie zostało dodane');

          this._getAllTenderQuestions();
        
          },
            error => console.log('Error: ', error)
      );
      
    }

  }


  
  

  submitAddEditTenderQuestionAnswerForm(formName: NgForm){


    if(+this.tenderQuestionAnswerModel.id>0){


      // this.TenderQuestionsService.updateTenderQuestionAnswer(this.tenderQuestionAnswerModel).subscribe( userData => { 

      //   formName.form.markAsUntouched(); 

      //   this.showAddEditTenderQuestionAnswerDrawer = false;
      //   this.MessageService.success('SAVED');
        
      //   this.TenderQuestionsService.getAllTenderQuestionAnswers().subscribe( data => {
      //     this.allTenderQuestionAnswers = data['data'];
        
      //   });
      
		// },
		// error => console.log('Error: ', error)
    //   );


    }else{
        
        this.tenderQuestionAnswerModel.addedFiles=JSON.stringify(this.fileListFiles);
        this.TenderQuestionsService.addTenderQuestionAnswer(this.tenderQuestionAnswerModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditTenderQuestionAnswerDrawer = false;
            this.fileListFiles=[];

            this.MessageService.success('Odpowiedź została dodana.');

            this.TenderQuestionsService.getAllTenderQuestionAnswers(this.tenderQuestionModel.id).subscribe( data => {
              this.allTenderQuestionAnswers = data['data'];
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }



  confirmTenderQuestionDelete(recordId: any){

    this.TenderQuestionsService.delTenderQuestion(recordId).subscribe( data => {
	    this.MessageService.success('Pytanie zostało usunięte');
      this._getAllTenderQuestions();
    });
  
  }



  confirmTenderQuestionAnswerDelete(recordId: any){

    this.TenderQuestionsService.delTenderQuestionAnswer(recordId).subscribe( data => {
	  this.MessageService.success('Odpowiedź została usunięta');
      this.TenderQuestionsService.getAllTenderQuestionAnswers(this.tenderQuestionModel.id).subscribe( data => {
        this.allTenderQuestionAnswers = data['data'];
        
      });        
    });
  
  }




}