import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http'; //HttpErrorResponse
import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IProject } from '../interfaces/project.interface';
import { ProjectItem } from '../models/projectitem.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectItemsService {

  constructor(private http: HttpClient) { }


  addProjectNormaFile(normaFileId: any, projectId: any, mainDirId: any):Observable<any>{
    let body = new FormData();
  
    body.set('normaFileId', normaFileId); 
    body.set('projectId', projectId); 
    body.set('mainDirId', mainDirId); 

    return this.http.post('/api/projectitems/norma', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );

  }


  saveSimpleProjectItem(updateProject: any): Observable<any[]>{
    
    let body = new HttpParams();
//console.log('SER ',updateProject);
    body = body.set('itemId', updateProject['id']);   
    body = body.set('doBy', updateProject['doBy']);

    
    return this.http.put('/api/projectitems/simple', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );   
  }



  saveProjectItemDone(updateProject: any): Observable<any[]>{
    
    let body = new HttpParams();

    body = body.set('itemId', updateProject['id']);
    body = body.set('projectId', updateProject['projectId']);
    body = body.set('planStatus', updateProject['planStatus']);

    body = body.set('toDo', updateProject['toDo']);

    body = body.set('toDoDone', updateProject['toDoDone']);

    body = body.set('toDoUnit', updateProject['toDoUnit']);
    body = body.set('workAmountTotal', updateProject['workAmountTotal']);

    body = body.set('itemAmountTotal', updateProject['itemAmountTotal']);

    body = body.set('groupId', updateProject['groupId']);
    body = body.set('isGroup', updateProject['isGroup']);

    body = body.set('taskStartDate', updateProject.taskStartDate.getFullYear()+'-'+(updateProject.taskStartDate.getMonth()+1)+'-'+updateProject.taskStartDate.getDate());
    body = body.set('taskEndDate', updateProject.taskEndDate.getFullYear()+'-'+(updateProject.taskEndDate.getMonth()+1)+'-'+updateProject.taskEndDate.getDate());
    body = body.set('taskName', updateProject['taskName']);
    body = body.set('basicEdit', updateProject['basicEdit']);
    body = body.set('doBy', updateProject['doBy']);
    body = body.set('setToAll', updateProject['setToAll']?'1':'0');

    return this.http.put('/api/projectitems', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );   
  }

  getProjectItems(projectId: any): Observable<IProject[]> {
    return this.http.get<IProject[]>('/api/projectitems/get/'+projectId);
  }


  getProjectGroups(projectId: any): Observable<any[]> {
    return this.http.get<any[]>('/api/projectitems/getgroups/'+projectId);
  }

  getProjectDirs(projectId: string): Observable<any[]> {
    return this.http.get<any[]>('/api/projectitems/dirs/'+projectId);
  }


  getProjectMainDirs(projectId: string): Observable<any[]> {
    return this.http.get<any[]>('/api/projectitems/maindirs/'+projectId);
  }
  

  addProject(projectItem: ProjectItem):Observable<any[]>{
    
 
    let body = new HttpParams();

    body = body.set('projectId', projectItem.projectId);   
    body = body.set('taskName', projectItem.taskName);
    body = body.set('taskStartDate', projectItem.taskStartDate.getFullYear()+'-'+(projectItem.taskStartDate.getMonth()+1)+'-'+projectItem.taskStartDate.getDate() );
    body = body.set('taskEndDate', projectItem.taskStartDate.getFullYear()+'-'+(projectItem.taskStartDate.getMonth()+1)+'-'+projectItem.taskStartDate.getDate()); 
    body = body.set('toDo', projectItem.toDo); 
    body = body.set('toDoUnit', projectItem.toDoUnit); 
    body = body.set('doBy', projectItem.doBy); 
    body = body.set('groupId', projectItem.groupId);
    body = body.set('taxRate', projectItem.taxRate);
    body = body.set('workAmountTotal', projectItem.workAmountTotal);
    body = body.set('itemAmountTotal', projectItem.itemAmountTotal);


    
    return this.http.post('/api/projectitems', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );   
  }


  addNewDirectory(projectId: any, groupId: any, taskName: string):Observable<any[]>{
    
    let body = new HttpParams();
    body = body.set('projectId', projectId);   
    body = body.set('taskName', taskName);
    body = body.set('groupId', groupId);
    
    return this.http.post('/api/projectitems/dirs', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    );   
  
  }



  saveProject(): void{
    
  }

  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }



}
