export class Subcontractor{
    
    constructor(
        public userId: string = '',
        public userLogin: string = '',
        public companyName: string='',
        public companyStreetType: string = '',        
        public companyStreet: string='',
        public companyNr: string='',
        public companyPostCode: string='',
        public companyCity: string='',
        public companyTaxNr: string = '',
        public companyBankAccount: string='',

        public contactPerson: string = '',
        public contactTelAreaCode: string = '+48',
        public contactTel: string = '',
        public contactPosition: string = 'Właściciel',
        public emailAddress: string = '',
        public serviceGroups: string = '',
        public webPage: string = '',
        public qrCodeData: string='',
        public contactPersons: string=''
    ){

    }
}