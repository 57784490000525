import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IFilesDirectory } from '../interfaces/filesdirectory.interface';
import { FilesDirectory } from '../models/filesdirectory.model';



@Injectable({
  providedIn: 'root'
})



export class FilesDirectoryService {

  constructor(private http: HttpClient) { }
  

  updateFilesDirectory(updateData: FilesDirectory):Observable<any>{


    console.log('Updating', updateData);

    let body = new HttpParams();

	  body = body.set('id', updateData.id);
	  body = body.set('directoryName', updateData.directoryName);

    return this.http.put('/api/files/directory', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  
  addFilesDirectory(addData: FilesDirectory):Observable<any>{


    let body = new HttpParams();

	  body = body.set('id', addData.id);
	  body = body.set('directoryName', addData.directoryName);
	
    return this.http.post('/api/files/directory', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  
  getAllFilesDirectory(): Observable<IFilesDirectory[]> {
    return this.http.get<IFilesDirectory[]>('/api/files/directory');
  }

  
  getFilesDirectory(itemId: number): Observable<IFilesDirectory[]> {
    return this.http.get<IFilesDirectory[]>('/api/files/directory/'+itemId);
  }

  
  delFilesDirectory(itemId: number):Observable<any> {
    return this.http.delete('/api/files/directory/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}