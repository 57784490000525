export class Tool{

    constructor(

	public id: any = 0,
	public did: any = 0,
	public toolName: string = '',
	public toolNumber: string = '',
	public purchaseDate: any = null,
	public toolGuarantee: any = 0,
	public toolNote: string = '',
	public toolLocalisation: string = '',
	public toolGetBy: string = '',
	public toolUseOn: string = '',
	public state: string = ''
	

    ){

    }
}