import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { SupplierGroup } from '../../../models/supplier-group.model';
import { ISupplierGroup } from   '../../../interfaces/supplier-group.interface';
import { SupplierGroupsService } from '../../../services/supplier-groups.service';


@Component({
  selector: 'app-supplier-groups',
  templateUrl: './supplier-groups.component.html',
  styleUrls: ['./supplier-groups.component.scss']
})


export class SupplierGroupsComponent implements OnInit {
	
  private allSupplierGroups:Array<ISupplierGroup> = [];
  private allMainSupplierGroups:Array<ISupplierGroup> = [];

  private supplierGroupModel = new SupplierGroup();


  private showSupplierGroupDrawer:boolean = false;
  private showAddEditSupplierGroupDrawer:boolean = false;

  private activateSupplierGroupDrawerSceleton:boolean = false;
  private activateAddEditSupplierGroupDrawerSceleton:boolean = false;

  private disableGroupSelect:boolean=false;

	

  constructor(private SupplierGroupsService: SupplierGroupsService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.SupplierGroupsService.getAllSupplierGroups().subscribe( data => { 
      this.allSupplierGroups = data['data']; 
    });
  
    this.SupplierGroupsService.getMainSupplierGroups().subscribe( data => {
      this.allMainSupplierGroups = data['data'];
    });
  
  
  }


  openAddEditSupplierGroupDrawer(itemId?: any){
  

    if(itemId){
	    this.activateAddEditSupplierGroupDrawerSceleton = true;
      this.showAddEditSupplierGroupDrawer = true;
      
      this.SupplierGroupsService.getSupplierGroup(itemId).subscribe(userData => {
        
        this.supplierGroupModel = new SupplierGroup(userData["data"].id, userData["data"].groupId, userData["data"].groupName);

        this.activateAddEditSupplierGroupDrawerSceleton=false;
        
        if(userData["data"].groupId=='0'){
          this.disableGroupSelect=true;
        }else{
          this.disableGroupSelect=false;
        }


      });
    
        
    }else{
      
      this.disableGroupSelect=false;
	    this.showAddEditSupplierGroupDrawer = true;
	   
	    this.activateAddEditSupplierGroupDrawerSceleton = false;
	    this.supplierGroupModel = new SupplierGroup();

    } 
  
  
  
  }


	  

  closeAddEditSupplierGroupDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditSupplierGroupDrawer = false;
   
  }


  submitAddEditSupplierGroupForm(formName: NgForm){


    if(+this.supplierGroupModel.id>0){


      this.SupplierGroupsService.updateSupplierGroup(this.supplierGroupModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditSupplierGroupDrawer = false;
        this.MessageService.success('Grupa została zmieniona.');
        
        this.SupplierGroupsService.getAllSupplierGroups().subscribe( data => {
          this.allSupplierGroups = data['data'];
        
        });
        
        this.SupplierGroupsService.getMainSupplierGroups().subscribe( data => {
          this.allMainSupplierGroups = data['data'];
        });

      
		},
		error => console.log('Error: ', error)
      );


    }else{

        this.SupplierGroupsService.addSupplierGroup(this.supplierGroupModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditSupplierGroupDrawer = false;
            
            this.MessageService.success('Grupa została dodana.');

            this.SupplierGroupsService.getAllSupplierGroups().subscribe( data => {
              this.allSupplierGroups = data['data'];
            
            });

            this.SupplierGroupsService.getMainSupplierGroups().subscribe( data => {
              this.allMainSupplierGroups = data['data'];
            });            
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmSupplierGroupDelete(recordId: any){

    this.SupplierGroupsService.delSupplierGroup(recordId).subscribe( data => {
	  this.MessageService.success('RECORD został usunięty');
      this.SupplierGroupsService.getAllSupplierGroups().subscribe( data => {
        this.allSupplierGroups = data['data'];
        
      });        
    });
  
  }





}