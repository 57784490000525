export class ProjectProtocol{

    constructor(

        public id: any = 0,
        public projectId: any = 0,
        public projectCommission: string = '',
        public projectAcceptance: string = '',
        public protocolValue: string = '',
        public protocolPaid: string = '',
        public protocolNumber: any = 0,
        public protocolType: string = 'p',
        public acceptanceDate: Date = new Date(),
        public protocolDateFrom: Date = new Date(),
        public protocolDateTo: Date = new Date(),
        public projectScore: string = 'p',
        public protocolNote: string = '',     
        public createdBy: any = 0,
        public status: any = 0
    ){

    }
}