import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { Subcontractor } from '../../../models/subcontractor.model';
import { ISubcontractor } from   '../../../interfaces/subcontractor.interface';

import { SubcontractorsService } from '../../../services/subcontractors.service';
import { SubcontractorGroupsService } from '../../../services/subcontractor-groups.service';
import { SubcontractorContactsService } from '../../../services/subcontractor-contacts.service';




@Component({
  templateUrl: './show-subcontractors.component.html',
  styleUrls: ['./show-subcontractors.component.scss']
})
export class ShowSubcontractorsComponent implements OnInit {

  private listOfOption = <any>[];
  private _serachWord:string='';
  private _searchGroup:string;

 
  private allSubcontractors:Array<ISubcontractor> = [];

  private availableSubcontractorGroups:Array<any> = [];

  private subcontractorModel = new Subcontractor();

  private showSubcontractorSearchForm:boolean = false;

  private showSubcontractorDrawer:boolean = false;
  private showAddEditSubcontractorDrawer:boolean = false;

  private activateSubcontractorDrawerSceleton:boolean = false;
  private activateAddEditSubcontractorDrawerSceleton:boolean = false;

  private subcontractorContactsList: Array<any> = [];

  private subcontractorContactsListEditCache:{ [key: string]: any } = {};


  constructor(private SubcontractorsService: SubcontractorsService, 
              private MessageService: NzMessageService, 
              private SubcontractorGroupsService: SubcontractorGroupsService,
              private SubcontractorContactsService: SubcontractorContactsService,
              private modalService: NzModalService
              ) { }


  ngOnInit() {

    this.SubcontractorsService.getAllSubcontractors().subscribe(data => this.allSubcontractors=data['data']);
    this.SubcontractorGroupsService.getAvailableSubcontractorGroups().subscribe( data => this.availableSubcontractorGroups=data['data'] );

  }


  searchSubcontractors(): void {

    this.SubcontractorsService.getAllSubcontractors(this._serachWord, this._searchGroup).subscribe(data => this.allSubcontractors=data['data']);
  }

  updateEditCache(): void {
    this.subcontractorContactsList.forEach(item => {
      
      this.subcontractorContactsListEditCache[ item.editId ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }

  startEdit(id: any): void {
    this.subcontractorContactsListEditCache[ id ].edit = true;   
  }

  cancelEdit(id: string): void {
    const index = this.subcontractorContactsList.findIndex(item => item.editId === id);
    
    this.subcontractorContactsListEditCache[ id ] = {
      data: { ...this.subcontractorContactsList[ index ] },
      edit: false
    };
  
  }

  saveEdit(id: string): void {
    const index = this.subcontractorContactsList.findIndex(item => item.editId === id);
    
    Object.assign(this.subcontractorContactsList[ index ], this.subcontractorContactsListEditCache[ id ].data);
    this.subcontractorContactsListEditCache[ id ].edit = false;
  }

  addSubcontractorContact(){

    this.subcontractorContactsList = [ ...this.subcontractorContactsList, {
        id     : '',
        editId: this.subcontractorContactsList.length+1,
        contactName   : '',
        contactTel    : '',
        contactPosition: '',
        contactTelAreaCode: '+48',
        contactEmail: ''
    }];




    this.updateEditCache();
  
  }


  togleSearchSubcontractorForm(){
    
    this.showSubcontractorSearchForm=!this.showSubcontractorSearchForm;
  
  }

  confirmSubcontractorDelete(recordId: any){

    this.SubcontractorsService.delSubcontractor(recordId).subscribe( data => {
      this.MessageService.success('Podwykonawca został usunięty');
      this.SubcontractorsService.getAllSubcontractors().subscribe( data => {
        this.allSubcontractors = data['data'];        
      });        
    });
  
  }



  openAddEditSubcontractorDrawer(userId?:number): void {
    this.showAddEditSubcontractorDrawer = true;
    
    this.subcontractorContactsList=[];

    if(userId){
      this.activateAddEditSubcontractorDrawerSceleton = true;

      this.SubcontractorsService.getSubcontractor(userId).subscribe(subData => {
        
        this.subcontractorModel.userId=subData['data'].userId;
        this.subcontractorModel.userLogin=subData['data'].userLogin;
        this.subcontractorModel.companyName=subData['data'].companyName;
        this.subcontractorModel.companyStreetType=subData['data'].companyStreetType;
        this.subcontractorModel.companyStreet=subData['data'].companyStreet;
        this.subcontractorModel.companyNr=subData['data'].companyNr;
        this.subcontractorModel.companyPostCode=subData['data'].companyPostCode;
        this.subcontractorModel.companyCity=subData['data'].companyCity;
        this.subcontractorModel.companyTaxNr=subData['data'].companyTaxNr;
        this.subcontractorModel.companyBankAccount=subData['data'].companyBankAccount;
        this.subcontractorModel.contactPerson=subData['data'].contactPerson;
        this.subcontractorModel.contactTelAreaCode=subData['data'].contactTelAreaCode;
        this.subcontractorModel.contactTel=subData['data'].contactTel;

        this.subcontractorModel.contactPosition=subData['data'].contactPosition;
        
        this.subcontractorModel.emailAddress=subData['data'].emailAddress;
        this.subcontractorModel.serviceGroups=subData['data'].serviceGroups.split(',');
        this.subcontractorModel.webPage=subData['data'].webPage;
        this.subcontractorModel.qrCodeData='';
        
        
        this.SubcontractorContactsService.getAllSubcontractorContacts(userId).subscribe(contactData => {
          
          

          let itemId=0;
          contactData["data"].forEach(item => {
            itemId++;


            this.subcontractorContactsList = [ ...this.subcontractorContactsList, {editId: itemId, ...item}];

          });          

          this.updateEditCache();

          this.activateAddEditSubcontractorDrawerSceleton = false;



        });        
        
        
        

      });    
    
    }else{
      this.subcontractorModel = new Subcontractor(); 
    }
  }


  openSubcontractorDrawer(userId?:number): void {
    
    this.subcontractorContactsList=[];

    if(userId){

      this.showSubcontractorDrawer = true;
      this.activateSubcontractorDrawerSceleton = true;
    
      this.SubcontractorsService.getSubcontractor(userId).subscribe(subData => {
      
        this.subcontractorModel.userId=subData['data'].userId;

        this.subcontractorModel.userLogin=subData['data'].userLogin;
        this.subcontractorModel.companyName=subData['data'].companyName;
        this.subcontractorModel.companyStreetType=subData['data'].companyStreetType;
        this.subcontractorModel.companyStreet=subData['data'].companyStreet;
        this.subcontractorModel.companyNr=subData['data'].companyNr;
        this.subcontractorModel.companyPostCode=subData['data'].companyPostCode;
        this.subcontractorModel.companyCity=subData['data'].companyCity;
        this.subcontractorModel.companyTaxNr=subData['data'].companyTaxNr;
        this.subcontractorModel.companyBankAccount=subData['data'].companyBankAccount;
        this.subcontractorModel.contactPerson=subData['data'].contactPerson;
        this.subcontractorModel.contactTelAreaCode=subData['data'].contactTelAreaCode;
        this.subcontractorModel.contactTel=subData['data'].contactTel;
        this.subcontractorModel.contactPosition=subData['data'].contactPosition;

        
        this.subcontractorModel.emailAddress=subData['data'].emailAddress;
        this.subcontractorModel.serviceGroups=subData['data'].serviceGroups;

        this.subcontractorModel.webPage=subData['data'].webPage;
        this.subcontractorModel.qrCodeData=`BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:`+this.subcontractorModel.contactPerson.replace(/ /i, ";")+`\nORG;CHARSET=UTF-8:`+this.subcontractorModel.companyName+`\nADR;WORK;PREF;CHARSET=UTF-8:;`+this.subcontractorModel.companyCity+` `+this.subcontractorModel.companyPostCode+`;`+this.subcontractorModel.companyStreet+` `+this.subcontractorModel.companyNr+`\nTITLE;CHARSET=UTF-8:`+this.subcontractorModel.contactPosition+`\nTEL;CELL:`+this.subcontractorModel.contactTelAreaCode+this.subcontractorModel.contactTel+`\nEMAIL:`+this.subcontractorModel.emailAddress+`\nEND:VCARD`;
        

        if(this.subcontractorModel.contactPerson!=''){
          this.subcontractorContactsList.push({
            qrCodeData: this.subcontractorModel.qrCodeData,
            edit: false,
            contactEmail: this.subcontractorModel.emailAddress,
            contactName: this.subcontractorModel.contactPerson,
            contactPosition: this.subcontractorModel.contactPosition,
            contactTel: this.subcontractorModel.contactTel,
            contactTelAreaCode: this.subcontractorModel.contactTelAreaCode,
            id: '0',
            subId: '0'
          });
        }

        this.SubcontractorContactsService.getAllSubcontractorContacts(userId).subscribe(contactData => {

          contactData["data"].forEach(item => {

            this.subcontractorContactsList.push({ 
              
              qrCodeData: `BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:`+item.contactName.replace(/ /i, ";")+`\nORG;CHARSET=UTF-8:`+this.subcontractorModel.companyName+`\nADR;WORK;PREF;CHARSET=UTF-8:;`+this.subcontractorModel.companyCity+` `+this.subcontractorModel.companyPostCode+`;`+this.subcontractorModel.companyStreet+` `+this.subcontractorModel.companyNr+`\nTITLE;CHARSET=UTF-8:`+item.contactPosition+`\nTEL;CELL:`+item.contactTelAreaCode+item.contactTel+`\nEMAIL:`+item.contactEmail+`\nEND:VCARD`,
              
              edit: false, ...item });

          });          

          this.activateSubcontractorDrawerSceleton=false;

        });



                
      });
    
    }else{
      this.MessageService.error('Nie mogę pobrać danych podwykonawcy');
    }
  } 


  closeSubcontractorDrawer(m?: any):void{
    this.showSubcontractorDrawer = false;	
  }

  closeAddEditSubcontractorDrawer(formName: NgForm): void{
  	formName.form.markAsUntouched(); 	  
    this.showAddEditSubcontractorDrawer = false;
  }


  submitAddEditSubcontractorForm(formName: NgForm){

    this.subcontractorModel.contactPersons=JSON.stringify(this.subcontractorContactsList);
    

    if (formName.invalid) { 
      Object.keys( formName.controls).forEach(key => {
        formName.controls[key].markAsTouched();
      });
      
    }

    //formName.controls['contactPerson'].markAsTouched();

//    alert(formName.invalid);

    if(formName.invalid){ //if having errors?
      this.modalService.error({
        nzTitle: 'Ups! Wystąpiły błędy',
        nzContent: 'Sprawdź formularz i popraw je.'
      });
    }else{

      if(+this.subcontractorModel.userId>0){

      
        
        this.SubcontractorsService.updateSubcontractor(this.subcontractorModel).subscribe( userData => { 
          formName.form.markAsUntouched(); 
          this.showAddEditSubcontractorDrawer = false;
          this.MessageService.success('Dane zostały Zapisane');
          
          this.SubcontractorsService.getAllSubcontractors(this._serachWord, this._searchGroup).subscribe(data => this.allSubcontractors=data['data']);
        
        },
        error => console.log('Error: ', error)
        );


      }else{





        this.SubcontractorsService.addSubcontractor(this.subcontractorModel).subscribe( userData => { 
          formName.form.markAsUntouched(); 
          this.showAddEditSubcontractorDrawer = false;

          this.MessageService.success('Użytkownik został dodany');

          this.SubcontractorsService.getAllSubcontractors(this._serachWord, this._searchGroup).subscribe(data => this.allSubcontractors=data['data']);
        
          },
          error => console.log('Error: ', error)
        );

      }  
    
    }
  }


}