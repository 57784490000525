import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'JsonToAddress'
})
export class JsonToAddressPipe implements PipeTransform {

  isJsonString(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  transform(jsonTxt: any): string {
    
    if(this.isJsonString(jsonTxt)){
      
      let addressArray=JSON.parse(jsonTxt);
      
      return addressArray.streetType+' '+addressArray.streetName+' '+addressArray.houseNr+', '+addressArray.postCode+' '+addressArray.city;

    }else{
      return '';
    }

  }



}




export class YesNoPipe implements PipeTransform {
  private yesNo: Array<any> = [];


  constructor(){
    this.yesNo[0]='Nie';
    this.yesNo[1]='Tak';
  }

  transform(optionValue: any): string {
    return this.yesNo[optionValue];
  }

}

