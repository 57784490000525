import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //HttpErrorResponse
import { Auth } from '../interfaces/auth';
//import { MessageService } from '../messages/message.service';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';



@Injectable({
  providedIn: 'root'
})

export class AuthService {
  
  currentUser: Auth;
  redirectUrl: string;

  get isLoggedIn(): boolean {
    return !!this.currentUser;
  }

  set userData(authData: Auth) {
    this.currentUser = authData;
  }

  constructor(private http: HttpClient) { }

  login(userName: string, password: string): Observable<any> {
    
    if (!userName || !password) {
      alert('Please enter your userName and password');
      //this.messageService.addMessage('Please enter your userName and password');
      return;
    }
    

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('m', 'login');
    body = body.set('userLogin', userName);
    body = body.set('userPass', password);

    //return 
    return this.http.post('/api_esczesono.php', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 



    // if (userName === 'admin') {
    //   this.currentUser = {
    //     id: 1,
    //     userName: userName,
    //     isAdmin: true
    //   };
    //   //this.messageService.addMessage('Admin login');
    //   return;
    // }
    
    // this.currentUser = {
    //   id: 2,
    //   userName: userName,
    //   isAdmin: false
    // };
    
    //this.messageService.addMessage(`User: ${this.currentUser.userName} logged in`);
  }

  logout(): Observable<any> {
    this.currentUser = null;

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});
    body = body.set('m', 'logout');

    return this.http.post('/api_esczesono.php', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }

  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}
