import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ITool } from '../interfaces/tool.interface';
import { Tool } from '../models/tool.model';



@Injectable({
  providedIn: 'root'
})


export class ToolsService {

  constructor(private http: HttpClient) { }
  
  updateTool(updateData: Tool):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('did', updateData.did);
    body = body.set('toolName', updateData.toolName);
    body = body.set('toolNumber', updateData.toolNumber);
    
    body = body.set('purchaseDate', updateData.purchaseDate.getFullYear()+'-'+(updateData.purchaseDate.getMonth()+1)+'-'+updateData.purchaseDate.getDate());

    body = body.set('toolGuarantee', updateData.toolGuarantee);
    body = body.set('toolNote', updateData.toolNote);
    body = body.set('toolLocalisation', updateData.toolLocalisation);
    body = body.set('toolGetBy', updateData.toolGetBy);
    body = body.set('toolUseOn', updateData.toolUseOn);
    body = body.set('state', updateData.state);
	

    return this.http.put('/api/tools', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addTool(addData: Tool):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', addData.id);
    body = body.set('did', addData.did);
    body = body.set('toolName', addData.toolName);
    body = body.set('toolNumber', addData.toolNumber);

    body = body.set('purchaseDate', addData.purchaseDate.getFullYear()+'-'+(addData.purchaseDate.getMonth()+1)+'-'+addData.purchaseDate.getDate());

    body = body.set('toolGuarantee', addData.toolGuarantee);
    body = body.set('toolNote', addData.toolNote);
    body = body.set('toolLocalisation', addData.toolLocalisation);
    body = body.set('toolGetBy', addData.toolGetBy);
    body = body.set('toolUseOn', addData.toolUseOn);
    body = body.set('state', addData.state);
	

    return this.http.post('/api/tools', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllTools(): Observable<ITool[]> {
    return this.http.get<ITool[]>('/api/tools');
  }

  
  getTool(itemId: number): Observable<ITool> {
    return this.http.get<ITool>('/api/tools/'+itemId);
  }


  
  delTool(itemId: number):Observable<any> {
    return this.http.delete('/api/tools/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
      return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}