export class TenderQuestion{
    constructor(
        public id: any = 0,
        public tenderQuestion: string = '',
        public sentDate: Date = new Date(),

        public tenderId: any = 0,
        public tenderQuestionTitle: string = '',
        public addedFiles: any = []
    ){

    }
}