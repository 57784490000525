import { Pipe, PipeTransform } from '@angular/core';
import { SubcontractorsService } from '../services/subcontractors.service';


@Pipe({
  name: 'Subcontractors'
})
export class SubcontractorsPipe implements PipeTransform {
  
  private contractorsArray: any[]=[];

  constructor(private SubcontractorsService: SubcontractorsService){
      
      this.contractorsArray[0]='Inter-Profil';

      this.SubcontractorsService.getAllSubcontractors().subscribe(
          data => {

              data["data"].forEach(
                  data => {
                      this.contractorsArray[data.id]=data.companyName;  
                  }
              );
              
          },
          error => console.log('Error: ', error)
      );

  }


  transform(contractorId: string): string {
    return this.contractorsArray[contractorId];
  }

}
