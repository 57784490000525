export class User{
    
    constructor(
        public userLogin: string,
        public userName: string,
        public userType: string,
        public userPass: string,
        public userPhone: string,
        public userEmail: string,
        public userId: string = '0',
        public qrCodeData: string=''
    ){

    }
}