import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TimeTableLayout'
})
export class TimeTableLayoutPipe implements PipeTransform {

  private timeTableLayout: Array<any> = [];


  constructor(){
    this.timeTableLayout['n']='Brak';
    this.timeTableLayout['g']='Belkowy/Gantta';
    this.timeTableLayout['v']='Belkowy z wartością';
  }


  transform(optionValue: any): string {
    return this.timeTableLayout[optionValue];
  }

}
