import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ITimeTable } from '../interfaces/timetable.interface';
import { TimeTable } from '../models/timetable.model';


@Injectable({
  providedIn: 'root'
})
export class TimeTableService {


  constructor(private http: HttpClient) { }
  

    
  
  setTimeTableOfficial(itemId: any):Observable<any>{

    //let body = new HttpParams();

    //body = body.set('id', updateData.id);


    return this.http.put('/api/timetables/setofficial/'+itemId, {}).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  updateTimeTableInstalments(updateData):Observable<any>{

    let body = new HttpParams();

    body = body.set('id',                   updateData.tid);
    body = body.set('timeTableInstalments', updateData.instalments);
   


    return this.http.put('/api/timetables/instalments', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 
  }


  updateTimeTable(updateData: TimeTable):Observable<any>{

    let body = new HttpParams();

    body = body.set('id', updateData.id);
    body = body.set('timeTableDate',  updateData.timeTableDate.getFullYear()+'-'+(updateData.timeTableDate.getMonth()+1)+'-'+updateData.timeTableDate.getDate());
    body = body.set('timeTableType',  updateData.timeTableType);
    body = body.set('timeTableLayout', updateData.timeTableLayout);
    body = body.set('isFull',         updateData.isFull?'1':'0' );

    body = body.set('showNettoAmount',         updateData.showNettoAmount?'1':'0' );
    body = body.set('showBruttoAmount',         updateData.showBruttoAmount?'1':'0' );
    body = body.set('showTaskPeriod',         updateData.showTaskPeriod?'1':'0' );

    body = body.set('timePeriods',    updateData.timePeriods);

    return this.http.put('/api/timetables', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  
  
  
    
  
  addTimeTable(addData: TimeTable):Observable<any>{


    let body = new HttpParams();

	  body = body.set('projectId', addData.projectId);
	  body = body.set('timeTableDate',  addData.timeTableDate.getFullYear()+'-'+(addData.timeTableDate.getMonth()+1)+'-'+addData.timeTableDate.getDate() );
    body = body.set('timeTableType', addData.timeTableType);
	  body = body.set('timeTableLayout', addData.timeTableLayout);
    
    body = body.set('isFull',           addData.isFull?'1':'0');
    body = body.set('showNettoAmount',  addData.showNettoAmount?'1':'0' );
    body = body.set('showBruttoAmount', addData.showBruttoAmount?'1':'0' );
    body = body.set('showTaskPeriod',   addData.showTaskPeriod?'1':'0' );    
    
    body = body.set('timePeriods', addData.timePeriods);
	  body = body.set('updatesTo', addData.updatesTo);


    return this.http.post('/api/timetables', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  


  



  getAllTimeTable(projectId: any): Observable<ITimeTable[]> {
    return this.http.get<ITimeTable[]>('/api/timetables/' + projectId);
  }

  
  getTimeTable(itemId: number): Observable<ITimeTable[]> {
    return this.http.get<ITimeTable[]>('/api/timetables/record/'+itemId);
  }

  getTimeTableValues(itemId: number): Observable<any[]> {
    return this.http.get<any[]>('/api/timetables/values/'+itemId);
  }
  
    

  




  
  delTimeTable(itemId: number):Observable<any> {
    return this.http.delete('/api/timetables/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }





  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }







}