import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'GroupType'
})
export class SucontractorGroupsTypePipe implements PipeTransform {

  constructor(){

  }

  transform(optionValue: any): string {
    
    if(optionValue=='0'){
        return 'Główna';
    }else{
        return 'Podgrupa';
    }
    
  }

}
