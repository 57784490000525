import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { ToolGroup } from '../../../models/tool-group.model';
import { IToolGroup } from   '../../../interfaces/tool-group.interface';
import { ToolGroupsService } from '../../../services/tool-groups.service';



@Component({
  selector: 'app-tool-groups',
  templateUrl: './tool-groups.component.html',
  styleUrls: ['./tool-groups.component.scss']
})

export class ToolGroupsComponent implements OnInit {
	
  private allToolGroups:Array<IToolGroup> = [];

  private toolGroupModel = new ToolGroup();


  private showToolGroupDrawer:boolean = false;
  private showAddEditToolGroupDrawer:boolean = false;

  private activateToolGroupDrawerSceleton:boolean = false;
  private activateAddEditToolGroupDrawerSceleton:boolean = false;



	

  constructor(private ToolGroupsService: ToolGroupsService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.ToolGroupsService.getAllToolGroups().subscribe( data => { 
      this.allToolGroups = data['data']; 
    });
  }


  openAddEditToolGroupDrawer(itemId?: any){
  

    if(itemId){
	    this.activateAddEditToolGroupDrawerSceleton = true;
		  this.showAddEditToolGroupDrawer = true;

      
		  this.ToolGroupsService.getToolGroup(itemId).subscribe(groupData => {
        
        this.toolGroupModel = new ToolGroup();
        this.toolGroupModel['id']=groupData['data']['id'];
        this.toolGroupModel['groupName']=groupData['data']['groupName'];


        this.activateAddEditToolGroupDrawerSceleton=false;
      
      });
    
        
    }else{
	  
      this.showAddEditToolGroupDrawer = true;
      
      this.activateAddEditToolGroupDrawerSceleton = false;
      this.toolGroupModel = new ToolGroup();

    } 
  
  
  
  }


  openToolGroupDrawer(itemId?: any): void{
 

    if(itemId){

	    this.showToolGroupDrawer = true;
      this.activateToolGroupDrawerSceleton = true;

      this.ToolGroupsService.getToolGroup(itemId).subscribe(userData => {
        
        this.toolGroupModel = new ToolGroup();
      
        this.activateToolGroupDrawerSceleton=false;		
		  });
    
    }else{
	  
		this.MessageService.error('Błąd odczytu grupy');
	  
    }
 
 
  }

  closeToolGroupDrawer(m?: any):void{
	  
    this.showToolGroupDrawer = false;	  

  }
	  

  closeAddEditToolGroupDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditToolGroupDrawer = false;
   
  }


  submitAddEditToolGroupForm(formName: NgForm){


    if(+this.toolGroupModel.id>0){


      this.ToolGroupsService.updateToolGroup(this.toolGroupModel).subscribe( userData => { 

          formName.form.markAsUntouched(); 

          this.showAddEditToolGroupDrawer = false;
          this.MessageService.success('Zmiany zostały zapisane');
          
          this.ToolGroupsService.getAllToolGroups().subscribe( data => {
            this.allToolGroups = data['data'];
          
          });
      
        },
        error => console.log('Błąd: ', error)
      );


    }else{

        this.ToolGroupsService.addToolGroup(this.toolGroupModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditToolGroupDrawer = false;
            
            this.MessageService.success('Grupa została dodana');

            this.ToolGroupsService.getAllToolGroups().subscribe( data => {
              this.allToolGroups = data['data'];
            
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmToolGroupDelete(recordId: any){

    this.ToolGroupsService.delToolGroup(recordId).subscribe( data => {
	  this.MessageService.success('Grupa została usunięta');
      this.ToolGroupsService.getAllToolGroups().subscribe( data => {
        this.allToolGroups = data['data'];
      });        
    });
  
  }





}
