import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';


import { File } from '../../../models/file.model';

import { FilesDirectory } from '../../../models/filesdirectory.model';
import { IFilesDirectory } from   '../../../interfaces/filesdirectory.interface';
import { IFile } from '../../../interfaces/file.interface'


import { FilesService } from '../../../services/files.service';
import { FilesDirectoryService } from '../../../services/files-directory.service';







@Component({
  selector: 'app-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.scss']
})

export class ProjectFilesComponent implements OnInit {
  @Input() projectId: string = '0';
  private projectFiles: any[]= [];
  private showAddFileDrawerState: boolean = false;

  private allFiles:Array<IFile> = [];

  private fileModel = new File();


  private allFilesDirectory:Array<IFilesDirectory> = [];

  private fileListFiles: any[]=[];


  private showFilesDrawer:boolean = false;

  private showAddEditFileDrawer:boolean = false;

  private activateFilesDrawerSceleton:boolean = false;
  private activateAddEditFilesDrawerSceleton:boolean = false;


  constructor(  private FilesService: FilesService, 
                private FilesDirectoryService: FilesDirectoryService, 
                private MessageService: NzMessageService
              ) { }



  closeAddFileDrawer(){
    this.showAddFileDrawerState=false;
  }


  showAddFileDrawer(){
    this.showAddEditFileDrawer=true;
  }


  ngOnInit() {
    
    this.FilesService.getProjectFiles(this.projectId).subscribe( data => {
      this.projectFiles = data['data'];
      
    });
    
    this.FilesDirectoryService.getAllFilesDirectory().subscribe( data => { 
      this.allFilesDirectory = data['data']; 
    });
  
  }

  
  closeAddEditFilesDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditFileDrawer = false;
   
  }



  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log('Uploading', file, fileList);
    }
    if (status === 'done') {
      //console.log('Uploaded:::', file.response);

      console.log('UUU: ', this.fileListFiles);
      this.MessageService.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {

      this.MessageService.error(`${file.name} file upload failed.`);
    }
  }

//this.fileListFiles
//submitAddEditFilesForm



submitAddEditFilesForm(formName: NgForm){

  if(+this.fileModel.id>0){
    /*
    this.FilesService.updateFile(this.fileModel).subscribe( userData => { 

      formName.form.markAsUntouched(); 
      this.showAddEditFileDrawer = false;
      this.MessageService.success('SAVED');      
      this.FilesService.getAllFiles().subscribe( data => {
        this.allFiles = data['data'];
      });
    
    },
    error => console.log('Error: ', error)
    );
    */
  }else{


    this.fileModel.projectId=this.projectId;
    
    this.fileModel.addedFiles=JSON.stringify(this.fileListFiles);
    this.FilesService.addFile(this.fileModel).subscribe( userData => {     
          formName.form.markAsUntouched(); 
          this.showAddEditFileDrawer = false;
          this.MessageService.success('Pliki zostały dodane.');

          this.FilesService.getProjectFiles(this.projectId).subscribe( data => {
            this.projectFiles = data['data'];
          });


        },
        error => console.log('Error: ', error)
      );   
  }
}




}