export class TenderQuestionAnswer {
    constructor(

        public id: any = 0,
        public tenderQuestionId: any = 0,
        public tenderQuestionResponse: string = '',
        public responseDate: Date = new Date,
        public addedFiles: string = ''
    
    ){}
}
