export class TenderFounder{

    constructor(

        public id: any = 0,
        public founderName: string = ''    
    
        ){
    
    }
}
