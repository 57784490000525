import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { IToolGroup } from '../interfaces/tool-group.interface';
import { ToolGroup } from '../models/tool-group.model';



@Injectable({
  providedIn: 'root'
})


export class ToolGroupsService {

  constructor(private http: HttpClient) { }
  
   
  updateToolGroup(updateData: ToolGroup):Observable<any>{

    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', String(updateData.id));
    body = body.set('groupName', updateData.groupName);

    return this.http.put('/api/toolgroups', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addToolGroup(addData: ToolGroup):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', String(addData.id));
    body = body.set('groupName', addData.groupName);
    

    return this.http.post('/api/toolgroups', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllToolGroups(): Observable<IToolGroup[]> {
    return this.http.get<IToolGroup[]>('/api/toolgroups');
  }

  
  getToolGroup(itemId: number): Observable<IToolGroup> {
    return this.http.get<IToolGroup>('/api/toolgroups/'+itemId);
  }


  
  delToolGroup(itemId: number):Observable<any> {
    return this.http.delete('/api/toolgroups/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}