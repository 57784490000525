export class ProjectAddressData {

    constructor(
        public streetType:  string='',
        public streetName:  string='',
        public houseNr: string='',
        public postCode:  string='',
        public city:  string=''
    ){}

}