import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //HttpErrorResponse
//import { Headers, RequestOptions, Response } from '@angular/common/';
import { Observable} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';


import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';




@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getConfigs(): Observable<any[]> {
    return this.http.get<any[]>('/api/settings');
  }


  getDocumentTemplate(itemId: string): Observable<any> {
    return this.http.get<any>('/api/documenttemplates/'+itemId);
  }


  updateCompanyData(updateData: any):Observable<any>{
    
    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('companyData', JSON.stringify(updateData) );

    return this.http.put('/api/settings', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  updateDocumentTemplate(updateData: any):Observable<any>{
    
    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('docTpl', updateData.docTpl);

    return this.http.put('/api/documenttemplates', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }

  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }
}