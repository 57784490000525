import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';


import { ISupplierContact } from '../interfaces/supplier-contact.interface';
import { SupplierContact } from '../models/supplier-contact.model';



@Injectable({
  providedIn: 'root'
})


export class SupplierContactsService {

  constructor(private http: HttpClient) { }
  
   
  updateSupplierContact(updateData: SupplierContact):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	

    return this.http.put('/api/suppliercontacts', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addSupplierContact(addData: SupplierContact):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

	

    return this.http.post('/api/suppliercontacts', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllSupplierContacts(userId: any): Observable<ISupplierContact[]> {
    return this.http.get<ISupplierContact[]>('/api/suppliercontacts/'+userId);
  }

  
  getSupplierContact(itemId: number): Observable<ISupplierContact> {
    return this.http.get<ISupplierContact>('/api/suppliercontacts/'+itemId);
  }


  
  delSupplierContact(itemId: number):Observable<any> {
    return this.http.delete('/api/suppliercontacts/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}
