import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { FilesDirectory } from '../../../models/filesdirectory.model';
import { IFilesDirectory } from '../../../interfaces/filesdirectory.interface';
import { FilesDirectoryService } from '../../../services/files-directory.service';


@Component({
  selector: 'app-files-directories',
  templateUrl: './files-directories.component.html',
  styleUrls: ['./files-directories.component.scss']
})

export class FilesDirectoriesComponent implements OnInit {

  private allFilesDirectory:Array<IFilesDirectory> = [];

  private filesDirectoryModel = new FilesDirectory();


  private showFilesDirectoryDrawer:boolean = false;
  private showAddEditFilesDirectoryDrawer:boolean = false;

  private activateFilesDirectoryDrawerSceleton:boolean = false;
  private activateAddEditFilesDirectoryDrawerSceleton:boolean = false;



	

  constructor(private FilesDirectoryService: FilesDirectoryService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.FilesDirectoryService.getAllFilesDirectory().subscribe( data => { 
      this.allFilesDirectory = data['data']; 
    });
  }


  openAddEditFilesDirectoryDrawer(itemId?: any){
  

    if(itemId){

      this.activateAddEditFilesDirectoryDrawerSceleton = true;
      this.showAddEditFilesDirectoryDrawer = true;

      this.FilesDirectoryService.getFilesDirectory(itemId).subscribe(userData => {
          
          this.filesDirectoryModel = new FilesDirectory();

          this.filesDirectoryModel.id=userData['data']['id'];
          this.filesDirectoryModel.directoryName=userData['data']['directoryName'];
          this.activateAddEditFilesDirectoryDrawerSceleton=false;
        
      });
    
        
    }else{
      
      this.showAddEditFilesDirectoryDrawer = true;
      
      this.activateAddEditFilesDirectoryDrawerSceleton = false;
      this.filesDirectoryModel = new FilesDirectory();

    } 
  
  }
	  

  closeAddEditFilesDirectoryDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched(); 	  
    this.showAddEditFilesDirectoryDrawer = false;
   
  }


  submitAddEditFilesDirectoryForm(formName: NgForm){

    if(+this.filesDirectoryModel.id>0){

      this.FilesDirectoryService.updateFilesDirectory(this.filesDirectoryModel).subscribe( userData => { 

          formName.form.markAsUntouched(); 

          this.showAddEditFilesDirectoryDrawer = false;
          this.MessageService.success('Zmiany zostały zapisane.');
          
          this.FilesDirectoryService.getAllFilesDirectory().subscribe( data => {
            this.allFilesDirectory = data['data'];
          });
      
		    },
		    error => console.log('Error: ', error)
      );


    }else{

        this.FilesDirectoryService.addFilesDirectory(this.filesDirectoryModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditFilesDirectoryDrawer = false;
            
            this.MessageService.success('Katalog został dodany');

            this.FilesDirectoryService.getAllFilesDirectory().subscribe( data => {
              this.allFilesDirectory = data['data'];
            
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmFilesDirectoryDelete(recordId: any){

    this.FilesDirectoryService.delFilesDirectory(recordId).subscribe( data => {
	  this.MessageService.success('Katalog został usunięty');
      this.FilesDirectoryService.getAllFilesDirectory().subscribe( data => {
        this.allFilesDirectory = data['data'];
        
      });        
    });
  
  }

}
