import { Pipe, PipeTransform } from '@angular/core';

import { SupplierGroupsService } from '../services/supplier-groups.service';


@Pipe({
  name: 'SupplierGroupsTags'
})
export class SupplierGroupsTagsPipe implements PipeTransform {
  private groupsArray: any[]=[];


  constructor(private SupplierGroupsService: SupplierGroupsService){
    this.SupplierGroupsService.getAvailableSupplierGroups().subscribe(data => {

      data["data"].forEach(
        data => {
            this.groupsArray[data.id]=data.groupName;   
        }
      );

    });
  }


  transform(value: any): any {
    

    let tmpArray=value.split(',');
    let returnArray=[];

    tmpArray.forEach(groupId=>{

      returnArray.push( this.groupsArray[groupId] );
    });
    
    return returnArray;
  }

}
