import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { ProjectProtocol } from '../../../models/project-protocol.model';
import { IProjectProtocol } from   '../../../interfaces/project-protocol.interface';
import { ProjectProtocolsService } from '../../../services/project-protocols.service';

@Component({
  selector: 'app-project-protocols',
  templateUrl: './project-protocols.component.html',
  styleUrls: ['./project-protocols.component.scss']
})

export class ProjectProtocolsComponent implements OnInit {
  @Input() projectId: string = '0';


  private allProjectProtocols:Array<IProjectProtocol> = [];

  private projectProtocolModel = new ProjectProtocol();
          

  private showProjectProtocolDrawer:boolean = false;
  private showAddEditProjectProtocolDrawer:boolean = false;

  private activateProjectProtocolDrawerSceleton:boolean = false;
  private activateAddEditProjectProtocolDrawerSceleton:boolean = false;


  private projectCommissionTable: any = [];
  private projectCommissionCache: any = {};


  private projectAcceptanceTable: any = [];
  private projectAcceptanceCache: any = {};
	
  
  constructor(private ProjectProtocolsService: ProjectProtocolsService, private MessageService: NzMessageService) {}

  ngOnInit() {
    this.ProjectProtocolsService.getAllProjectProtocols(this.projectId).subscribe( data => { 
      this.allProjectProtocols = data['data']; 
    });
  }

  //projectProtocolModel.projectCommission
  //projectProtocolModel.projectAcceptance

  addAcceptanceDocument(){
    
    this.projectAcceptanceTable = [ ...this.projectAcceptanceTable, {
      id:   this.projectAcceptanceTable.length+1,
      docName:  ''
    }];


    this.updateEditAcceptanceCache();
  }

  startAcceptanceEdit(id: string): void{
    this.projectAcceptanceCache[ id ].edit = true; 
  }


  saveAcceptanceEdit(id: string): void{
    const index = this.projectAcceptanceTable.findIndex(item => item.id === id);
    
    Object.assign(this.projectAcceptanceTable[ index ], this.projectAcceptanceCache[ id ].data);
    this.projectAcceptanceCache[ id ].edit = false;
  }

  cancelAcceptanceEdit(id: string): void {

    this.projectAcceptanceTable = this.projectAcceptanceTable.filter( (acceptance) => acceptance.id!==id);

    this.updateEditAcceptanceCache();

  }

  updateEditAcceptanceCache(): void {
    this.projectAcceptanceTable.forEach(item => {
      
      this.projectAcceptanceCache[ item.id ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }
  //////////////

  addCommissionPerson(){
    
    this.projectCommissionTable = [ ...this.projectCommissionTable, {
      id: this.projectCommissionTable.length+1,
      userName:   '',
      userTitle:  '',
      userType: 'i'
    }];


    this.updateEditCommissionCache();
  }

  startCommissionEdit(id: string): void{
    this.projectCommissionCache[ id ].edit = true; 
  }

  saveCommissionEdit(id: string): void{
    
    const index = this.projectCommissionTable.findIndex(item => item.id === id);
    Object.assign(this.projectCommissionTable[ index ], this.projectCommissionCache[ id ].data);
    this.projectCommissionCache[ id ].edit = false;
  
  
  }

  
  cancelCommissionEdit(id: string): void {

    this.projectCommissionTable = this.projectCommissionTable.filter( (commission) => commission.id!==id);

    this.updateEditCommissionCache();
  }

  updateEditCommissionCache(): void {
    this.projectCommissionTable.forEach(item => {
      
      this.projectCommissionCache[ item.id ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }




  openAddEditProjectProtocolDrawer(itemId?: any){
    this.projectCommissionTable=[];
    this.projectAcceptanceTable=[];

    if(itemId){
	    this.activateAddEditProjectProtocolDrawerSceleton = true;

      this.showAddEditProjectProtocolDrawer = true;

      this.ProjectProtocolsService.getProjectProtocol(itemId).subscribe(projectData => {
        
        this.projectProtocolModel = new ProjectProtocol();
        
        this.projectProtocolModel.id=projectData['data'].id;
        this.projectProtocolModel.projectId=projectData['data'].projectId;
        this.projectProtocolModel.protocolType=projectData['data'].protocolType;

        this.projectProtocolModel.acceptanceDate=projectData['data'].acceptanceDate=!'0000-00-00'?new Date(projectData['data'].acceptanceDate):new Date();
        this.projectProtocolModel.protocolDateFrom=projectData['data'].protocolDateFrom=!'0000-00-00'?new Date(projectData['data'].protocolDateFrom):new Date();
        this.projectProtocolModel.protocolDateTo=projectData['data'].protocolDateTo=!'0000-00-00'?new Date(projectData['data'].protocolDateTo):new Date();

        this.projectProtocolModel.projectScore=projectData['data'].projectScore;
        this.projectProtocolModel.protocolNote=projectData['data'].protocolNote;

        let proCom=JSON.parse(projectData['data'].projectCommission);
        let proAcc=JSON.parse(projectData['data'].projectAcceptance);

        proCom.forEach(item => {
          this.projectCommissionTable = [ ...this.projectCommissionTable, {
            id: this.projectCommissionTable.length+1,
            userName:   item.userName,
            userTitle:  item.userTitle,
            userType:   item.userType
          }];
        })
        this.updateEditCommissionCache();

        proAcc.forEach(item => {
          this.projectAcceptanceTable = [ ...this.projectAcceptanceTable, {
            id:   this.projectAcceptanceTable.length+1,
            docName:  item.docName
          }];
        })
        this.updateEditAcceptanceCache()


        this.activateAddEditProjectProtocolDrawerSceleton=false;
      
      });
    
        
    }else{
      this.showAddEditProjectProtocolDrawer = true;
	    this.activateAddEditProjectProtocolDrawerSceleton = false;
	    this.projectProtocolModel = new ProjectProtocol();
      this.projectProtocolModel.projectId=this.projectId;

    } 
  
  
  
  }


  openProjectProtocolDrawer(itemId?: any): void{
 

    if(itemId){

	    this.showProjectProtocolDrawer = true;
		  this.activateProjectProtocolDrawerSceleton = true;
		  this.ProjectProtocolsService.getProjectProtocol(itemId).subscribe(userData => {
        this.projectProtocolModel = new ProjectProtocol(userData['data'].userLogin, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id);
    
        this.activateProjectProtocolDrawerSceleton=false;
		  });
    
    }else{
	  
		  this.MessageService.error('ERROR');
	  
    }
 
 
  }

  closeProjectProtocolDrawer(m?: any):void{
	  
    this.showProjectProtocolDrawer = false;	  

  }
	  

  closeAddEditProjectProtocolDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditProjectProtocolDrawer = false;
   
  }


  confirmProjectProtocolOfficial(protocolId: any){
   

    this.ProjectProtocolsService.setProjectProtocolOfficial(protocolId).subscribe( data => {
      this.MessageService.success('Zmienione');
        this.ProjectProtocolsService.getAllProjectProtocols(this.projectId).subscribe( data => {
          this.allProjectProtocols = data['data'];
          
        });        
      });

  }


  submitAddEditProjectProtocolForm(formName: NgForm){

    this.projectProtocolModel.projectId=this.projectId;
    this.projectProtocolModel.projectCommission=JSON.stringify(this.projectCommissionTable); 
    this.projectProtocolModel.projectAcceptance=JSON.stringify(this.projectAcceptanceTable);

    if(+this.projectProtocolModel.id>0){


      this.ProjectProtocolsService.updateProjectProtocol(this.projectProtocolModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditProjectProtocolDrawer = false;
        this.MessageService.success('Protokół zapisany');
        
        this.ProjectProtocolsService.getAllProjectProtocols(this.projectId).subscribe( data => {
          this.allProjectProtocols = data['data'];
        
        });
      
	  	},
		  error => console.log('Error: ', error)
      );


    }else{

      //{{item.acceptanceDate}}
      //{{item.projectCommission}}



        this.ProjectProtocolsService.addProjectProtocol(this.projectProtocolModel).subscribe( userData => { 
            
            formName.form.markAsUntouched(); 
            this.showAddEditProjectProtocolDrawer = false;
            
            this.MessageService.success('Protokół zapisany');

            
            this.ProjectProtocolsService.getAllProjectProtocols(this.projectId).subscribe( data => {
              this.allProjectProtocols = data['data'];
            });
          
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmProjectProtocolDelete(recordId: any){

    this.ProjectProtocolsService.delProjectProtocol(recordId).subscribe( data => {
	  this.MessageService.success('Protokół został usunięty');
      this.ProjectProtocolsService.getAllProjectProtocols(this.projectId).subscribe( data => {
        this.allProjectProtocols = data['data'];
        
      });        
    });
  
  }





}