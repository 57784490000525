import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ISubcontractorsAgreements } from '../interfaces/subcontractorsagreements.interface';
import { SubcontractorsAgreement } from '../models/subcontractorsagreements.model';


@Injectable({
  providedIn: 'root'
})



export class SubcontractorsAgreementsService {

  constructor(private http: HttpClient) { }
  

    

    
  updateSubcontractorsAgreementsAgreement(updateData: SubcontractorsAgreement):Observable<any>{


    let body = new HttpParams();

    body = body.set('id', updateData.id);
    body = body.set('contractTxt', updateData.contractTxt);
  

    return this.http.put('/api/subcontractorsagreements/agreement', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  
  updateSubcontractorsAgreements(updateData: SubcontractorsAgreement):Observable<any>{


    let body = new HttpParams();

    body = body.set('id', updateData.id);

    body = body.set('agreementDate', updateData.agreementDate.getFullYear()+'-'+(updateData.agreementDate.getMonth()+1)+'-'+updateData.agreementDate.getDate());
    body = body.set('agreementCompletionData', updateData.agreementCompletionData.getFullYear()+'-'+(updateData.agreementCompletionData.getMonth()+1)+'-'+updateData.agreementCompletionData.getDate());

    body = body.set('amountNetto', updateData.amountNetto);
    //body = body.set('amountVat', updateData.amountVat);
    body = body.set('taskNotes', updateData.taskNotes);
	

    return this.http.put('/api/subcontractorsagreements', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  
  
  
    
  
  addSubcontractorsAgreements(addData: SubcontractorsAgreement):Observable<any>{

    let body = new HttpParams();

    body = body.set('subId', addData.subId);
    body = body.set('projectId', addData.projectId);
    body = body.set('agreementDate', addData.agreementDate.getFullYear()+'-'+(addData.agreementDate.getMonth()+1)+'-'+addData.agreementDate.getDate());
    body = body.set('agreementCompletionData', addData.agreementCompletionData.getFullYear()+'-'+(addData.agreementCompletionData.getMonth()+1)+'-'+addData.agreementCompletionData.getDate());
    body = body.set('amountNetto', addData.amountNetto);
    body = body.set('taskNotes', addData.taskNotes);

    body = body.set('payPerUnit', addData.payPerUnit?'1':'0') ;
    body = body.set('detailedWorksList', addData.detailedWorksList?'1':'0');
    body = body.set('pricePerUnit', addData.pricePerUnit);
    body = body.set('pricePerUnitName', addData.pricePerUnitName);
    body = body.set('consolidatedWorksName', addData.consolidatedWorksName);

    body = body.set('workType', addData.workType);
    
    body = body.set('subcontractorAgreementElementsList', JSON.stringify(addData.subcontractorAgreementElementsList));





    return this.http.post('/api/subcontractorsagreements', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  
  

  getSubcontractorsAgreementsElements(projectId: any, subId: any): Observable<ISubcontractorsAgreements[]> {
    return this.http.get<any[]>('/api/subcontractorsagreements/elements/'+projectId+'/'+subId);
  }
  

  getProjectAvailableSubcontractors(projectId: any): Observable<ISubcontractorsAgreements[]> {
    return this.http.get<ISubcontractorsAgreements[]>('/api/subcontractorsagreements/available/'+projectId);
  }


  getAllSubcontractorsAgreements(projectId: any): Observable<ISubcontractorsAgreements[]> {
    return this.http.get<ISubcontractorsAgreements[]>('/api/subcontractorsagreements/'+projectId);
  }

  
  getSubcontractorsAgreements(itemId: number): Observable<ISubcontractorsAgreements[]> {
    return this.http.get<ISubcontractorsAgreements[]>('/api/subcontractorsagreements/single/'+itemId);
  }


  getSubcontractorsAgreementsAgreement(itemId: number): Observable<ISubcontractorsAgreements[]> {
    return this.http.get<ISubcontractorsAgreements[]>('/api/subcontractorsagreements/agreement/'+itemId);
  }


  
    
  
  setSubcontractorsAgreementOfficial(itemId: number):Observable<any> {
    return this.http.put('/api/subcontractorsagreements/setofficial/'+itemId, {}).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  




  
  delSubcontractorsAgreements(itemId: number):Observable<any> {
    return this.http.delete('/api/subcontractorsagreements/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }





  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}