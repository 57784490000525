import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'UserType'})
export class UserType implements PipeTransform {
  transform(userType: string): string {
    
    let userTypesArray: any[] =[];
    userTypesArray['1']='Administrator';
    userTypesArray['2']='Pracownik biurowy';
    userTypesArray['3']='Kierownik budowy';
    userTypesArray['4']='Fachowiec';
    userTypesArray['5']='Podwykonawca';

    return userTypesArray[userType];
  }
}

