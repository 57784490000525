export class SubcontractorsAgreement{

    constructor(

		public id: any = 0,
		public subId: any = 0,
		public projectId: any = 0,
		public agreementDate: Date = new Date(),
		public agreementCompletionData: Date = new Date(),
		public amountNetto: string = '',
		public amountVat: number = 0,
		public taskNotes: string = '',
		public contractTxt: string = '',
	

		public payPerUnit: boolean = false,
		public detailedWorksList: boolean = false,

		
		public pricePerUnit: any = '',
		public pricePerUnitName: any = '',
		public consolidatedWorksName: string='',
		public subcontractorAgreementElementsList: any[]=[],
		public workType: string = 'p'




    //agreementPayPerUnit
    //agreementDetailedWorksList
    //pricePerUnit
    //pricePerUnitName
    //consolidatedWorksName
    //subcontractorAgreementElementsList
    //


    ){

    }
}