export class Projects{

    constructor(

    public id: any='0',
    public projectName:     string='',
    public projectAddress:  string='',
    public projectNote:     string='',
    public projectStart:    any = new Date(),
    public projectEnd:      any = new Date(),
    public managerId:       any=undefined,
    public projectId:       string='0',
    public status:          string='0',
    public basicEdit:       string='2',
    public projectExtraData: any=''

    ){

    }
}