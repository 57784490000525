import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpParamsCustomEncoder } from '../classes/http-params-custom-encoder.class';

import { ISupplierGroup } from '../interfaces/supplier-group.interface';
import { SupplierGroup } from '../models/supplier-group.model';


@Injectable({
  providedIn: 'root'
})

export class SupplierGroupsService {

  constructor(private http: HttpClient) { }
  
   
  updateSupplierGroup(updateData: SupplierGroup):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', updateData.id);
    body = body.set('groupId', updateData.groupId);
    body = body.set('groupName', updateData.groupName);
	

    return this.http.put('/api/suppliergroups', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
 
  
   
  addSupplierGroup(addData: SupplierGroup):Observable<any>{


    let body = new HttpParams({encoder: new HttpParamsCustomEncoder()});

    body = body.set('id', addData.id);
    body = body.set('groupId', addData.groupId);
    body = body.set('groupName', addData.groupName);
	

    return this.http.post('/api/suppliergroups', body).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }
  

  
  getAllSupplierGroups(): Observable<ISupplierGroup[]> {
    return this.http.get<ISupplierGroup[]>('/api/suppliergroups');
  }


  getAvailableSupplierGroups(): Observable<ISupplierGroup[]> {
    return this.http.get<ISupplierGroup[]>('/api/suppliergroups/available');
  }


  getMainSupplierGroups(): Observable<ISupplierGroup[]> {
    return this.http.get<ISupplierGroup[]>('/api/suppliergroups/main');
  }  
  
  getSupplierGroup(itemId: number): Observable<ISupplierGroup> {
    return this.http.get<ISupplierGroup>('/api/suppliergroups/'+itemId);
  }


  
  delSupplierGroup(itemId: number):Observable<any> {
    return this.http.delete('/api/suppliergroups/'+itemId).pipe(
      map(this.extractData),
      catchError( this.handleError )
    ); 

  }


  private extractData(res: any){
    return res || {};
  }

  private handleError(error: any){
    return Observable.throw(error);
  }


}