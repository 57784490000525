import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TimeTableType'
})
export class TimeTableTypePipe implements PipeTransform {
  private timeTableTypes: Array<any> = [];


  constructor(){
    this.timeTableTypes['m']='Głowny';
    this.timeTableTypes['a']='Aneks';
    this.timeTableTypes['u']='Zaktualizowany';
  }


  transform(optionValue: any): string {
    return this.timeTableTypes[optionValue];
  }



}
