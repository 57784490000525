import { Component, OnInit, Input } from '@angular/core';

import { NgForm } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';

import { SubcontractorsAgreement } from '../../../models/subcontractorsagreements.model';
import { ISubcontractorsAgreements } from   '../../../interfaces/subcontractorsagreements.interface';
import { SubcontractorsAgreementsService } from '../../../services/subcontractors-agreements.service';

import { SubcontractorsService } from '../../../services/subcontractors.service';

//import { AngularEditorConfig } from '@kolkov/angular-editor';

import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';



import { SubcontractorsAgreementsItem } from '../../../models/subcontractors-agreements-item.model';
import { ISubcontractorsAgreementsItem } from   '../../../interfaces/subcontractors-agreements-item.interface';
import { SubcontractorsAgreementsItemsService } from '../../../services/subcontractors-agreements-items.service';


import { IProjectSubcontractorProtocol } from '../../../interfaces/project-subcontractor-protocol.interface';
import { ProjectSubcontractorProtocol} from '../../../models/project-subcontractor-protocol.model';
import { ProjectSubcontractorProtocolsService } from '../../../services/project-subcontractor-protocols.service';





@Component({
  selector: 'app-project-subcontractors',
  templateUrl: './project-subcontractors.component.html',
  styleUrls: ['./project-subcontractors.component.scss']
})
export class ProjectSubcontractorsComponent implements OnInit {
  
  @Input() projectId: string = '0';

  listOfChildrenData = [];


 
  private agreementSubcontractors:Array<any> = [];

  private availableProjectSubcontractors:Array<any> = [];
  private availableSubcontractors:Array<any> = [];

  private subcontractorAgreementElementsList:Array<any> = [];


  private allSubcontractorsAgreements:Array<ISubcontractorsAgreements> = [];
  private allSubcontractorsAgreementsItems: Array<ISubcontractorsAgreementsItem> = [];



  private subcontractorsAgreementModel = new SubcontractorsAgreement();


  private showSubcontractorsAgreementsDrawer:boolean = false;
  private showAddEditSubcontractorsAgreementDrawer:boolean = false;
  private showSubcontractorAgreementProtocolDrawer:boolean = false;
  

  private activateSubcontractorsAgreementsDrawerSceleton:boolean = false;
  private activateAddEditSubcontractorsAgreementsDrawerSceleton:boolean = false;
  private activateSubcontractorAgreementProtocolDrawerSceleton:boolean = false;

  private showEditSubcontractorAgreementDrawer:boolean = false;
  private activateSubcontractorAgreementDrawerSceleton:boolean = false;  

  private agreementPayPerUnit:boolean = false;
  private agreementDetailedWorksList: boolean = true;

  private agreementPayPerUnitLoading:boolean = false;
  private agreementDetailedWorksListLoading: boolean = false;
	
  private agreementWorkType = 'p';

  public Editor = DecoupledEditor;

  private projectCommissionTable: any = [];
  private projectCommissionCache: any = {};


  private projectAcceptanceTable: any = [];
  private projectAcceptanceCache: any = {};


  private projectSubcontractorProtocolModel = new ProjectSubcontractorProtocol();
          

  constructor(
      private SubcontractorsService: SubcontractorsService, 
      private SubcontractorsAgreementsService: SubcontractorsAgreementsService, 
      private MessageService: NzMessageService,
      private SubcontractorsAgreementsItemsService: SubcontractorsAgreementsItemsService,
      private ProjectSubcontractorProtocolsService: ProjectSubcontractorProtocolsService
    ) {}

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
}

  addAcceptanceDocument(){
    
    this.projectAcceptanceTable = [ ...this.projectAcceptanceTable, {
      id:   this.projectAcceptanceTable.length+1,
      docName:  ''
    }];


    this.updateEditAcceptanceCache();
  }

  startAcceptanceEdit(id: string): void{
    this.projectAcceptanceCache[ id ].edit = true; 
  }

  saveAcceptanceEdit(id: string): void{
    const index = this.projectAcceptanceTable.findIndex(item => item.id === id);
    
    Object.assign(this.projectAcceptanceTable[ index ], this.projectAcceptanceCache[ id ].data);
    this.projectAcceptanceCache[ id ].edit = false;
  }
  cancelAcceptanceEdit(id: string): void {
    const index = this.projectAcceptanceTable.findIndex(item => item.id === id);
    
    this.projectAcceptanceCache[ id ] = {
      data: { ...this.projectAcceptanceTable[ index ] },
      edit: false
    };
  }

  updateEditAcceptanceCache(): void {
    this.projectAcceptanceTable.forEach(item => {
      
      this.projectAcceptanceCache[ item.id ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }
  //////////////

  addCommissionPerson(){
    
    this.projectCommissionTable = [ ...this.projectCommissionTable, {
      id: this.projectCommissionTable.length+1,
      userName:   '',
      userTitle:  '',
      userType: 'i'
    }];


    this.updateEditCommissionCache();
  }

  startCommissionEdit(id: string): void{
    this.projectCommissionCache[ id ].edit = true; 
  }

  saveCommissionEdit(id: string): void{
    
    const index = this.projectCommissionTable.findIndex(item => item.id === id);
    Object.assign(this.projectCommissionTable[ index ], this.projectCommissionCache[ id ].data);
    this.projectCommissionCache[ id ].edit = false;
  
  
  }

  
  cancelCommissionEdit(id: string): void {
    const index = this.projectCommissionTable.findIndex(item => item.id === id);
    
    this.projectCommissionCache[ id ] = {
      data: { ...this.projectCommissionTable[ index ] },
      edit: false
    };
  }

  updateEditCommissionCache(): void {
    this.projectCommissionTable.forEach(item => {
      
      this.projectCommissionCache[ item.id ] = Object.assign({}, {
        edit: false,
        data: { ...item }
      });
    
    });
  }







  changeWorkType(event){

    this.subcontractorAgreementElementsList=[];
    this.subcontractorsAgreementModel.subId=undefined;

    if(event==='p'){
      this.agreementSubcontractors=this.availableProjectSubcontractors;
    }else{
      this.agreementSubcontractors=this.availableSubcontractors;
      this.agreementDetailedWorksList=true;
    }

  }



  submitAddSubcontractorProjectProtocolForm(form: NgForm):void{

    
    this.projectSubcontractorProtocolModel.projectItems=JSON.stringify(this.allSubcontractorsAgreementsItems);
    this.projectSubcontractorProtocolModel.projectAcceptance=JSON.stringify(this.projectAcceptanceTable);
    this.projectSubcontractorProtocolModel.projectCommission=JSON.stringify(this.projectCommissionTable);


    this.ProjectSubcontractorProtocolsService.addProjectSubcontractorProtocol(this.projectSubcontractorProtocolModel).subscribe(a=>{})
    
  }

  openSubcontractorAgreementProtocolDrawer(subAgreementId: any): void {
 
    //jeszcze musimy pobierać dane o samym protokole i w zalenosci od tego
    // wyświetlać odpwoweidnie opcjie w prcaach
    this.SubcontractorsAgreementsItemsService.getSubcontractorsAgreementsItem(subAgreementId).subscribe( data => {
      this.allSubcontractorsAgreementsItems = data['data'];
    });

    this.showSubcontractorAgreementProtocolDrawer=true;
    //this.activateSubcontractorAgreementProtocolDrawerSceleton=false;

  }

  closeSubcontractorAgreementProtocolDrawer(){
    this.showSubcontractorAgreementProtocolDrawer=false;
    //this.activateSubcontractorAgreementProtocolDrawerSceleton=false;
  }


  addEmptySubcontrctorElementToList(): void {
  
    this.subcontractorAgreementElementsList = [...this.subcontractorAgreementElementsList, 
      { id: this.subcontractorAgreementElementsList.length+1, 
        taskName: 'Nazwa prac', 
        toDo: '10', 
        toDoUnit: 'm2', 
        unitPrice: '2', 
        unitUnit: 'm2'
      }];
      
      console.log(this.subcontractorAgreementElementsList);
  }

  changeAgreementSubcontractor(subId: any): void{    

    if(this.agreementWorkType==='p'){
      this.SubcontractorsAgreementsService.getSubcontractorsAgreementsElements(this.projectId, subId).subscribe( agreementItem => {

        this.subcontractorAgreementElementsList=[];

        agreementItem['data'].forEach(element => {
          
          this.subcontractorAgreementElementsList = [...this.subcontractorAgreementElementsList, 
            { id: element.id, 
              taskName: element.taskName, 
              toDo: element.toDo, 
              toDoUnit: element.toDoUnit, 
              unitPrice: '2', 
              unitUnit: 'm2'
            }];  
        
        });
      });


    }
  }

  clickSwitchPayPerUnit(): void{
    if (!this.agreementPayPerUnitLoading) {
      this.agreementPayPerUnitLoading = true;
      
      this.agreementPayPerUnit = !this.agreementPayPerUnit;
      this.agreementPayPerUnitLoading = false;
    }
  }


  clickSwitchDetailedWorks(): void {

    if(this.agreementWorkType==='p'){    
      if (!this.agreementDetailedWorksListLoading) {
        this.agreementDetailedWorksListLoading = true;
        this.agreementDetailedWorksList = !this.agreementDetailedWorksList;
        this.agreementDetailedWorksListLoading = false;
      }
    }
  }


  ngOnInit() {


    this.SubcontractorsService.getAllSubcontractors().subscribe(subItems => {
      this.availableSubcontractors=subItems['data'];
    });


    this.SubcontractorsAgreementsService.getProjectAvailableSubcontractors(this.projectId).subscribe( subItems => {

      this.availableProjectSubcontractors=subItems["data"];
      this.agreementSubcontractors=subItems["data"];
    });



    this.SubcontractorsAgreementsService.getAllSubcontractorsAgreements(this.projectId).subscribe( data => { 
      this.allSubcontractorsAgreements = data['data']; 
      
      this.allSubcontractorsAgreements.forEach(agreementItem => {
        agreementItem.expand=false;
      }); // było dodane , this.allSubcontractorsAgreements 
    

    });


    
    for (let i = 0; i < 3; ++i) {
      this.listOfChildrenData.push({
        key       : i,
        date      : (i+10)+'/pro/2019',
        name      : '80 500 zł',
        upgradeNum: 'Upgraded: 56'
      });
    }



  }


  confirmSubcontractorsAgreementsOfficial(itemId: any){

    this.SubcontractorsAgreementsService.setSubcontractorsAgreementOfficial(itemId).subscribe( data =>{
      
      this.SubcontractorsAgreementsService.getAllSubcontractorsAgreements(this.projectId).subscribe( data => {
        this.allSubcontractorsAgreements = data['data'];
        this.MessageService.success('Zapisane');
      });        
    
    });


  }


  closeSubcontractorAgreementDrawer(){
    this.showEditSubcontractorAgreementDrawer = false;	  
  
  
  
  
  }

  openEditSubcontractorAgreementDrawer(itemId: any){
    this.showEditSubcontractorAgreementDrawer = true;
    this.activateSubcontractorAgreementDrawerSceleton = true;

    this.SubcontractorsAgreementsService.getSubcontractorsAgreementsAgreement(itemId).subscribe(itemData => {
  
      this.subcontractorsAgreementModel = new SubcontractorsAgreement();
      this.subcontractorsAgreementModel.contractTxt = itemData["data"].contractTxt;
      this.subcontractorsAgreementModel.id = itemData["data"].id;

      this.activateSubcontractorAgreementDrawerSceleton = false;
    });

  }

  submitEditSubcontractorsAgreementsAgreementForm(){
    
    this.subcontractorsAgreementModel.projectId=this.projectId;
    this.SubcontractorsAgreementsService.updateSubcontractorsAgreementsAgreement(this.subcontractorsAgreementModel).subscribe( userData => { 
        this.showEditSubcontractorAgreementDrawer = false;
        this.MessageService.success('Umowa została zapisana');
      },
      error => console.log('Error: ', error)
    );
    

  }

  openAddEditSubcontractorsAgreementsDrawer(itemId?: any){
  
  
  
    this.showAddEditSubcontractorsAgreementDrawer = true;
    
    this.activateAddEditSubcontractorsAgreementsDrawerSceleton = true;

    if(itemId){
      
      this.SubcontractorsAgreementsService.getSubcontractorsAgreements(itemId).subscribe(agreementData => {
        
        this.subcontractorsAgreementModel = new SubcontractorsAgreement();
        this.subcontractorsAgreementModel.id=agreementData["data"].id;
        this.subcontractorsAgreementModel.subId=agreementData["data"].subId;
        this.subcontractorsAgreementModel.amountNetto  =agreementData["data"].amountNetto;
        this.subcontractorsAgreementModel.taskNotes =agreementData["data"].taskNotes;
        this.subcontractorsAgreementModel.projectId =agreementData["data"].projectId;

        this.subcontractorsAgreementModel.agreementCompletionData = agreementData["data"].agreementCompletionData!='0000-00-00'?new Date(agreementData["data"].agreementCompletionData):new Date();
        this.subcontractorsAgreementModel.agreementDate = agreementData["data"].agreementDate!='0000-00-00'? new Date(agreementData["data"].agreementDate):new Date();

        this.activateAddEditSubcontractorsAgreementsDrawerSceleton=false;
      
      });
      
    }else{
	  
      this.activateAddEditSubcontractorsAgreementsDrawerSceleton = false;
      this.subcontractorsAgreementModel = new SubcontractorsAgreement();

    } 
  
  
  
  }


  openSubcontractorsAgreementsDrawer(itemId?: any): void{
 
    this.showSubcontractorsAgreementsDrawer = true;
    
    this.activateSubcontractorsAgreementsDrawerSceleton = true;

    if(itemId){
      
      this.SubcontractorsAgreementsService.getSubcontractorsAgreements(itemId).subscribe(userData => {
        
        //this.subcontractorsAgreementModel = new User(userData['data'].userLogin, userData['data'].userName, userData['data'].userType, '', userData['data'].userPhone, userData['data'].userEmail, userData['data'].id);

        this.activateSubcontractorsAgreementsDrawerSceleton=false;
      
      });
    
    }else{
	  
	    this.activateSubcontractorsAgreementsDrawerSceleton = false;
	    this.subcontractorsAgreementModel = new SubcontractorsAgreement();

    }
 
 
  
  }

  closeSubcontractorsAgreementsDrawer(m?: any):void{
	  
    this.showSubcontractorsAgreementsDrawer = false;	  

  }
	  

  closeAddEditSubcontractorsAgreementDrawer(formName: NgForm): void{

  	formName.form.markAsUntouched();
 	  
  	//this.userExistError=false;
    this.showAddEditSubcontractorsAgreementDrawer = false;
   
  }


  submitAddEditSubcontractorsAgreementForm(formName: NgForm){

		this.subcontractorsAgreementModel.payPerUnit=this.agreementPayPerUnit;
		this.subcontractorsAgreementModel.detailedWorksList=this.agreementDetailedWorksList;

    this.subcontractorsAgreementModel.workType=this.agreementWorkType;

//		public pricePerUnit: any = '',
	//	public pricePerUnitName: any = '',
		//public consolidatedWorksName: string='',
		this.subcontractorsAgreementModel.subcontractorAgreementElementsList=this.subcontractorAgreementElementsList;




    //
    //
    //pricePerUnit
    //pricePerUnitName
    //consolidatedWorksName
    //subcontractorAgreementElementsList
    //

    if(this.subcontractorsAgreementModel.id){
      this.SubcontractorsAgreementsService.updateSubcontractorsAgreements(this.subcontractorsAgreementModel).subscribe( userData => { 

        formName.form.markAsUntouched(); 

        this.showAddEditSubcontractorsAgreementDrawer = false;
        this.MessageService.success('Zapisane');
        
        this.SubcontractorsAgreementsService.getAllSubcontractorsAgreements(this.projectId).subscribe( data => {
          this.allSubcontractorsAgreements = data['data'];
        
        });
      
		  },error => console.log('Error: ', error)
      );

    }else{

        this.subcontractorsAgreementModel.projectId=this.projectId;
        this.SubcontractorsAgreementsService.addSubcontractorsAgreements(this.subcontractorsAgreementModel).subscribe( userData => { 
            formName.form.markAsUntouched(); 
            this.showAddEditSubcontractorsAgreementDrawer = false;            
            this.MessageService.success('Umowa została dodana');
            this.SubcontractorsAgreementsService.getAllSubcontractorsAgreements(this.projectId).subscribe( data => {
              this.allSubcontractorsAgreements = data['data'];
            });
          },
          error => console.log('Error: ', error)
        );
        
    }





  }


  confirmSubcontractorsAgreementsDelete(recordId: any){

    this.SubcontractorsAgreementsService.delSubcontractorsAgreements(recordId).subscribe( data => {
      this.SubcontractorsAgreementsService.getAllSubcontractorsAgreements(this.projectId).subscribe( data => {
        this.allSubcontractorsAgreements = data['data'];
        this.MessageService.success('Umowa została usunięta');
      });        
    });
  
  }




  
}