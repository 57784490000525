import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowUsersComponent } from './components/users/show-users/show-users.component';

import { ShowSubcontractorsComponent } from './components/subcontractors/show-subcontractors/show-subcontractors.component';
import { ShowProjectsComponent } from './components/projects/show-projects/show-projects.component';
import { ShowSettingsComponent } from './components/settings/show-settings/show-settings.component';
import { GetProjectComponent } from './components/projects/get-project/get-project.component'

import { ShowSuppliersComponent } from './components/suppliers/show-suppliers/show-suppliers.component';
import { ToolsComponent } from './components/tools/tools.component';
import { TendersComponent } from './components/tenders/tenders.component';
import { TendersStatsComponent } from './components/tenders/tenders-stats/tenders-stats.component';

import { AuthGuard } from './guards/auth.guard';
import { AuthComponent } from './components/auth/auth.component';


const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: AuthComponent },
  { path: 'users',  canActivate: [AuthGuard], component: ShowUsersComponent },
  { path: 'subcontractors',  canActivate: [AuthGuard], component: ShowSubcontractorsComponent },

  { path: 'suppliers',  canActivate: [AuthGuard], component: ShowSuppliersComponent },

  { path: 'tools', canActivate: [AuthGuard], component: ToolsComponent },
  

  { path: 'tenders',  canActivate: [AuthGuard], component: TendersComponent },
  
  { path: 'tenders/stats',  canActivate: [AuthGuard], component: TendersStatsComponent },

  
  { path: 'projects',  canActivate: [AuthGuard], component: ShowProjectsComponent },
  { path: 'projects/get/:id',  canActivate: [AuthGuard], component: GetProjectComponent },
  { path: 'settings',  canActivate: [AuthGuard], component: ShowSettingsComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
