import { Pipe, PipeTransform } from '@angular/core';

import { SubcontractorGroupsService } from '../services/subcontractor-groups.service';


@Pipe({
  name: 'GroupsTags'
})
export class SubcontractorGroupsTagsPipe implements PipeTransform {
  private groupsArray: any[]=[];


  constructor(private SubcontractorGroupsService: SubcontractorGroupsService){
    this.SubcontractorGroupsService.getAvailableSubcontractorGroups().subscribe(data => {

      data["data"].forEach(
        data => {
            this.groupsArray[data.id]=data.groupName;   
        }
      );

    });
  }


  transform(value: any): any {
    

    let tmpArray=value.split(',');
    let returnArray=[];

    tmpArray.forEach(groupId=>{

      returnArray.push( this.groupsArray[groupId] );
    });
    
    return returnArray;
  }

}
